import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Button as MuiButton } from "@material-ui/core";

const Button = styled(MuiButton)`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  padding: 7px;
  min-width: unset;
  color: ${({ color }) => color};
  border-color: ${({ color }) => color};

  @media (max-width: 1030px) {
    font-size: 12px;
  }
`;

const ActionButton = ({ onClick, color, children }) => {
  return (
    <Button onClick={onClick} variant="outlined" color={color}>
      {children}
    </Button>
  );
};

ActionButton.defaultProps = {
  color: "#787486"
};

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  children: PropTypes.node.isRequired // injected
};

export default ActionButton;
