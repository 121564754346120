import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Compressor from "compressorjs";
import CircularProgress from "@material-ui/core/CircularProgress";
import RoundedAvatar from "../waybee-ui/RoundedAvatar";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
const Avatar = styled.div`
  position: relative;
`;
const Loading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const UploadLabel = styled.div`
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.secondary.main};
  ${Container}:hover & {
    text-decoration: underline;
  }
`;
const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  ${Container}:hover & {
    cursor: pointer;
  }
`;

const ImageInput = ({ label, loading, imageUrl, onChange, size }) => {
  const minimizeImage = file => {
    return new Promise((resolve, reject) => {
      return new Compressor(file, {
        convertSize: 0,
        quality: 0.5,
        maxWidth: 1024,
        maxHeight: 1024,
        success: resultFile => resolve(resultFile),
        error: () => reject()
      });
    });
  };

  const handleChange = async e => {
    const file = e.target.files[0];
    if (file.type.split("/")[0] !== "image") return;
    minimizeImage(file).then(minimizedFile => onChange(minimizedFile));
  };

  return (
    <Container>
      <Avatar>
        <RoundedAvatar
          imageSrc={imageUrl}
          size={size}
          icon="camera_alt"
          customColor="rgba(0,0,0,.2)"
        />
        {loading && (
          <Loading>
            <CircularProgress />
          </Loading>
        )}
      </Avatar>

      {label && <UploadLabel>{label}</UploadLabel>}

      <FileInput
        type="file"
        accept="image/png,image/jpeg"
        onChange={handleChange}
      />
    </Container>
  );
};

ImageInput.defaultProps = {
  imageUrl: "",
  label: "",
  loading: false,
  size: 60
};

ImageInput.propTypes = {
  label: PropTypes.string,
  imageUrl: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number
};

export default ImageInput;
