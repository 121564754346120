import React, { Component } from "react";
import { withAlert } from "react-alert";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  TextField,
  Avatar as MuiAvatar,
  FormControl,
  Select,
  MenuItem,
  Box
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Add } from "@material-ui/icons";
import TicketsService from "../../services/TicketsService";
import TicketChannelList from "./components/TicketChannelList";
import NavigateButton from "../../components/Button/NavigateButton";
import { Can } from "../../acl/can";

const Avatar = styled(MuiAvatar)`
  width: 24px;
  height: 24px;
`;
const FilterField = styled(TextField)`
  padding: 0;
  input {
    padding: 10px;
  }
`;
const CategorySelect = styled(Select)`
  .MuiSelect-root {
    padding-bottom: 10px;
    padding-top: 10px;
  }
`;
const Actions = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 784px) {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;

    div:nth-child(3) {
      margin-left: 0;
    }
    div:nth-child(n + 3) {
      margin-top: 8px;
    }
  }
`;

const LoadingWrapper = styled.div`
  text-align: center;
`;

const BoxWrapper = styled(Box)`
  @media (max-width: 1030px) {
    width: auto;
    text-wrap: nowrap;
    max-width: 150px;
    span :first-child {
      display: none;
    }
    input {
      font-size: 14px;
    }
    span {
      font-size: 14px;
    }
    div {
      font-size: 14px;
    }
  }
`;

class TicketChannels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channels: [],
      filteredChannels: [],
      loading: false,
      categories: [],
      selectedCategory: "",
      titleFilter: null
    };
  }

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Canais de Atendimento");
    this.getChannels();
    this.getCategory();
  }

  getChannels = async () => {
    this.setState({ loading: true });
    const channels = await TicketsService.getChannels();
    this.setState({
      channels,
      filteredChannels: channels,
      loading: false
    });
  };

  getCategory = async () => {
    const categories = await TicketsService.getCategories();
    this.setState({ categories, loading: false });
  };

  onFilter = (e, field) => {
    this.setState({ [field]: e.target.value }, this.filterChannels);
  };

  filterChannels = () => {
    const { channels, selectedCategory, titleFilter } = this.state;

    const filteredChannels = channels.filter(channel => {
      return (
        channel.title.includes(titleFilter) ||
        (!selectedCategory || channel.category.id === selectedCategory)
      );
    });

    this.setState({ filteredChannels });
  };

  removeChannel = async id => {
    const { alert } = this.props;
    try {
      await TicketsService.removeChannel(id);
      alert.show("Canal excluído com sucesso", {
        title: "Sucesso",
        onClose: async () => {
          await this.getChannels();
          this.filterChannels();
        }
      });
    } catch (e) {
      alert.show("Erro ao excluir canal", {
        title: "Ocorreu um erro"
      });
    }
  };

  visibilitySetting = async (id, isVisible) => {
    const { alert } = this.props;
    try {
      await TicketsService.visibilityChannel(id, isVisible);
      alert.show("Visibilidade do canal alterado com sucesso", {
        title: "Sucesso",
        onClose: async () => {
          await this.getChannels();
          this.filterChannels();
        }
      });
    } catch (e) {
      alert.show("Erro ao alterar visibilidade do canal", {
        title: "Ocorreu um erro"
      });
    }
  };

  render() {
    const {
      loading,
      categories,
      selectedCategory,
      titleFilter,
      filteredChannels
    } = this.state;
    const isMobile = window.innerWidth <= 784;

    if (loading) {
      return (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      );
    }

    return (
      <>
        <Actions>
          <Can I="create" a="ticketsCommunication">
            <Box width={isMobile ? 150 : 140} flexShrink={0}>
              <NavigateButton to="/ticket-channel/new" icon={<Add />}>
                Criar canal
              </NavigateButton>
            </Box>
          </Can>
          <BoxWrapper width={210} flexShrink={0} ml={1.6}>
            <NavigateButton
              to="/ticket-channels/0"
              icon={<Avatar src={localStorage.getItem("userPhoto")} />}
            >
              Meus atendimentos
            </NavigateButton>
          </BoxWrapper>
          <BoxWrapper width={300} flexShrink={0} ml={1.6}>
            <FilterField
              variant="outlined"
              placeholder="Nome do canal"
              fullWidth
              value={titleFilter}
              onChange={e => this.onFilter(e, "titleFilter")}
            />
          </BoxWrapper>
          <BoxWrapper width={300} flexShrink={0} ml={1.6}>
            <FormControl variant="outlined" fullWidth>
              <CategorySelect
                displayEmpty
                value={selectedCategory}
                onChange={e => this.onFilter(e, "selectedCategory")}
              >
                <MenuItem value="">Categoria do canal</MenuItem>
                {categories.map(category => (
                  <MenuItem value={category.id}>{category.name}</MenuItem>
                ))}
              </CategorySelect>
            </FormControl>
          </BoxWrapper>
        </Actions>

        <TicketChannelList
          channels={filteredChannels}
          onClickDelete={this.removeChannel}
          onClickEnableVisibility={this.visibilitySetting}
        />
      </>
    );
  }
}

TicketChannels.propTypes = {
  setTitle: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired // injected by withAlert
};

export default withAlert()(TicketChannels);
