import React from "react";
import PropTypes from "prop-types";
import { Grid, withTheme } from "@material-ui/core";
import {
  Add as AddIcon,
  ArrowUpward as UpIcon,
  Remove as MinusIcon
} from "@material-ui/icons";
import styled from "styled-components";
import Widget from "./Widget";
import Button from "../../../waybee-ui/Button";

const Container = styled.div`
  padding: 30px 30px 20px;
  text-align: center;
  width: 100%;
`;
const Header = styled.p`
  margin: 0 0 10px;
  font-size: 25px;
  color: black;
  font-weight: 300;
`;
const Label = styled.span`
  font-weight: 400;
`;

const Value = styled.p`
  font-size: 50px;
  color: rgba(0, 0, 0, 0.5);
`;
const AdditionalValue = styled.p`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${({ color }) => color};
`;

const AccessionWidget = ({
  label,
  value,
  additionalValue,
  onClick,
  theme,
  ...other
}) => {
  return (
    <Widget {...other}>
      <Container>
        <Header>
          Adesão dos 
          {' '}
          <Label>{label}</Label>
        </Header>

        <Grid container alignItems="center">
          <Grid item xs={3} />
          <Grid item xs={6}>
            {!!value && (
            <Value>
              {value}
              %
            </Value>
)}
          </Grid>
          <Grid item xs={3}>
            {!!additionalValue && (
              <AdditionalValue
                color={
                  additionalValue > 0
                    ? theme.palette.primary.main
                    : theme.palette.secondary.light
                }
              >
                {additionalValue > 0 ? <UpIcon /> : <MinusIcon />}

                <span>
                  {additionalValue}
                  %
                </span>
              </AdditionalValue>
            )}
          </Grid>
        </Grid>

        {onClick && (
          <Button onClick={onClick} color="primary">
            <span>
              <AddIcon />
            </span>
            <span>Mais informações</span>
          </Button>
        )}
      </Container>
    </Widget>
  );
};

AccessionWidget.defaultProps = {
  additionalValue: null,
  value: null,
  onClick: null
};

AccessionWidget.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  additionalValue: PropTypes.number,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // inject by withTheme
};

export default withTheme(AccessionWidget);
