import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
  BorderColor,
  DeleteOutlined,
  MoreHoriz,
  Visibility,
  VisibilityOff
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import ActionButton from "./ActionButton";

const TicketChannelActions = ({ id, isVisible, confirmDialogQuestion }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openActionMenu = Boolean(anchorEl);
  const history = useHistory();

  const handleMenuClose = () => setAnchorEl(null);

  return (
    <>
      {window.innerWidth <= 1030 ? (
        <div>
          <IconButton
            onClick={e => {
              setAnchorEl(e.currentTarget);
              e.stopPropagation();
            }}
          >
            <MoreHoriz />
          </IconButton>
          <Menu
            id="basic-menu"
            open={openActionMenu}
            anchorEl={anchorEl}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => history.push(`/ticket-channels/${id}/edit`)}
            >
              Editar
            </MenuItem>
            <MenuItem onClick={() => confirmDialogQuestion("exclude")}>
              Excluir
            </MenuItem>
            <MenuItem onClick={() => confirmDialogQuestion("visibility")}>
              {isVisible ? "Ativar" : "Desativar"}
            </MenuItem>
          </Menu>
        </div>
      ) : (
        <Box display="flex" justifyContent="center">
          <Box mr={0.8}>
            <ActionButton
              color="#00303B"
              onClick={() => history.push(`/ticket-channels/${id}/edit`)}
            >
              <BorderColor />
            </ActionButton>
          </Box>
          <Box mr={0.8}>
            <ActionButton
              color="#FF0000"
              onClick={() => confirmDialogQuestion("exclude")}
            >
              <DeleteOutlined />
            </ActionButton>
          </Box>
          <Box mr={0.8}>
            <ActionButton
              color={isVisible ? "#00303B" : "#FF"}
              onClick={() => confirmDialogQuestion("visibility")}
            >
              {isVisible ? <Visibility /> : <VisibilityOff color="disabled" />}
            </ActionButton>
          </Box>
        </Box>
      )}
    </>
  );
};

TicketChannelActions.propTypes = {
  id: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  confirmDialogQuestion: PropTypes.func.isRequired
};

export default TicketChannelActions;
