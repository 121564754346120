import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Button from "./Button";

const NavigationButton = ({ to, icon, color, children }) => {
  const history = useHistory();
  return (
    <Button onClick={() => history.push(to)} startIcon={icon} color={color}>
      {children}
    </Button>
  );
};

NavigationButton.defaultProps = {
  color: null
};

NavigationButton.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  color: PropTypes.string,
  children: PropTypes.node.isRequired // injected
};

export default NavigationButton;
