import React from "react";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

const useStyles = makeStyles(() => ({
  paper: {
    background: "rgb(229,229,229)",
    fontSize: "0.7em",
    maxWidth: "55%",
    borderRadius: "12px"
  }
}));

const MenuButton = styled.div`
  box-sizing: content-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 4px 24px;

  &:hover {
    background-color: rgb(200, 200, 200);
  }

  &:nth-child(1) {
    padding-top: 10px;
  }

  &:nth-last-child(1) {
    padding-bottom: 10px;
  }
`;

const IconContainer = styled.div`
  margin: 0 12px 0 0;
`;

const ClickPopover = ({ externalClasses, items, data, children }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idPopOver = open ? "simple-popover" : undefined;

  return (
    <div className={externalClasses || ""}>
      <Typography aria-describedby={idPopOver} onClick={handlePopoverOpen}>
        {children}
      </Typography>

      <Popover
        id={idPopOver}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        disableRestoreFocus
        classes={{
          paper: classes.paper
        }}
      >
        {items.map(item => (
          <MenuButton
            onClick={() => {
              if (item.onClick) {
                item.onClick(data);
              }
              handlePopoverClose();
            }}
          >
            <IconContainer>{item.icon}</IconContainer>
            <Typography>{item.title}</Typography>
          </MenuButton>
        ))}
      </Popover>
    </div>
  );
};

ClickPopover.defaultProps = {
  externalClasses: undefined
};

ClickPopover.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  externalClasses: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.objectOf(PropTypes.shape({})).isRequired
};

export default ClickPopover;
