import React, { Component } from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import {
  Container as MuiContainer,
  Typography,
  CircularProgress
} from "@material-ui/core";
import FormLogin from "./FormLogin";
import ExternalUserForgotPassword from "./ExternalUserForgotPassword";
import UserService from "../../services/UserService";
import AppService from "../../services/AppService";
import UserStore from "../../utils/UserStore";
import ChangePassword from "./ChangePassword";
import LoginIdentification from "./LoginIdentification";
import PasswordCode from "./PasswordCode";

const hasTokenLogin = process.env.REACT_APP_TOKEN_LOGIN === "true";
const hasFormLogin = process.env.REACT_APP_FORM_LOGIN === "true";
const urlErp = process.env.REACT_APP_ERP_URL;

const Background = Styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #ddd;
  background-image: url(${({ theme }) => theme.images.loginBackground});
  background-size: cover;
`;

const Fade = Styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: rgba(52, 101, 127, 0.6);
`;

const Logo = Styled.div`
  background-image: url(${({ theme }) => theme.images.logo});
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: contain;
  height: 300px;
  max-width: 290px;
  margin: 0 auto;
  position: sticky;
  z-index: 10;
`;

const Container = Styled(MuiContainer)`
  box-sizing: content-box;
  max-width: 640px;
  padding-bottom: 20px;
`;

const Paper = Styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
  padding: 32px;
  box-sizing: border-box;

  .main-button {
    max-width: 300px;
    margin: 0 auto;
    display: block;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
  }

`;

const BodyText = Styled(Typography)`
  margin-top: 20px;
`;

const AppVersion = Styled.span`
  font-size: 16px;
  font-weight: 300;
  color: rgba(0,0,0,.5);
  position: absolute;
  bottom: 12px;
  right: 12px;
`;

const LoadingWrapper = Styled.div`
  text-align: center;
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      changePassEmail: "",
      loading: false,
      activeView: "formLogin",
      forgotPasswordLabel: "",
      isFirstAccess: false,
      errors: {
        email: "",
        password: ""
      }
    };
  }

  componentDidMount() {
    this.getForgotPasswordLabel();
  }

  openForgotPassword = () => {
    this.setState({ activeView: "loginIdentification" });
  };

  closeForgotPassword = () => {
    this.setState({ activeView: "formLogin" });
  };

  handleFieldChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleSubmit = async () => {
    const { email, password } = this.state;
    const { loadApp } = this.props;
    this.setState({ loading: true });

    try {
      const userId = await UserService.login({ email, password });
      const user = await UserService.getUserData(userId);

      if (!user.firstAccess) {
        UserStore.clearToken();
        UserStore.setUserEmail(user.email);
        this.setState({ activeView: "changePassword", isFirstAccess: true });
      } else {
        await UserService.setUserData(user);
        loadApp();
      }
    } catch (err) {
      const { message } = err;
      if (message === "Senha incorreta") {
        this.setState({ errors: { password: "Senha incorreta", email: "" } });
      } else {
        this.setState({ errors: { password: "", email: message } });
      }
      this.setState({ loading: false });
    }
  };

  getForgotPasswordLabel = async () => {
    try {
      const res = await AppService.getForgotPassword();
      this.setState({ forgotPasswordLabel: res.value });
    } catch (e) {
      console.error(e);
    }
  };

  setPassword = async password => {
    const { loadApp } = this.props;
    const { isFirstAccess } = this.state;
    await UserService.setPassword(password, isFirstAccess);

    if (isFirstAccess) {
      const userId = await UserService.login({
        email: UserStore.getUserEmail(),
        password
      });
      const user = await UserService.getUserData(userId);
      await UserService.setUserData(user);
      loadApp();
    } else {
      this.setState({ activeView: "formLogin" });
    }
  };

  validateLogin = async login => {
    const isExternalUser = await UserService.isExternalUser(login);
    if (isExternalUser) {
      this.setState({ activeView: "externalUserForgotPassword" });
    } else {
      await UserService.sendCodeEmail(login);
      this.setState({ activeView: "passwordCode", changePassEmail: login });
    }
  };

  checkCode = async code => {
    const { changePassEmail } = this.state;
    const token = await UserService.checkCode(changePassEmail, code);
    await UserStore.setProvisionalToken(token.id);
    this.setState({ activeView: "changePassword", isFirstAccess: false });
  };

  render() {
    const {
      email,
      password,
      activeView,
      loading,
      errors,
      forgotPasswordLabel
    } = this.state;
    return (
      <Background>
        <Fade>
          <Logo />
          <Container component="main">
            {hasFormLogin && (
              <Paper>
                {activeView === "formLogin" && (
                  <FormLogin
                    onSubmit={this.handleSubmit}
                    handleChange={this.handleFieldChange}
                    email={email}
                    password={password}
                    openForgotPassword={this.openForgotPassword}
                    loading={loading}
                    errors={errors}
                  />
                )}

                {activeView === "loginIdentification" && (
                  <LoginIdentification
                    onClose={this.closeForgotPassword}
                    validateLogin={this.validateLogin}
                  />
                )}

                {activeView === "passwordCode" && (
                  <PasswordCode
                    onClose={this.closeForgotPassword}
                    checkCode={this.checkCode}
                  />
                )}

                {activeView === "externalUserForgotPassword" && (
                  <ExternalUserForgotPassword
                    forgotLabel={forgotPasswordLabel}
                    closeForgotPassword={this.closeForgotPassword}
                  />
                )}

                {activeView === "changePassword" && (
                  <ChangePassword
                    setPassword={this.setPassword}
                    onClose={this.closeForgotPassword}
                  />
                )}
              </Paper>
            )}

            {hasTokenLogin && !hasFormLogin && (
              <Paper>
                {loading ? (
                  <LoadingWrapper>
                    <CircularProgress />
                  </LoadingWrapper>
                ) : (
                  <span>
                    <Typography variant="h2">Login Desativado</Typography>
                    <BodyText variant="body1">
                      Para acessar a plataforma de comunicação, acesse o menu de
                      Communication pelo ERP:
                      <a href={urlErp}>{urlErp}</a>
                    </BodyText>
                  </span>
                )}
              </Paper>
            )}

            <AppVersion>{process.env.REACT_APP_VERSION}</AppVersion>
          </Container>
        </Fade>
      </Background>
    );
  }
}

Login.propTypes = {
  loadApp: PropTypes.func.isRequired
};

export default Login;
