import React, { Component, Fragment } from "react";
import { withAlert } from "react-alert";
import PropTypes from "prop-types";
import { Add, FilterListRounded } from "@material-ui/icons";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import styled from "styled-components";
import { DebounceInput } from "react-debounce-input";
import _ from "lodash";
import UsersService from "../../services/UsersService";
import UserList from "./components/UserList";
import Button from "../../components/Button/Button";
import UserDialog from "./components/UserDialog";
import SimpleConfirmDialog from "../../waybee-ui/Dialog/SimpleConfirmDialog";

const FilterField = styled(TextField)`
  margin-right: 16px;
`;

const SelectControl = styled(FormControl)`
  margin-right: 16px;
`;

const FilterButton = styled(IconButton)`
  border: 1px solid;
  border-radius: 8px;
  padding: 7px;
  margin-left: 16px;
`;

const FilterContainer = styled(Grid)`
  margin-bottom: 12px;

  .MuiInputBase-input {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .MuiInputBase-root {
    margin: 0;
  }
`;

const FilterGrid = styled(Grid)`
  margin-top: 12px;
`;

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      openDialog: false,
      filters: {
        name: "",
        documentNumber: "",
        email: "",
        status: "",
        isExternalUser: ""
      },
      loading: false,
      showFilters: false,
      user: {},
      isEditing: false,
      confirmDialog: {},
      isCreatingUser: false,
      filteredUsers: []
    };
  }

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Gerenciar usuários");
    this.getUsers();
  }

  getUsers = async () => {
    const { filters } = this.state;
    const { alert } = this.props;

    try {
      this.setState({ loading: true });
      const users = await UsersService.getUsers(filters);
      this.setState({
        users,
        filteredUsers: users,
        loading: false
      });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      alert.show(`Ocorreu um erro ao buscar os usuários.`, {
        title: "Erro"
      });
    }
  };

  saveUser = async values => {
    const { alert } = this.props;
    try {
      let photoUrl;
      if (values.photo) photoUrl = await UsersService.uploadPhoto(values.photo);
      await UsersService.createUser({
        ...values,
        photo: photoUrl
      });

      this.setState({ openDialog: false });

      alert.show("Usuário criado com sucesso!", {
        title: "Sucesso",
        onClose: async () => {
          await this.clearFilter();
          this.getUsers();
          this.setState({ isCreatingUser: false, user: {} });
        }
      });
    } catch (e) {
      console.error(e);
      alert.show(
        `
      Ocorreu um erro no processo de criação do usuário
      ${e.message}
      `,
        {
          title: "Erro"
        }
      );
    }
  };

  onEditingChange = isEditing => {
    this.setState({ isEditing });
  };

  onOpenUser = (data, isEditing) => {
    this.setState({
      user: data,
      openDialog: true,
      isEditing
    });
  };

  onUpdateUser = async (value, status) => {
    if (status === 0 || status === 1) {
      const isStatusActive = status === 1;

      this.setState({
        confirmDialog: {
          open: true,
          title: `${isStatusActive ? "Desativar" : "Ativar"} usuário`,
          message: `Ao confirmar, o usuário ${value.name} será ${
            isStatusActive ? "desativado" : "ativado"
          }. Deseja continuar?`,
          onConfirm: async () => this.onConfirmUserUpdate(value, status),
          onClose: () => {
            this.setState({ confirmDialog: {} });
          }
        }
      });
    } else {
      await this.onConfirmUserUpdate(value);
    }
  };

  onConfirmUserUpdate = async (value, status) => {
    const { alert } = this.props;

    this.setState({ confirmDialog: {} });

    try {
      await UsersService.updateUser(value, status);

      this.setState({ openDialog: false });

      alert.show(`Usuário ${status ? "desativado" : "editado"} com sucesso!`, {
        title: "Sucesso",
        onClose: () => {
          this.getUsers();
          this.setState({
            isEditing: false,
            confirmDialog: {}
          });
        }
      });
    } catch (e) {
      console.error(e);
      alert.show(
        `
      Ocorreu um erro no processo de ${
        status ? "desativar o usuário" : "edição do usuário"
      }
      ${e.message}
      `,
        {
          title: "Erro"
        }
      );
    }
  };

  onConfirmDeleteUser = async id => {
    const { alert } = this.props;

    try {
      await UsersService.deleteUser(id);

      this.setState({ openDialog: false });
      alert.show("Usuário deletado com sucesso!", {
        title: "Sucesso",
        onClose: () => {
          this.getUsers();
          this.setState({
            isEditing: false,
            confirmDialog: {}
          });
        }
      });
    } catch (e) {
      console.error(e);
      alert.show(
        `
      Ocorreu um erro no processo de exclusão do usuário
      ${e.message}
      `,
        {
          title: "Erro"
        }
      );
    }
  };

  onDeleteUser = async user => {
    this.setState({
      confirmDialog: {
        open: true,
        title: `Deletar usuário`,
        message: `Ao confirmar, o usuário ${user.name} será deletado. Deseja continuar?`,
        onConfirm: async () => this.onConfirmDeleteUser(user.id),
        onClose: () => {
          this.setState({ confirmDialog: {} });
        }
      }
    });
  };

  getFilteredUsers = async (field, search) => {
    const { filters, users } = this.state;
    filters[field] = search;

    this.setState({ filters }, () => {
      const newUserList = _.filter(users, user => {
        return _.every(filters, (value, key) => {
          if (value === "") return true;

          if (["email", "documentNumber", "name"].includes(key)) {
            return (
              typeof user[key] === "string" &&
              user[key].toLowerCase().includes(value.toLowerCase())
            );
          }

          return user[key] === value;
        });
      });

      this.setState({ filteredUsers: newUserList });
    });
  };

  clearFilter = () => {
    const { users } = this.state;

    return this.setState({
      filters: {
        name: "",
        documentNumber: "",
        email: "",
        status: "",
        isExternalUser: ""
      },
      filteredUsers: users
    });
  };

  hasAnyFilter() {
    const { filters } = this.state;
    const { name, documentNumber, email } = filters;
    return !!(name.trim() || documentNumber.trim() || email.trim());
  }

  render() {
    const {
      filteredUsers,
      openDialog,
      showFilters,
      filters,
      loading,
      user,
      isEditing,
      isCreatingUser,
      confirmDialog
    } = this.state;

    const openConfirmDialog = Object.keys(confirmDialog).length !== 0;

    return (
      <>
        <FilterContainer container alignItems="center">
          <Box width={165} flexShrink={0}>
            <Button
              color="#4CAF50"
              onClick={() => {
                this.setState({
                  openDialog: true,
                  isCreatingUser: true,
                  user: {}
                });
              }}
              icon={<Add />}
              textColor="white"
            >
              <Box pl={0.8}>Criar usuário</Box>
            </Button>
          </Box>
          <FilterButton
            onClick={() => {
              this.setState({ showFilters: !showFilters });
            }}
          >
            <FilterListRounded />
          </FilterButton>
          {showFilters && (
            <FilterGrid container item xs={12}>
              <DebounceInput
                debounceTimeout={400}
                element={FilterField}
                name="name"
                placeholder="Nome do Usuário"
                variant="outlined"
                value={filters.name}
                onChange={e =>
                  this.getFilteredUsers(e.target.name, e.target.value)
                }
              />
              <DebounceInput
                debounceTimeout={400}
                element={FilterField}
                variant="outlined"
                name="documentNumber"
                placeholder="CPF"
                value={filters.documentNumber}
                onChange={e =>
                  this.getFilteredUsers(e.target.name, e.target.value)
                }
              />
              <DebounceInput
                debounceTimeout={400}
                element={FilterField}
                variant="outlined"
                name="email"
                placeholder="E-mail"
                value={filters.email}
                onChange={e =>
                  this.getFilteredUsers(e.target.name, e.target.value)
                }
              />
              <SelectControl variant="outlined">
                <Select
                  name="status"
                  value={filters.status}
                  displayEmpty
                  onChange={e =>
                    this.getFilteredUsers(e.target.name, e.target.value)
                  }
                >
                  <MenuItem value="">Status</MenuItem>
                  <MenuItem value={1}>Ativo</MenuItem>
                  <MenuItem value={0}>Inativo</MenuItem>
                </Select>
              </SelectControl>
              <SelectControl variant="outlined">
                <Select
                  name="isExternalUser"
                  value={filters.isExternalUser}
                  displayEmpty
                  onChange={e =>
                    this.getFilteredUsers(e.target.name, e.target.value)
                  }
                >
                  <MenuItem value="">Tipo de usuário</MenuItem>
                  <MenuItem value>Externo</MenuItem>
                  <MenuItem value={false}>Interno</MenuItem>
                </Select>
              </SelectControl>
              <Box width={165} flexShrink={0}>
                <Button onClick={this.clearFilter} textColor="black">
                  Redefinir filtro
                </Button>
              </Box>
            </FilterGrid>
          )}
        </FilterContainer>

        {openConfirmDialog && (
          <SimpleConfirmDialog
            open={confirmDialog.open}
            title={confirmDialog.title}
            message={confirmDialog.message}
            onConfirm={confirmDialog.onConfirm}
            onClose={confirmDialog.onClose}
          />
        )}

        <UserList
          users={filteredUsers}
          loading={loading}
          onOpenDialog={this.onOpenUser}
          onDelete={this.onDeleteUser}
          onChangeStatus={this.onUpdateUser}
        />

        <UserDialog
          open={openDialog}
          onClose={() => {
            this.setState({ openDialog: false }, () => {
              this.setState({
                isEditing: false,
                isCreatingUser: false
              });
            });
          }}
          onSend={this.saveUser}
          onEdit={this.onUpdateUser}
          onChangeStatus={this.onUpdateUser}
          onDelete={this.onDeleteUser}
          onEditing={this.onEditingChange}
          data={user}
          isEditing={isEditing}
          isCreatingUser={isCreatingUser}
        />
      </>
    );
  }
}

Users.propTypes = {
  setTitle: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired // injected by withAlert
};

export default withAlert()(Users);
