import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { Box, OutlinedInput } from "@material-ui/core";
import Button from "../../components/Button/Button";
import WuiButton from "../../waybee-ui/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 31px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
`;
const Description = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  color: #b7b6b6;
  margin-top: 8px;
  margin-bottom: 8px;
`;
const FieldContainer = styled.div`
  width: 440px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const PasswordCode = ({ onClose, checkCode }) => {
  const [code, setCode] = useState("");

  return (
    <>
      <WuiButton
        color="secondary"
        startIcon={<ArrowBackIcon />}
        size="large"
        to="/"
        onClick={onClose}
      />

      <Container>
        <Title>Digite o código</Title>
        <Description>
          Digite seu código que foi enviado para o seu e-mail.
        </Description>

        <FieldContainer>
          <OutlinedInput
            variant="outlined"
            placeholder="000000"
            fullWidth
            onChange={e => setCode(e.target.value)}
            value={code}
          />
        </FieldContainer>

        <FieldContainer>
          <Button
            color="#00303B"
            textColor="white"
            fullWidth
            disabled={!code || code.length !== 6}
            onClick={() => checkCode(code)}
          >
            <Box p={0.8}>Continuar</Box>
          </Button>
        </FieldContainer>
      </Container>
    </>
  );
};

PasswordCode.propTypes = {
  onClose: PropTypes.func.isRequired,
  checkCode: PropTypes.func.isRequired
};

export default PasswordCode;
