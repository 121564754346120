import React from "react";
import { Grid, Typography, Box, ClickAwayListener } from "@material-ui/core";
import styled from "styled-components";
import {
  PhoneOutlined,
  MailOutlineOutlined,
  GroupWorkOutlined
} from "@material-ui/icons";
import PropTypes from "prop-types";
import _ from "lodash";
import Avatar from "../Avatar";
import Heading from "../Heading";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || "row"};
  justify-content: ${props => props.justify || "center"};
  align-items: ${props => props.alignItems || "center"};
  margin-left: ${props => (props.gutterLeft ? "11px" : "0px")};
`;
const DetailedText = styled(Typography)`
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: ${props => (props.gutterLeft ? "11px" : "0px")};
`;
const MainTitle = styled(Typography)`
  font-size: ${props => props.fontSize || "18px"};
  @media (max-width: 784px) {
    font-size: ${props => props.fontSize || "16px"};
  }
`;

const ScrollGrid = styled(Grid)`
  overflow-y: auto;
  max-height: 50vh;
`;

const isMobile = window.innerWidth <= 784;

const StudentInfo = ({ student, onClose }) => {
  const studentHasCoursesAndClasses =
    student.coursesAndClasses && student.coursesAndClasses.length;

  const regularCourse = studentHasCoursesAndClasses
    ? _.find(student.coursesAndClasses, ["TIPO_CURSO", "REGULAR"])
    : null;

  return (
    <ClickAwayListener onClickAway={onClose}>
      {student ? (
        <Box margin="30px">
          <ScrollGrid container alignItems="center" spacing={2}>
            <Grid item xs={12} container spacing={2} alignItems="center">
              <Grid item>
                <Avatar alt="foto" src={student.photo} size={60} />
              </Grid>
              <Grid item xs={3} container>
                <Grid item xs container direction="column">
                  <Grid item xs>
                    <MainTitle
                      variant="body1"
                      fontSize={isMobile ? "18px" : "20px"}
                    >
                      {student.name}
                    </MainTitle>
                    <FlexContainer
                      justify="space-between"
                      alignItems={isMobile ? "flex-start" : "center"}
                      direction={isMobile ? "column" : "row"}
                    >
                      {/* TODO Alterar esse campo quanto conseguir colocar a série */}
                      <DetailedText variant="caption">
                        {regularCourse && regularCourse.NOME_TURMA}
                      </DetailedText>
                      <DetailedText variant="caption">
                        {regularCourse && regularCourse.NOME_CURSO}
                      </DetailedText>
                    </FlexContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Heading level={5}>Informações sobre responsáveis</Heading>
            </Grid>
            {student.responsibles.map(responsible => {
              return (
                <Grid
                  item
                  xs={12}
                  container
                  justify="space-between"
                  alignItems="center"
                  wrap="wrap"
                >
                  <Grid
                    item
                    xs={isMobile ? 10 : 8}
                    sm={6}
                    md={4}
                    container
                    direction="column"
                    justifyContent="center"
                  >
                    <MainTitle variant="body1">{responsible.nome}</MainTitle>
                    <FlexContainer justify="flex-start" direction="row">
                      <DetailedText color="primary" variant="caption">
                        {responsible.parentesco}
                      </DetailedText>
                      <DetailedText gutterLeft variant="caption">
                        {responsible.tipo_responsavel[0]}
                      </DetailedText>
                    </FlexContainer>
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 10 : 8}
                    sm={6}
                    md={4}
                    container
                    direction="row"
                    justifyContent={isMobile ? "flex-start" : "center"}
                    alignItems="center"
                  >
                    <PhoneOutlined color="primary" />
                    <FlexContainer
                      gutterLeft
                      direction="column"
                      justify="center"
                    >
                      <DetailedText variant="caption">
                        {responsible.telefone || "Sem telefone"}
                      </DetailedText>
                    </FlexContainer>
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 10 : 8}
                    sm={6}
                    md={4}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent={isMobile ? "flex-start" : "center"}
                    noWrap
                  >
                    <MailOutlineOutlined color="primary" />
                    <FlexContainer
                      gutterLeft
                      direction="column"
                      justify="center"
                      alignItems={isMobile ? "flex-start" : "center"}
                    >
                      {responsible.emails.map(email => {
                        return (
                          <DetailedText variant="caption">{email}</DetailedText>
                        );
                      })}
                    </FlexContainer>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Heading level={5}>
                Informações sobre cursos extracurriculares
              </Heading>
            </Grid>
            {studentHasCoursesAndClasses &&
              student.coursesAndClasses.map(course => {
                if (course.TIPO_CURSO === "OPCIONAL") {
                  return (
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <FlexContainer direction="column" justify="space-between">
                        <MainTitle>{course.NOME_CURSO}</MainTitle>
                      </FlexContainer>
                      <FlexContainer
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                      >
                        <GroupWorkOutlined color="primary" />
                        <DetailedText variant="caption" gutterLeft>
                          {course.NOME_TURMA}
                        </DetailedText>
                      </FlexContainer>
                    </Grid>
                  );
                }
                return <></>;
              })}
          </ScrollGrid>
        </Box>
      ) : (
        <Box textAlign="center" minHeight="fit-content" margin="30px">
          <Typography>Nenhum aluno encontrado</Typography>
        </Box>
      )}
    </ClickAwayListener>
  );
};

StudentInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  student: PropTypes.object,
  onClose: PropTypes.func
};

StudentInfo.defaultProps = {
  onClose: () => {},
  student: undefined
};

export default StudentInfo;
