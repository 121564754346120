import React from "react";
import { withAlert } from "react-alert";
import PropTypes from "prop-types";
import { Box, Grid } from "@material-ui/core";
import styled from "styled-components";
import TicketChannelItem from "./TicketChannelItem";
import { Can } from "../../../acl/can";

const ListColumnTitle = styled.p`
  margin: 10px 0;
  color: #0d062d;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const InfoHeader = styled(Grid)`
  padding-left: 18px;
`;

const TicketChannelList = ({
  channels,
  onClickDelete,
  onClickEnableVisibility
}) => {
  const isMobile = window.innerWidth <= 784;

  return (
    <Box mt={1}>
      {!isMobile && (
        <Grid container>
          <InfoHeader container xs={10}>
            <Grid item xs={1} />
            <Grid item xs={3}>
              <ListColumnTitle>Nome</ListColumnTitle>
            </Grid>
            <Grid item xs={5}>
              <ListColumnTitle>Descrição</ListColumnTitle>
            </Grid>
            <Grid item xs={3}>
              <ListColumnTitle>Categoria</ListColumnTitle>
            </Grid>
          </InfoHeader>
          <Can I="create" a="ticketsCommunication">
            <Grid item xs={2}>
              <ListColumnTitle>Ações</ListColumnTitle>
            </Grid>
          </Can>
        </Grid>
      )}

      {channels.map(channel => (
        <TicketChannelItem
          onClickEnableVisibility={onClickEnableVisibility}
          onClickDelete={onClickDelete}
          channel={channel}
        />
      ))}
    </Box>
  );
};

TicketChannelList.propTypes = {
  channels: PropTypes.arrayOf().isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickEnableVisibility: PropTypes.func.isRequired
};

export default withAlert()(TicketChannelList);
