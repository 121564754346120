import React, { Component } from "react";
import ReactTextFormat from "react-text-format";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import {
  MoreHoriz as MoreIcon,
  InfoOutlined as InfoIcon,
  ArrowBackIos as BackIcon,
  DoneAll as DoubleCheckIcon,
  ArrowBack
} from "@material-ui/icons";
import { IconButton, Box, Slide } from "@material-ui/core";
import "emoji-mart/css/emoji-mart.css";
import _ from "lodash";
import fileSize from "filesize";
import { withAlert } from "react-alert";
import Grow from "@material-ui/core/Grow";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "@moment";
import ChatColumnHeader from "./ChatColumnHeader";
import ChatService from "../../../services/ChatService";
import RoundedAvatarWui from "../../../waybee-ui/RoundedAvatar";
import ScrollColumnBody from "./ScrollColumnBody";
import MessageField from "./MessageField";
import RocketChatWS from "../../../utils/RocketChatWS";
import OpenImage from "../../../waybee-ui/OpenImage";
import { Can } from "../../../acl/can";
import Button from "../../../waybee-ui/Button";
import MemberItem from "./MemberItem";
import StudentInfoPopover from "../../../components/StudentInfoPopover";

const SlideContainer = styled(Slide)`
  @media (min-width: 784px) {
    transition: none !important;
  }
`;
const ChatInfos = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ChatTitle = styled.p`
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 300;
  color: #000;
`;
const ChatTypeMember = styled.p`
  margin: 10px 0 0;
  font-size: 12px;
  color: ${({ theme }) => theme.color.primary.main};
`;
const StudentInfo = styled.p`
  margin: 10px 0 5px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
`;
const InfoButton = styled(Button)`
  display: flex;
  justify-content: end;
  margin-left: 0;
  padding-left: 0;
  font-size: 16px;
  p {
    margin: 0 0 0 9px;
    text-decoration: underline;
  }
`;
const MessagesContainer = styled.div`
  border-top: 2px solid ${({ theme }) => theme.color.background.default};
  display: flex;
  flex-direction: column-reverse;
`;
const MessageItem = styled.div`
  display: flex;
  margin: 10px 12px;

  &.self-message {
    flex-direction: row-reverse;
  }
`;
const RoundedAvatar = styled(RoundedAvatarWui)`
  ${({ deleted }) =>
    deleted
      ? css`
          opacity: 0.5;
        `
      : ""};
`;
const MessageBubble = styled.div`
  display: flex;
  width: 100%;
  word-break: break-word;
  flex-direction: column;
  ${({ deleted }) =>
    deleted
      ? css`
          justify-content: center;
          opacity: 0.5;
        `
      : ""};
  ${({ file }) =>
    file
      ? css`
          white-space: nowrap;
          overflow: hidden;
        `
      : ""};
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.color.primary.light};
  margin: 0 8px;
  border-radius: 8px;

  p {
    margin: 0;
  }

  &.self-message {
    background-color: ${({ theme }) => theme.color.background.default};
  }
`;
const BubbleTitle = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.color.primary.dark};
`;
const BubbleBody = styled.p`
  font-size: 14px;
  color: #666666;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  a {
    color: ${({ theme }) => theme.color.secondary.light};
    font-weight: bold;
  }
`;
const FileBody = styled.a`
  display: flex;
  margin: 4px 0;
  text-decoration: none;

  & * {
    white-space: nowrap;
    overflow: hidden;
  }
`;
const BubbleImage = styled.div`
  margin: 8px 0;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 250px;
  }

  &:hover {
    cursor: pointer;
  }
`;
const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10px;
`;
const FileName = styled.div`
  font-size: 14px;
  color: #666666;
  display: flex;
  min-width: 0;

  p {
    margin: 0;
  }
`;
const FileNameBase = styled.p`
  text-overflow: ellipsis;
`;
const FileNameExtension = styled.p`
  flex-shrink: 0;
`;
const FileSize = styled.p`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
`;
const BubbleBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const BubbleDate = styled.p`
  font-size: 12px;
  text-align: right;
  color: #999999;
`;
const BubbleRead = styled.p`
  && {
    margin: 0 0 0 8px;
  }
`;
const ContainerInput = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
const DeleteButton = styled(Button)`
  background-color: #e6e6e6;
  border-radius: 4px;
  height: 33px;
  bottom: 65%;
  left: 60%;
  position: absolute;
  width: 140px;
`;

const TextDeleteButton = styled.p`
  font-size: 11px;
  color: #666666;
`;
// const BoubleOptions = styled.div`
//   min-width: 44px;
//   display: flex;
//   align-items: flex-end;
//   justify-content: flex-end;
// `;
const BackButton = styled(Button)`
  padding: 16px 22px;
  color: #787486;
`;
class MessagesColumn extends Component {
  constructor(props) {
    super(props);
    this.bottomRef = React.createRef();
    this.state = {
      openDetails: false,
      messageValue: "",
      loadingMessages: true,
      loadingSend: false,
      userId: localStorage.getItem("userId"),
      selectedImage: null,
      members: [],
      messages: [],
      showModalDeleteMessage: false,
      idMsgSelected: "",
      anchorEl: null,
      popoverUserId: null,
      bottomHeight: 0
      /* anchorMenu: null */
    };
  }

  componentDidMount() {
    this.setup();
    this.setBottomHeight("messages-bottom");
  }

  componentDidUpdate(prevProps, prevState) {
    const { chat } = this.props;
    const { openDetails } = this.state;

    if (chat !== prevProps.chat) this.reload(chat.rocketChatId);

    if (openDetails !== prevState.openDetails)
      this.setBottomHeight("messages-bottom");
  }

  componentWillUnmount() {
    RocketChatWS.sendUnsubRoom();
  }

  reload = async rocketChatId => {
    this.setState({ loadingMessages: true });
    await this.getMembers();
    RocketChatWS.sendLoadHistory(rocketChatId);
    RocketChatWS.sendUnsubRoom();
    RocketChatWS.sendSubRoom(rocketChatId);
  };

  setup = async () => {
    const { chat } = this.props;
    await this.getMembers();

    // Carrega Histórico de mensagens
    RocketChatWS.sendLoadHistory(chat.rocketChatId);
    RocketChatWS.addOnMessageFunc(event => {
      const message = JSON.parse(event.data);
      // Listen for loadHistory method result
      if (message.msg === "result" && message.result.messages) {
        const lastReadMessageId = this.getLastReadMessageId();
        const chatMessages = [];
        let read = false;
        message.result.messages.forEach(chatMessage => {
          const formattedMessage = this.formatMessage(chatMessage);
          if (formattedMessage) {
            if (lastReadMessageId === formattedMessage.id) read = true;
            formattedMessage.read = read;
            chatMessages.push(formattedMessage);
          }
        });
        this.setState(
          { messages: chatMessages, loadingMessages: false },
          () => {
            this.scrollToBottom();
            this.markAsRead();
          }
        );
      }
    });

    // Se inscreve para receber novas mensagens;
    RocketChatWS.sendSubRoom(chat.rocketChatId);
    RocketChatWS.addOnMessageFunc(event => {
      let { messages } = this.state;

      const message = JSON.parse(event.data);
      // Listen for room messages
      if (message.collection === "stream-room-messages" && message.fields) {
        const formattedMessage = this.formatMessage(message.fields.args[0]);
        if (
          formattedMessage &&
          !messages.some(msg => msg.id === formattedMessage.id)
        ) {
          messages.unshift(formattedMessage);
          this.setState({ messages }, this.scrollToBottom);
        }

        if (
          message.fields.args[0].t &&
          message.fields.args[0].t === "rm" &&
          messages.some(msg => msg.id === formattedMessage.id)
        ) {
          messages = messages.map(msg => {
            const newMsg = msg;

            if (newMsg.id === formattedMessage.id) newMsg.deleted = true;

            return newMsg;
          });

          this.setState({ messages });
        }
      }
    });
  };

  markAsRead = async () => {
    const { chat } = this.props;
    const { messages } = this.state;

    await ChatService.markAsRead(chat.id, messages[0].id);
    const refreshMessageStatus = new CustomEvent("refresh-chat-item", {
      detail: { unread: false }
    });

    document.dispatchEvent(refreshMessageStatus);
  };

  getLastReadMessageId = () => {
    const { members } = this.state;

    const readMessages = members
      .filter(member => {
        return (
          member.type === "family_member" &&
          member.info &&
          member.info.lastReadMessageId
        );
      })
      .map(member => {
        const formattedInfo = member.info;
        formattedInfo.updatedAt = new Date(member.info.updated_at);
        return formattedInfo;
      });
    const sortedInfos = _.sortBy(readMessages, "updatedAt");
    return sortedInfos.length
      ? sortedInfos[sortedInfos.length - 1].lastReadMessageId
      : null;
  };

  formatMessage = chatMessage => {
    const { members } = this.state;

    if (!chatMessage.u) return null;

    // eslint-disable-next-line no-underscore-dangle
    const foundMember = members.find(
      // eslint-disable-next-line no-underscore-dangle
      member => member.rocketChatId === chatMessage.u._id
    );
    if (!foundMember) return null;

    const msg = {
      // eslint-disable-next-line no-underscore-dangle
      id: chatMessage._id,
      date: moment(chatMessage.ts.$date).format("DD/MM/YYYY HH:mm"),
      body: chatMessage.msg,
      sender: foundMember,
      deleted: !!chatMessage.t
    };

    if (chatMessage.attachments && chatMessage.attachments.length) {
      if (chatMessage.file.type.includes("image")) {
        msg.type = "image";
      } else {
        msg.type = "file";
      }
    } else {
      msg.type = "text";
    }

    if (msg.type === "file" || msg.type === "image") {
      const nameParts = chatMessage.file.name.split(".");
      const extension = nameParts[nameParts.length - 1];

      const rocketInfo = JSON.parse(
        atob(localStorage.getItem("rocketChatInfo"))
      );
      const fileUrl = rocketInfo.url + chatMessage.attachments[0].title_link;

      const size = chatMessage.attachments[0].description;

      msg.file = {
        url: fileUrl,
        name: chatMessage.file.name.split(".")[0],
        extension,
        size: fileSize(Number.isNaN(Number(size)) ? 0 : size)
      };
    }

    return msg;
  };

  getMembers = async () => {
    try {
      const { chat } = this.props;
      const members = await ChatService.getChatMembers(chat.id);
      this.setState({ members });
    } catch (e) {
      const { alert } = this.props;
      alert.show(`Erro ao buscar membros`, { title: "Erro" });
    }
  };

  scrollToBottom = () => {
    const messagesBody = document.getElementById("messages-body");
    if (!messagesBody) return;
    messagesBody.scrollTop = messagesBody.scrollHeight;
  };

  deleteMessage = async msg => {
    const { alert, chat } = this.props;
    let { messages } = this.state;

    try {
      if (!msg.deleted) {
        await ChatService.deleteMessage(chat.id, msg.id);

        messages = messages.map(message => {
          const newMsg = message;
          if (newMsg.id === msg.id) newMsg.deleted = true;
          return newMsg;
        });

        this.setState({ /* anchorMenu: null, */ messages });

        const refreshEvent = new CustomEvent("refresh-chat-item", {
          detail: { deleted: true, chatId: chat.id }
        });
        document.dispatchEvent(refreshEvent);
      }
    } catch (e) {
      const error = _.get(e, "response.data.error", null);

      alert.show(error ? error.message : `Erro ao deletar mensagem.`, {
        title: "Erro"
      });
    }
  };

  showDeletedMessage = (msg, show) => {
    let { messages } = this.state;
    messages = messages.map(message => {
      const newMsg = message;
      if (newMsg.id === msg.id) newMsg.show = show;
      return newMsg;
    });

    this.setState({ messages });
  };

  sendMessage = async value => {
    this.setState({ loadingSend: true });
    try {
      const { chat } = this.props;
      await ChatService.sendMessage(chat.id, value);
      this.setState({ messageValue: "" });

      const refreshEvent = new CustomEvent("refresh-chat-item", {
        detail: { msg: value, chatId: chat.id }
      });
      document.dispatchEvent(refreshEvent);
    } catch (e) {
      const { alert } = this.props;
      alert.show(`Erro ao enviar mensagem`, { title: "Erro" });
    } finally {
      this.setState({ loadingSend: false });
    }
  };

  sendFile = async file => {
    this.setState({ loadingSend: true });
    try {
      const { chat } = this.props;
      const formData = new FormData();

      formData.append("file", file, file.name);
      formData.append("description", file.size);
      formData.append("msg", "Arquivo");

      await ChatService.sendFile(chat.id, formData);

      const refreshEvent = new CustomEvent("refresh-chat-item", {
        detail: { msg: "Arquivo", chatId: chat.id }
      });
      document.dispatchEvent(refreshEvent);
    } catch (e) {
      const { alert } = this.props;
      const msg = _.get(
        e,
        "response.data.error.message",
        "Erro ao enviar arquivo"
      );
      alert.show(msg, { title: "Erro" });
    } finally {
      this.setState({ loadingSend: false });
    }
  };

  showDeleteModal = async msgId => {
    const { showModalDeleteMessage } = this.state;

    this.setState({
      showModalDeleteMessage: !showModalDeleteMessage,
      idMsgSelected: `msg-${msgId}`
    });
  };

  setBottomHeight = elementId => {
    const element = document.getElementById(elementId);
    this.setState({ bottomHeight: element ? element.offsetHeight : 0 });
  };

  formatDate = date => {
    const parsedDate = moment(date).parseZone();
    const minutes = moment().diff(moment(parsedDate), "minutes");
    const hours = moment().diff(moment(parsedDate), "hours");
    const days = moment()
      .endOf("day")
      .diff(moment(parsedDate).endOf("day"), "days");

    if (minutes <= 1) return `1 minuto atrás`;
    if (minutes <= 60) return `${minutes} minutos atrás`;
    if (hours <= 1) return `${hours} hora atrás`;
    if (hours <= 24) return `${hours} horas atrás`;
    if (days === 1)
      return moment
        .utc(parsedDate)
        .local()
        .format("[ontem às ] HH:mm");
    if (days > 1 && days <= 6) return `${days} dias atrás`;
    if (days > 6)
      return moment
        .utc(parsedDate)
        .local()
        .format("DD/MM/YY");

    return "";
  };

  render() {
    const { chat, currentSection, onClose } = this.props;
    const {
      openDetails,
      messageValue,
      loadingMessages,
      loadingSend,
      selectedImage,
      userId,
      messages,
      members,
      bottomHeight,
      showModalDeleteMessage,
      idMsgSelected /* , anchorMenu */,
      anchorEl,
      popoverUserId
    } = this.state;

    const isMobile = window.innerWidth <= 784;
    const isCurrentSection = isMobile ? currentSection === "messages" : true;
    return (
      <SlideContainer
        direction="right"
        in={isCurrentSection}
        mountOnEnter
        unmountOnExit
      >
        <Box
          height="100%"
          position="relative"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Box id="messages-header">
            {isMobile && (
              <BackButton onClick={onClose} startIcon={<ArrowBack />}>
                Voltar
              </BackButton>
            )}
            <ChatColumnHeader title="Conversa com">
              <Box mt={1.6} display="flex">
                <RoundedAvatar
                  imageSrc={chat.type === "private" ? chat.photo : null}
                  imageAlt="Foto do chat"
                  icon={chat.type === "private" ? "person" : "group"}
                  size={86}
                />
                <ChatInfos>
                  <ChatTitle title={chat.title}>{chat.title}</ChatTitle>
                  {chat.type === "private" && chat.typeMember && (
                    <ChatTypeMember>{chat.typeMember}</ChatTypeMember>
                  )}
                  <StudentInfo
                    onClick={event => {
                      this.setState({
                        anchorEl: event.currentTarget,
                        popoverUserId: chat.studentId
                      });
                    }}
                  >
                    Aluno:
                    {` ${chat.studentName}`}
                    {" - "}
                    {chat.studentInfos}
                  </StudentInfo>
                  {popoverUserId && (
                    <StudentInfoPopover
                      anchorEl={anchorEl}
                      onClose={() => {
                        this.setState({
                          anchorEl: null,
                          popoverUserId: null
                        });
                      }}
                      userId={popoverUserId}
                    />
                  )}
                  {openDetails ? (
                    <InfoButton
                      color="primary"
                      onClick={() =>
                        this.setState(
                          { openDetails: false },
                          this.scrollToBottom
                        )
                      }
                    >
                      <BackIcon />
                      <p>Voltar à conversa</p>
                    </InfoButton>
                  ) : (
                    <InfoButton
                      color="primary"
                      onClick={() => this.setState({ openDetails: true })}
                    >
                      <InfoIcon />
                      <p>Ver informações da conversa</p>
                    </InfoButton>
                  )}
                </ChatInfos>
              </Box>
            </ChatColumnHeader>
          </Box>

          {!openDetails ? (
            <>
              <ScrollColumnBody
                bodyId="messages-body"
                subtractIds={["messages-header", "messages-bottom"]}
                mb={bottomHeight}
              >
                <MessagesContainer>
                  {loadingMessages ? (
                    Array.from(new Array(5)).map(() => (
                      <Box display="flex" p={1.2}>
                        <Skeleton variant="circle" width={44} height={44} />
                        <Box
                          flexGrow={1}
                          ml={1}
                          display="flex"
                          justifyContent="center"
                          flexDirection="column"
                        >
                          <Skeleton variant="text" width="100%" height={10} />
                          <Skeleton variant="text" width="100%" height={10} />
                          <Skeleton variant="text" width="100%" height={10} />
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <>
                      {messages.map(msg => (
                        <MessageItem
                          key={msg.id}
                          className={
                            userId === msg.sender.id.toString()
                              ? "self-message"
                              : ""
                          }
                          id={`msg-${msg.id}`}
                        >
                          <Grow in>
                            <RoundedAvatar
                              deleted={msg.deleted && !msg.show}
                              imageSrc={msg.sender.photo}
                              imageAlt="Foto do usuário que enviou mensagem"
                              icon="person"
                              size={44}
                            />
                          </Grow>
                          <Grow in>
                            <MessageBubble
                              file={msg.type === "file"}
                              deleted={msg.deleted && !msg.show}
                              className={
                                userId === msg.sender.id.toString()
                                  ? "self-message"
                                  : ""
                              }
                            >
                              {msg.deleted && !msg.show ? (
                                <BubbleBody>
                                  Essa mensagem foi apagada.
                                </BubbleBody>
                              ) : (
                                <>
                                  <BubbleTitle>{msg.sender.name}</BubbleTitle>
                                  {msg.type === "file" && (
                                    <FileBody
                                      href={msg.file.url}
                                      target="_blank"
                                      download
                                    >
                                      <RoundedAvatar
                                        size={42}
                                        icon="description"
                                      />
                                      <FileInfo>
                                        <FileName>
                                          <FileNameBase>
                                            {msg.file.name}
                                          </FileNameBase>
                                          <FileNameExtension>
                                            .
                                            {msg.file.extension}
                                          </FileNameExtension>
                                        </FileName>
                                        <FileSize>{msg.file.size}</FileSize>
                                      </FileInfo>
                                    </FileBody>
                                  )}
                                  {msg.type === "text" && (
                                    <BubbleBody>
                                      <ReactTextFormat linkTarget="_blank">
                                        {msg.body}
                                      </ReactTextFormat>
                                    </BubbleBody>
                                  )}
                                  {msg.type === "image" && (
                                    <BubbleImage
                                      onClick={() =>
                                        this.setState({
                                          selectedImage: msg.file.url
                                        })
                                      }
                                    >
                                      <img
                                        src={msg.file.url}
                                        onLoad={this.scrollToBottom}
                                        alt="Imagem enviada"
                                      />
                                    </BubbleImage>
                                  )}
                                  <BubbleBottom>
                                    <BubbleDate>{msg.date}</BubbleDate>
                                    {userId === msg.sender.id.toString() && (
                                      <BubbleRead>
                                        {msg.read ? (
                                          <DoubleCheckIcon
                                            color="primary"
                                            fontSize="small"
                                          />
                                        ) : (
                                          <DoubleCheckIcon fontSize="small" />
                                        )}
                                      </BubbleRead>
                                    )}
                                  </BubbleBottom>
                                </>
                              )}
                            </MessageBubble>
                          </Grow>

                          {userId === msg.sender.id.toString() && !msg.deleted && (
                            <IconButton
                              style={{ height: "15%" }}
                              onClick={() => this.showDeleteModal(msg.id)}
                            >
                              <MoreIcon
                                size={24}
                                style={{ color: "rgba(0, 0, 0, 0.3)" }}
                              />
                              {showModalDeleteMessage &&
                                idMsgSelected === `msg-${msg.id}` && (
                                  <DeleteButton
                                    onClick={() => this.deleteMessage(msg)}
                                  >
                                    <TextDeleteButton>
                                      Apagar mensagem
                                    </TextDeleteButton>
                                  </DeleteButton>
                                )}
                            </IconButton>
                          )}
                        </MessageItem>
                      ))}
                    </>
                  )}
                </MessagesContainer>
              </ScrollColumnBody>
              <Can I="send" a="message">
                <ContainerInput id="messages-bottom" ref={this.bottomRef}>
                  <MessageField
                    value={messageValue}
                    onChange={value => this.setState({ messageValue: value })}
                    onSend={this.sendMessage}
                    onSendFile={this.sendFile}
                    loading={loadingSend}
                  />
                </ContainerInput>
              </Can>
            </>
          ) : (
            <ScrollColumnBody
              bodyId="messages-info"
              subtractIds={["messages-header"]}
            >
              {members.map(member => {
                if (member.type === "communication") return null;

                const lastAccess = _.get(member, "info.lastAccess", null);

                return (
                  <MemberItem
                    key={member.id}
                    relation={
                      member.type === "employee"
                        ? "Funcionário"
                        : member.relation
                    }
                    name={member.name}
                    photo={member.photo}
                    photoSize={66}
                    memberType={
                      member.type === "employee"
                        ? "Funcionário"
                        : member.categories.join("\n")
                    }
                    info={
                      lastAccess &&
                      `visto por último ${this.formatDate(lastAccess)}`
                    }
                    type="private"
                  />
                );
              })}
            </ScrollColumnBody>
          )}

          {selectedImage && (
            <OpenImage
              src={selectedImage}
              onClose={() => this.setState({ selectedImage: null })}
            />
          )}
        </Box>
      </SlideContainer>
    );
  }
}

MessagesColumn.propTypes = {
  chat: PropTypes.shape({
    id: PropTypes.string,
    rocketChatId: PropTypes.string,
    type: PropTypes.string,
    photo: PropTypes.string,
    title: PropTypes.string,
    studentId: PropTypes.number,
    studentName: PropTypes.string,
    studentInfos: PropTypes.string,
    typeMember: PropTypes.string
  }).isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  currentSection: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withAlert()(MessagesColumn);
