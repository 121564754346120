import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField
} from "@material-ui/core";
import {
  ExitToApp as ExitToAppIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from "@material-ui/icons";
import { withTranslation } from "react-i18next";

import Heading from "../../waybee-ui/Heading";
import Button from "../../waybee-ui/Button";

const Form = Styled.form`
  width: 100%;
  margin-top: 1rem;
`;

const LoginButton = Styled(Button)`
  max-width: 300px;
  margin: 50px auto 0 auto;
  display: block;
`;

function FormLogin(props) {
  const {
    email,
    password,
    handleChange,
    openForgotPassword,
    onSubmit,
    loading,
    errors,
    t
  } = props;

  const [values, setValues] = React.useState({
    showPassword: false
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <Fragment>
      <Heading level={1} icon={ExitToAppIcon}>
        Acesso à Plataforma Waybee
      </Heading>
      <Form
        noValidate
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Grid container alignItems="flex-start" justifyContent="center">
          <Box width={440}>
            <TextField
              InputLabelProps={{ required: false }}
              margin="normal"
              fullWidth
              id="email"
              label={t("login.login", "Login")}
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleChange("email")}
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email}
            />

            <TextField
              label={t("login.pass", "Senha")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onMouseDown={handleClickShowPassword}
                    >
                      {values.showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              variant="outlined"
              name="password"
              id="password"
              type={values.showPassword ? "text" : "password"}
              value={password}
              fullWidth
              onChange={handleChange("password")}
              error={!!errors.password}
              helperText={errors.password}
            />
          </Box>
          <Box
            width={440}
            textAlign="center"
            display="flex"
            justifyContent="flex-end"
          >
            <Button color="secondary" size="lg" onClick={openForgotPassword}>
              Esqueceu a senha?
            </Button>
          </Box>
        </Grid>
        {loading ? (
          <Box py={4} textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <LoginButton
            onClick={() => {}}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Entrar
          </LoginButton>
        )}
      </Form>
    </Fragment>
  );
}

FormLogin.propTypes = {
  email: PropTypes.string.isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  openForgotPassword: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(FormLogin);
