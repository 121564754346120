import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { Button as MuiButton } from "@material-ui/core";

const StyledButton = styled(MuiButton).withConfig({
  shouldForwardProp: prop => prop !== "color"
})`
  color: ${({ textColor }) => textColor || "#787486"};
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  ${({ color, fontColor, disabled }) =>
    color
      ? css`
          background-color: ${disabled ? "rgba(0, 0, 0, 0.04)" : color};
        `
      : css`
          border-color: ${fontColor};
        `}
  &:hover {
    color: ${({ color }) => color}
`;

const Button = props => {
  const { onClick, icon, color, children } = props;

  return (
    <StyledButton
      {...props}
      onClick={onClick}
      variant="outlined"
      fullWidth
      startIcon={icon}
      color={color}
    >
      {children}
    </StyledButton>
  );
};

Button.defaultProps = {
  color: null,
  textColor: null,
  icon: null
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element,
  color: PropTypes.string,
  textColor: PropTypes.string,
  children: PropTypes.node.isRequired // injected
};

export default Button;
