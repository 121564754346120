import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "@moment";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import { History as HistoryIcon, ExpandMore } from "@material-ui/icons";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import Group from "../../waybee-ui/Group";
import Heading from "../../waybee-ui/Heading";
import HoverPopover from "../HoverPopover";
import Loading from "../Loading";

const MainContainer = styled(Grid)`
  margin: 5px 0;
`;

const DropDown = styled(Accordion)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  box-shadow: none;
`;

const DetailsContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: 0 0 10px 0;
`;
const Subtitle = styled(Typography)`
  color: #00b297;
`;

const SubtitleBefore = styled(Typography)`
  color: #00b297;

  &:before {
    content: "antigo: ";
  }
`;

const SubtitleAfter = styled(Typography)`
  color: #00b297;

  &:before {
    content: "novo: ";
  }
`;

const DropdownDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0;

  @media (max-width: 784px) {
    align-items: flex-start;
  }
`;

const DropdownSummary = styled(AccordionSummary)`
  padding: 0;

  &expandIconWrapper {
    padding: 0;
  }
`;

const DetailText = styled(Typography)`
  margin: 0 0 0 5px;
  word-break: break-word;
  white-space: normal;

  @media (max-width: 784px) {
    font-size: 14px;
    word-break: keep-all;
  }
`;

const PopoverText = styled.p`
  color: rgb(114, 114, 114);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 1.4;
  margin: 0 0 0 5px;
  word-break: break-word;
`;

const PopoverContent = styled(Typography)`
  cursor: pointer;
  word-break: break-word;
  line-break: normal;

  :hover {
    opacity: 0.8;
  }
`;

const TitleDescription = styled(Typography)`
  margin: 0 0 0 10px;

  @media (max-width: 784px) {
    margin: 0;
  }
`;

const ItemDetail = styled(Grid)`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;

  @media (max-width: 784px) {
    flex-direction: column;
    margin: 10px 0;
  }
`;

const ItemContainerLeft = styled(Grid)`
  padding: 0 32px 0 0;

  @media (max-width: 784px) {
    padding: 0;
  }
`;

const ItemContainerRight = styled(Grid)`
  padding: 0 0 0 16px;

  @media (max-width: 784px) {
    padding: 0;
  }
`;

const GroupHistory = ({ audits, loading }) => {
  const [viewDetails, setViewDetails] = useState(false);

  const handleDropdownChange = panel => (event, isExpanded) => {
    setViewDetails(isExpanded ? panel : false);
  };

  if (!loading && (!audits || (audits && !audits.length))) return null;

  const limitRange = 125; // Limite para exibição de texto

  const handleText = text => {
    if (text.length > limitRange) {
      return `${text.substring(0, limitRange)}...`;
    }
    return text;
  };

  return (
    <Group>
      <Heading level={2} icon={HistoryIcon} gutterBottom>
        Histórico de alterações na comunicação
      </Heading>

      {loading ? (
        <Loading />
      ) : (
        <Grid container justifyContent="space-between">
          {audits
            .map(audit => {
              if (!audit) {
                return undefined;
              }
              const { alteredValues } = audit;

              return (
                <MainContainer item xs={12} container key={audit.id}>
                  <DropDown
                    expanded={viewDetails === `panel${audit.id}`}
                    onChange={handleDropdownChange(`panel${audit.id}`)}
                  >
                    <DropdownSummary expandIcon={<ExpandMore />}>
                      <Grid item xs={12} sm={4}>
                        <DetailText>{`Alterações na comunicação (${alteredValues.length})`}</DetailText>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <DetailText variant="caption">
                          Responsável pela alteração:
                          <Subtitle>{` ${audit.userId}`}</Subtitle>
                        </DetailText>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <DetailText variant="caption">
                          {moment(audit.createdAt).format(
                            "[Realizada dia] DD/MM/YYYY [às] HH:mm"
                          )}
                        </DetailText>
                      </Grid>
                    </DropdownSummary>
                    <DropdownDetails>
                      {alteredValues.map((data, j) => {
                        const getDetailValue = (value, type) => {
                          if (type === "boolean") {
                            return value ? "Sim" : "Não";
                          }
                          return value;
                        };

                        const detailOld = getDetailValue(data.old, data.type);
                        const detailNew = getDetailValue(data.new, data.type);

                        const detailTitle = data.name || "-";

                        return (
                          <DetailsContainer container key={uuidv4()}>
                            <ItemDetail item xs={12}>
                              <Grid item xs={12} sm={4}>
                                <TitleDescription variant="caption">
                                  {detailTitle}
                                </TitleDescription>
                              </Grid>
                              <ItemContainerLeft item xs={12} sm={4}>
                                <SubtitleBefore variant="caption" />
                                {detailOld && detailOld.length > limitRange ? (
                                  <HoverPopover
                                    id={`old-${j}`}
                                    value={
                                      <PopoverText>{detailOld}</PopoverText>
                                    }
                                  >
                                    <PopoverContent>
                                      {handleText(detailOld)}
                                    </PopoverContent>
                                  </HoverPopover>
                                ) : (
                                  <DetailText>
                                    {handleText(detailOld)}
                                  </DetailText>
                                )}
                              </ItemContainerLeft>
                              <ItemContainerRight item xs={12} sm={4}>
                                <SubtitleAfter variant="caption" />
                                {detailNew && detailNew.length > limitRange ? (
                                  <HoverPopover
                                    id={`new-${j}`}
                                    value={
                                      <PopoverText>{detailNew}</PopoverText>
                                    }
                                  >
                                    <PopoverContent>
                                      {handleText(detailNew)}
                                    </PopoverContent>
                                  </HoverPopover>
                                ) : (
                                  <DetailText>
                                    {handleText(detailNew)}
                                  </DetailText>
                                )}
                              </ItemContainerRight>
                            </ItemDetail>
                          </DetailsContainer>
                        );
                      })}
                    </DropdownDetails>
                  </DropDown>
                </MainContainer>
              );
            })
            .reverse()}
        </Grid>
      )}
    </Group>
  );
};

GroupHistory.defaultProps = {
  loading: false
};

GroupHistory.propTypes = {
  audits: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      id: PropTypes.number,
      alteredValues: PropTypes.arrayOf()
    })
  ).isRequired,
  loading: PropTypes.bool
};

export default GroupHistory;
