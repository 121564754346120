import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import Button from "../../waybee-ui/Button";

const Title = Styled.h3`
  font-size: 28px;
  font-weight: 300;
  color: ${({ theme }) => theme.color.secondary.dark};
  margin: 0 0 20px;
  line-height: 1.43;
`;

const BackButton = Styled(Button)`
width: 300px;
`;

const ForgetText = Styled(Typography)`
margin-bottom: 32px;
`;

function ExternalUserForgotPassword(props) {
  const { closeForgotPassword, forgotLabel } = props;

  return (
    <Grid container>
      <Button
        color="secondary"
        startIcon={<ArrowBackIcon />}
        size="large"
        to="/"
        onClick={closeForgotPassword}
      />
      <Grid container item justifyContent="center">
        <Title level={6} gutterBottom>
          Esqueci a senha
        </Title>
        <ForgetText
          variant="subtitle1"
          dangerouslySetInnerHTML={{ __html: forgotLabel }}
        />
        <BackButton
          onClick={closeForgotPassword}
          type="submit"
          variant="contained"
          color="secondary"
        >
          Voltar
        </BackButton>
      </Grid>
    </Grid>
  );
}

ExternalUserForgotPassword.propTypes = {
  closeForgotPassword: PropTypes.func.isRequired,
  forgotLabel: PropTypes.string.isRequired
};

export default ExternalUserForgotPassword;
