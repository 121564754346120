import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { Box, OutlinedInput } from "@material-ui/core";
import Button from "../../components/Button/Button";
import WuiButton from "../../waybee-ui/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 31px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
`;
const Description = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  color: #b7b6b6;
  margin-top: 8px;
  margin-bottom: 8px;
`;
const FieldContainer = styled.div`
  width: 440px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const ChangePassword = ({ onClose, validateLogin }) => {
  const [login, setLogin] = useState("");

  return (
    <>
      <WuiButton
        color="secondary"
        startIcon={<ArrowBackIcon />}
        size="large"
        to="/"
        onClick={onClose}
      />

      <Container>
        <Title>Confirme seu login</Title>
        <Description>
          Digite seu login para receber as instruções para troca de senha.
        </Description>

        <FieldContainer>
          <OutlinedInput
            variant="outlined"
            placeholder="Login"
            fullWidth
            onChange={e => setLogin(e.target.value)}
            value={login}
          />
        </FieldContainer>

        <FieldContainer>
          <Button
            color="#00303B"
            textColor="white"
            fullWidth
            disabled={!login}
            onClick={() => validateLogin(login)}
          >
            <Box p={0.8}>Continuar</Box>
          </Button>
        </FieldContainer>
      </Container>
    </>
  );
};

ChangePassword.propTypes = {
  onClose: PropTypes.func.isRequired,
  validateLogin: PropTypes.func.isRequired
};

export default ChangePassword;
