import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select
} from "@material-ui/core";
import moment from "moment";
import { Close as CloseIcon } from "@material-ui/icons";
import TicketDetailSupervisors from "./TicketDetailSupervisors";
import TicketDetailAttendee from "./TicketDetailAttendee";
import HeaderInfoTitle from "./HeaderInfoTitle";
import HeaderAvatar from "./HeaderAvatar";
import HeaderName from "./HeaderName";
import StudentInfoPopover from "../../../components/StudentInfoPopover";
import HoverPopover from "../../../components/HoverPopover";

const Header = styled.div`
  border-bottom: 1px solid #00303b66;
  padding-bottom: 16px;
`;
const HeaderStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StatusBar = styled.span`
  display: inline-block;
  background-color: ${({ color }) => color};
  width: 320px;
  height: 8px;

  @media (max-width: 784px) {
    width: 85%;
  }
`;
const Title = styled.p`
  margin: 12px 0;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
`;
const ChannelName = styled.p`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #0d062d;
`;
const SelectStatus = styled(Select)`
  margin-left: 4px;
  padding: 0;

  .MuiSelect-root {
    padding: 0 24px 0 0;
  }
`;
const StatusBadge = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: ${({ color }) => color};
  margin-right: 8px;
`;
const Option = styled(MenuItem)`
  padding: 2px 12px;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #787486;
`;
const HeaderInfoText = styled.p`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #787486;
`;

const isMobile = window.innerWidth <= 784;

const TicketDetailHeader = ({
  onClose,
  ticket,
  status,
  employees,
  setAttendee,
  changeStatus,
  disableActions
}) => {
  const [selectedStatus, setSelectedStatus] = useState(ticket.status);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverUserId, setPopoverUserId] = useState(null);

  const onChanceStatus = async newStatus => {
    await changeStatus(selectedStatus.id, newStatus.id);
    setSelectedStatus(newStatus);
  };

  const getOption = statusItem => {
    if (statusItem) {
      return (
        <Option value={statusItem.id}>
          <StatusBadge color={statusItem.color} />
          {statusItem.title}
        </Option>
      );
    }
    return null;
  };

  const popoverOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setPopoverUserId(id);
  };

  const popoverClose = () => {
    setAnchorEl(null);
    setPopoverUserId(null);
  };

  return (
    <Header>
      <HeaderStatus>
        <StatusBar color={ticket.status.color} />
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </HeaderStatus>
      <Title>{ticket.title}</Title>
      <Box display="flex" alignItems="center">
        <ChannelName>{`${ticket.channel.name} / `}</ChannelName>
        <FormControl variant="outlined">
          <SelectStatus
            disabled={disableActions}
            renderValue={value => {
              const foundStatus = status.find(
                statusItem => statusItem.id === value
              );
              return getOption(foundStatus);
            }}
            onChange={e => {
              const foundStatus = status.find(
                statusItem => statusItem.id === e.target.value
              );
              onChanceStatus(foundStatus);
            }}
            value={selectedStatus.id}
          >
            {status.map(statusItem => getOption(statusItem))}
          </SelectStatus>
        </FormControl>
      </Box>
      <Box
        display={isMobile ? "flex-wrap" : "flex"}
        justifyContent="space-between"
        mt={1}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={1}>
            <HeaderInfoTitle>Solicitante</HeaderInfoTitle>
            <Box display="flex" alignItems="center">
              <HeaderAvatar src={ticket.createdBy.photoProfile} />
              <HeaderName>{ticket.createdBy.name}</HeaderName>
            </Box>
          </Box>
          <TicketDetailAttendee
            attendee={ticket.attendee}
            employees={employees}
            setAttendee={setAttendee}
            disabled={disableActions}
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <Box mb={1}>
            <HeaderInfoTitle>Dependente</HeaderInfoTitle>
            {ticket.student ? (
              <Box display="flex" alignItems="center">
                <HeaderAvatar src={ticket.student.photoProfile} />
                <HoverPopover
                  id={ticket.student.id}
                  value={`Turma: ${ticket.student.classId}`}
                >
                  <HeaderName
                    highlight
                    onClick={e => popoverOpen(e, ticket.student.id)}
                  >
                    {ticket.student.name}
                  </HeaderName>
                </HoverPopover>
              </Box>
            ) : (
              <HeaderName>-</HeaderName>
            )}
          </Box>
          <TicketDetailSupervisors supervisors={ticket.supervisor} />
        </Box>
        <Box display="flex" flexDirection="column">
          <div>
            <HeaderInfoTitle>Data de criação</HeaderInfoTitle>
            <HeaderInfoText>
              {moment(ticket.createdAt).format("DD/MM/YYYY")}
            </HeaderInfoText>
          </div>
        </Box>
      </Box>
      {popoverUserId && (
        <StudentInfoPopover
          anchorEl={anchorEl}
          onClose={popoverClose}
          userId={popoverUserId}
          invertPosition
        />
      )}
    </Header>
  );
};

TicketDetailHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  ticket: PropTypes.shape({
    status: PropTypes.shape({
      id: PropTypes.number,
      color: PropTypes.string
    }),
    title: PropTypes.string,
    channel: PropTypes.shape({
      name: PropTypes.string
    }),
    createdBy: PropTypes.shape({
      name: PropTypes.string,
      photoProfile: PropTypes.string
    }),
    attendee: PropTypes.shape(),
    student: PropTypes.shape({
      name: PropTypes.string,
      photoProfile: PropTypes.string,
      id: PropTypes.string,
      classId: PropTypes.string
    }),
    supervisor: PropTypes.shape(),
    createdAt: PropTypes.string
  }).isRequired,
  status: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      color: PropTypes.string
    })
  ).isRequired,
  employees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAttendee: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  disableActions: PropTypes.bool.isRequired
};

export default TicketDetailHeader;
