import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  ArrowBack as ArrowBackIcon,
  LockOutlined,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from "@material-ui/icons";
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput
} from "@material-ui/core";
import PasswordStrengthBar from "react-password-strength-bar";
import Button from "../../components/Button/Button";
import WuiButton from "../../waybee-ui/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const MainAsset = styled.img`
  width: 327px;
`;
const LockIcon = styled(LockOutlined)`
  margin-right: 8px;
`;
const Title = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 31px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
`;
const Description = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  color: #b7b6b6;
  margin-top: 8px;
  margin-bottom: 8px;
`;
const FieldContainer = styled.div`
  width: 440px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const ChangePassword = ({ onClose, setPassword }) => {
  const [showPass, setShowPass] = useState(false);
  const [pass, setPass] = useState("");
  const [score, setScore] = useState();

  return (
    <>
      <WuiButton
        color="secondary"
        startIcon={<ArrowBackIcon />}
        size="large"
        to="/"
        onClick={onClose}
      />

      <Container>
        <MainAsset
          src="https://storage.googleapis.com/waybee-production/assets/change_password_asset.svg"
          alt="Change Password Asset"
        />
        <Title>
          <LockIcon />
          Defina sua senha
        </Title>
        <Description>Por favor digite sua nova senha</Description>

        <FieldContainer>
          <OutlinedInput
            variant="outlined"
            placeholder="Nova senha"
            fullWidth
            type={showPass ? "text" : "password"}
            onChange={e => setPass(e.target.value)}
            value={pass}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPass(!showPass)}>
                  {showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            )}
          />
          <PasswordStrengthBar
            password={pass}
            scoreWords={["fraca", "fraca", "okay", "boa", "forte"]}
            minLength={6}
            shortScoreWord="muito curta"
            onChangeScore={currentScore => setScore(currentScore)}
          />
        </FieldContainer>

        <FieldContainer>
          <Button
            color="#00303B"
            textColor="white"
            fullWidth
            disabled={score < 3}
            onClick={() => setPassword(pass)}
          >
            <Box p={0.8}>Atualizar senha</Box>
          </Button>
        </FieldContainer>
      </Container>
    </>
  );
};

ChangePassword.propTypes = {
  onClose: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired
};

export default ChangePassword;
