import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import styled, { css } from "styled-components";
import moment from "@moment";
import Avatar from "../../waybee-ui/Avatar";
import PopoverLabel from "../PopoverLabel";
import CheckColumn from "./component/CheckColumn";
import NumericColumn from "./component/NumericColumn";
import StudentInfoPopover from "../StudentInfoPopover";

const Container = styled.div`
  margin: 0 -30px -20px;
  max-height: 400px;
  overflow-y: auto;
`;

const Table = styled(MuiTable)`
  width: 100%;
  max-height: calc(100vh - 430px);
`;

const TableHead = styled(MuiTableHead)`
  tr {
    height: 48px;
  }

  th {
    color: #018675;
    padding: 0;
    border-bottom: none;
  }
`;

const TableBody = styled(MuiTableBody)`
  tr {
    height: 96px;
    padding: 18px 30px;
    border-bottom: solid #f2f2f2 2px;
  }

  td {
    color: #000;
  }
`;

const TableCell = styled(MuiTableCell)`
  font-weight: 500;
  font-size: 16px;
  text-align: center;

  @media (max-width: 784px) {
    .sc-kAyceB {
      height: fit-content;
    }
  }
`;

const TextName = styled(Typography)`
  overflow: hidden;

  ${({ showUserDetails }) =>
    showUserDetails &&
    css`
      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    `};

  @media (max-width: 784px) {
    font-size: 16px;
  }
`;

const SubscriberInfo = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 784px) {
    height: fit-content;
    align-items: center;
  }
`;

const isMobile = window.innerWidth <= 784;
const StatisticSubscriber = ({ subscribers, headers, isStudent }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverUserId, setPopoverUserId] = useState(null);

  const popoverOpen = (event, id) => {
    if (isStudent) {
      setAnchorEl(event.currentTarget);
      setPopoverUserId(id);
    }
  };

  const popoverClose = () => {
    setAnchorEl(null);
    setPopoverUserId(null);
  };

  return (
    <Container>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            {headers.map(header => (
              <TableCell align="center">{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {subscribers.map(subscriber => (
            <TableRow>
              <TableCell className="student">
                <Avatar alt="" src={subscriber.photo} size={isMobile ? 40 : 60}>
                  <SubscriberInfo>
                    <TextName
                      showUserDetails={isStudent}
                      variant="h6"
                      onClick={e => popoverOpen(e, subscriber.id)}
                    >
                      {subscriber.name}
                    </TextName>
                    <Typography variant="caption">
                      {subscriber.description}
                    </Typography>
                  </SubscriberInfo>
                </Avatar>
              </TableCell>

              {subscriber.columns.map(column => {
                switch (column.type) {
                  case "check":
                    return (
                      <TableCell align="center">
                        {column.settings && column.settings.popover ? (
                          <PopoverLabel
                            popoverContent={
                              column.value && (
                                <Typography>
                                  {moment(column.value).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </Typography>
                              )
                            }
                            label={<CheckColumn check={column.value} />}
                          />
                        ) : (
                          <CheckColumn check={column.value} />
                        )}
                      </TableCell>
                    );
                  case "numeric":
                    return (
                      <TableCell align="center">
                        <NumericColumn
                          total={column.value}
                          popoverValues={
                            column.settings && column.settings.popover
                          }
                        />
                      </TableCell>
                    );
                  case "custom":
                    return <TableCell align="center">{column.value}</TableCell>;
                  default:
                    return null;
                }
              })}
            </TableRow>
          ))}
          {popoverUserId && (
            <StudentInfoPopover
              anchorEl={anchorEl}
              onClose={popoverClose}
              userId={popoverUserId}
            />
          )}
        </TableBody>
      </Table>
    </Container>
  );
};

StatisticSubscriber.defaultProps = {
  headers: [],
  isStudent: true
};

StatisticSubscriber.propTypes = {
  subscribers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      photo: PropTypes.string,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf(["check", "numeric", "custom"]),
          // eslint-disable-next-line react/forbid-prop-types
          value: PropTypes.any,
          // eslint-disable-next-line react/forbid-prop-types
          settings: PropTypes.object
        })
      )
    })
  ).isRequired,
  headers: PropTypes.arrayOf(PropTypes.string),
  isStudent: PropTypes.bool
};

export default StatisticSubscriber;
