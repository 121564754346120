import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MuiButton from "./Button";

const Button = styled(MuiButton)`
  font-weight: 500;
`;

const ConfirmButton = props => {
  const { onClick, text } = props;
  return (
    <Button onClick={onClick} color="#00303B" textColor="white" {...props}>
      {text}
    </Button>
  );
};

ConfirmButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

export default ConfirmButton;
