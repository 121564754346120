import React, { Component } from "react";
import { Grid, InputLabel, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";
import ImageInput from "../../../components/ImageInput";
import TicketsService from "../../../services/TicketsService";

const InputText = styled(TextField)`
  margin-top: 12px;
`;

const DescriptionLabel = styled(InputLabel)`
  margin-top: 12px;
  margin-bottom: 6px;
`;

const isMobile = window.innerWidth <= 784;

class TicketChannelFormDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onUpload = async file => {
    const url = await TicketsService.uploadPhoto(file);

    return url;
  };

  render() {
    const { values, handleChange, setFieldValue, categories } = this.props;
    const { title, description, photoAvatar, ticketCategoryId, photo } = {
      ...values
    };

    return (
      <>
        <Grid container item xs={isMobile ? 12 : 3} justifyContent="center">
          <ImageInput
            imageUrl={photoAvatar || photo}
            size={isMobile ? 70 : 200}
            onChange={async file => {
              // setFieldValue(
              // "photoAvatar",
              // URL.createObjectURL(file));
              setFieldValue("photoAvatar", await this.onUpload(file));
            }}
          />
        </Grid>
        <Grid item xs={isMobile ? 10 : 5}>
          <InputText
            id="title"
            name="title"
            onChange={handleChange}
            variant="outlined"
            fullWidth
            label="Nome do Canal"
            value={title}
          />
          <Autocomplete
            id="ticketCategoryId"
            name="ticketCategoryId"
            getOptionLabel={option => option.name}
            options={categories}
            value={ticketCategoryId}
            renderInput={params => (
              <InputText
                {...params}
                label="Categoria do canal"
                fullWidth
                variant="outlined"
              />
            )}
            onChange={(event, categoryValue) => {
              setFieldValue("ticketCategoryId", categoryValue);
            }}
            renderOption={option => (
              <React.Fragment>{option.name || ""}</React.Fragment>
            )}
          />
          <DescriptionLabel>Descrição</DescriptionLabel>
          <Editor
            initialValue={description}
            id="description"
            name="description"
            apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
            init={{
              branding: false,
              height: 300,
              paste_retain_style_properties: "color font-size",
              plugins: [
                "advlist",
                "lists",
                "anchor",
                "fullscreen",
                "insertdatetime",
                "help",
                "emoticons"
              ],
              toolbar: `styles forecolor emoticons | bold italic underline strikethrough`,
              color_map: [
                "#2DC26B",
                "Green",
                "#E03E2D",
                "Red",
                "#B96AD9",
                "Purple",
                "#3598DB",
                "Blue",
                "#169179",
                "Dark Turquoise",
                "#E67E23",
                "Orange",
                "#BA372A",
                "Dark Red",
                "#843FA1",
                "Dark Purple",
                "#236FA1",
                "Dark Blue",
                "#CED4D9",
                "Medium Gray",
                "#95A5A6",
                "Gray",
                "#7E8C8D",
                "Dark Gray",
                "#34495E",
                "Navy Blue",
                "#000000",
                "Black"
              ],
              custom_colors: false,
              style_formats: [
                {
                  title: "Headings",
                  items: [
                    { title: "Heading 1", format: "h2" },
                    { title: "Heading 2", format: "h3" },
                    { title: "Heading 3", format: "h4" },
                    { title: "Paragraph", format: "p" }
                  ]
                }
              ],
              formats: {
                h2: {
                  block: "h2",
                  styles: { fontWeight: "normal" }
                },
                h3: {
                  block: "h3",
                  styles: { fontWeight: "normal" }
                },
                h4: {
                  block: "h4",
                  styles: { fontWeight: "normal" }
                }
              },
              menubar: false,
              language_url: `${window.location.protocol}//${window.location.host}/tinymce/pt_BR.js`,
              language: "pt_BR",
              paste_block_drop: false,
              link_assume_external_targets: true,
              default_link_target: "_system",
              link_default_protocol: "https",
              target_list: [{ title: "Nova Página", value: "_system" }]
            }}
            onBlur={e => setFieldValue("description", e.target.getContent())}
          />
        </Grid>
      </>
    );
  }
}

TicketChannelFormDetail.propTypes = {
  values: PropTypes.objectOf().isRequired,
  errors: PropTypes.objectOf().isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf().isRequired
};

export default TicketChannelFormDetail;
