import { api } from "../utils/api";

const getUsers = async filter => {
  const res = await api.get("WaybeeUsers/schoolEmployees", {
    params: {
      filter
    }
  });
  return res.data;
};

const createUser = async values => {
  const res = await api.post("WaybeeUsers/createCommunicationUser", {
    name: values.name,
    email: values.email,
    cpf: values.documentNumber,
    photo: values.photo,
    phone: values.phone
  });
  return res.data;
};

const updateUser = async (user, status) => {
  let data = {
    id: user.id,
    name: user.name,
    email: user.email,
    documentNumber: user.documentNumber,
    mobile: user.phone
  };

  const statusMapping = {
    1: "INACTIVE",
    0: "COMPLETED"
  };

  if (status in statusMapping) {
    data = { ...data, status: statusMapping[status] };
  }

  const res = await api.put(
    `WaybeeUsers/${data.id}/updateSchoolEmployee`,
    data
  );
  return res.data;
};

const deleteUser = async id => {
  await api.put(`WaybeeUsers/${id}/deleteSchoolEmployee`);
};

const uploadPhoto = async file => {
  const formData = new FormData();
  formData.append("fileToUpload", file);

  const res = await api.post("WaybeeUsers/uploadPhoto", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return res.data;
};

const UsersService = {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  uploadPhoto
};

export default UsersService;
