import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TextField, Box, Grid, Button, Slide } from "@material-ui/core";
import { NavigateBefore as BackIcon } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withAlert } from "react-alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Skeleton from "@material-ui/lab/Skeleton";
import ScrollColumnBody from "./ScrollColumnBody";
import ChatColumnHeader from "./ChatColumnHeader";
import MemberItem from "./MemberItem";
import MessageField from "./MessageField";
import ChatService from "../../../services/ChatService";

const SlideContainer = styled(Slide)`
  @media (min-width: 784px) {
    transition: none !important;
  }
`;
const Filters = styled(Grid)`
  border-top: 2px solid ${({ theme }) => theme.color.background.default};
  border-bottom: 2px solid ${({ theme }) => theme.color.background.default};
  padding: 15px 30px;
`;
const FilterInput = styled(Grid)``;
const MembersLabel = styled.p`
  margin: 0;
  padding: 20px 30px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
`;
const NextButton = styled(Button)`
  width: 100%;
  text-transform: none;
  text-decoration: underline;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  bottom: 0;
  height: 52px;
  border-top: 2px solid ${({ theme }) => theme.color.background.default};
  justify-content: flex-end;
  padding-right: 20px;
`;
const BackButton = styled(Button)`
  width: 100%;
  text-transform: none;
  text-decoration: underline;
  justify-content: flex-start;
  padding: 30px 20px;
  margin-top: 3px;
  font-size: 16px;
  font-weight: normal;
  border-bottom: 2px solid ${({ theme }) => theme.color.background.default};
`;
const ContainerInput = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
`;

class NewChatColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      classes: [],
      years: [],
      grades: [],
      students: [],
      filterTypes: ["Turma", "Série"],
      selectedFilter: "Turma",
      familyGroup: null,
      selectedYear: new Date().getFullYear(),
      selectedGrade: null,
      selectedClass: null,
      selectedMembers: [],
      selectedGroup: false,
      selectedStudent: null,
      messageValue: "",
      loadingStudents: false,
      loadingClasses: true,
      loadingMembers: false,
      loadingCreation: false
    };
  }

  componentDidMount() {
    const { selectedChannel } = this.props;
    this.getClasses();
    this.getChannel(selectedChannel);
  }

  componentDidUpdate(prevProps) {
    const { selectedChannel } = this.props;
    if (prevProps.selectedChannel !== selectedChannel) {
      this.getClasses();
      this.getChannel(selectedChannel);
    }
  }

  getChannel = async id => {
    const channel = await ChatService.getChannelById(id);

    if (
      channel.destinationFilter.students.classes &&
      !channel.destinationFilter.students.grades
    )
      this.setState({ filterTypes: ["Turma"], selectedFilter: "Turma" });
    if (
      !channel.destinationFilter.students.classes &&
      channel.destinationFilter.students.grades
    )
      this.setState({ filterTypes: ["Série"], selectedFilter: "Série" });
  };

  getClasses = async () => {
    const { selectedYear } = this.state;
    this.setState({ loadingClasses: true, classes: [] });
    try {
      const { selectedChannel } = this.props;
      const data = await ChatService.getClassesByChannel(
        selectedChannel,
        selectedYear
      );
      this.setState({
        selectedYear: data.years.includes(selectedYear)
          ? selectedYear
          : data.years[0],
        classes: data.classes,
        years: data.years,
        grades: data.grades,
        students: [],
        familyGroup: null,
        loadingClasses: false
      });
    } catch (e) {
      console.error(e);
      const { alert } = this.props;
      alert.show(`Erro ao buscar turmas`, { title: "Erro" });
    }
  };

  getStudents = async () => {
    try {
      const { selectedChannel } = this.props;
      const { selectedYear, selectedClass, selectedGrade } = this.state;

      const filters = { year: selectedYear };
      if (selectedClass) filters.classe = selectedClass.id;
      if (selectedGrade) filters.grade = selectedGrade.id;

      if (!filters.grade && !filters.classe) return;

      const students = await ChatService.getStudentsFilter(
        selectedChannel,
        filters
      );
      students.sort((firstEl, secondEl) => {
        if (firstEl.name < secondEl.name) {
          return -1;
        }
        if (firstEl.name > secondEl.name) {
          return 1;
        }
        return 0;
      });
      this.setState({
        students,
        familyGroup: null,
        selectedStudent: null,
        loadingStudents: false
      });
    } catch (e) {
      console.error(e);
      const { alert } = this.props;
      alert.show(`Erro ao buscar alunos`, { title: "Erro" });
    }
  };

  changeFilter = async value => {
    if (value) {
      this.setState({ students: [], loadingStudents: true });
      await this.getStudents();
    } else
      this.setState({ students: [], familyGroup: null, selectedStudent: null });
  };

  changeStudent = async (event, value) => {
    this.setState({ loadingMembers: true });
    try {
      const { selectedChannel } = this.props;
      if (value) {
        const res = await ChatService.getMembers(value.id, selectedChannel);
        res.members = res.members.map(member => ({
          ...member,
          selectedStudent: { id: value.id }
        }));
        this.setState({
          familyGroup: res,
          selectedStudent: value,
          loadingMembers: false
        });
      } else {
        this.setState({
          familyGroup: null,
          selectedStudent: null,
          loadingMembers: false
        });
      }
    } catch (e) {
      console.error(e);
      const { alert } = this.props;
      alert.show(`Erro ao buscar membros`, { title: "Erro" });
    }
  };

  onSelectMember = async (value, selectedMember, type) => {
    const { familyGroup, selectedStudent } = this.state;
    let { selectedMembers } = this.state;

    if (value) {
      const foundMember = selectedMembers.find(member => {
        return (
          member.id === selectedMember.id &&
          member.selectedStudent.id === selectedStudent.id
        );
      });
      if (foundMember) return;
    }

    if (value) {
      selectedMembers.push({
        ...selectedMember,
        cardType: type,
        selectedStudent
      });
    } else {
      selectedMembers = selectedMembers.filter(member => {
        return !(
          member.id === selectedMember.id &&
          member.selectedStudent.id === selectedMember.selectedStudent.id
        );
      });
    }

    if (familyGroup)
      familyGroup.members = familyGroup.members.map(member => {
        const newMember = member;
        if (member.id === selectedMember.id) newMember.checked = value;
        return newMember;
      });

    this.setState({
      selectedMembers,
      familyGroup
    });
  };

  sendBroadcast = async value => {
    const { selectedMembers, loadingCreation } = this.state;

    if (!loadingCreation && (!value || !value.trim())) {
      return;
    }
    this.setState({ loadingCreation: true });
    try {
      const { selectedChannel, alert } = this.props;
      await ChatService.sendBroadcast(selectedChannel, selectedMembers, value);
      this.setState({ messageValue: "", loadingCreation: false });

      alert.show(`Mensagens enviada com sucesso`, { title: "Sucesso" });

      const refreshEvent = new Event("refresh");
      document.dispatchEvent(refreshEvent);
    } catch (e) {
      console.error(e);
      const { alert } = this.props;
      alert.show(`Erro ao enviar mensagens`, { title: "Erro" });
    }
  };

  getBottomHeight = elementId => {
    const element = document.getElementById(elementId);
    if (!element) return 0;
    return element.offsetHeight;
  };

  sendFileBroadcast = async file => {
    this.setState({ loadingCreation: true });
    try {
      const { selectedChannel, alert } = this.props;
      const { selectedMembers } = this.state;

      const formData = new FormData();

      const destinations = selectedMembers.map(member => ({
        id: member.id,
        type: member.cardType,
        student: member.selectedStudent.id
      }));

      formData.append("file", file, file.name);
      formData.append("description", file.size);
      formData.append("msg", "Arquivo");
      formData.append("destinations", JSON.stringify(destinations));

      await ChatService.newFileBroadcast(selectedChannel, formData);

      alert.show(`Arquivo enviado com sucesso`, { title: "Sucesso" });
      const refreshEvent = new Event("refresh");
      document.dispatchEvent(refreshEvent);
    } catch (e) {
      console.error(e);
      const { alert } = this.props;
      alert.show(`Erro ao enviar arquivo`, { title: "Erro" });
    }
    this.setState({ loadingCreation: false });
  };

  render() {
    const { onClose, allowGroup, currentSection } = this.props;

    const {
      step,
      classes,
      years,
      grades,
      students,
      filterTypes,
      selectedFilter,
      familyGroup,
      selectedYear,
      selectedGrade,
      selectedClass,
      selectedMembers,
      selectedGroup,
      selectedStudent,
      messageValue,
      loadingStudents,
      loadingClasses,
      loadingMembers,
      loadingCreation
    } = this.state;

    const isMobile = window.innerWidth <= 784;
    const isCurrentSection = isMobile ? currentSection === "chatForm" : true;

    return (
      <SlideContainer
        direction="right"
        in={isCurrentSection}
        mountOnEnter
        unmountOnExit
      >
        <Box position="relative" height="100%">
          <Box id="new-chat-header">
            <ChatColumnHeader
              title="Nova Conversa"
              icon="close"
              onClickIcon={onClose}
              variant="outline"
            />

            {step === 1 && (
              <BackButton
                color="primary"
                onClick={() => this.setState({ step: 0 })}
              >
                <BackIcon />
                Voltar a seleção de destinatários
              </BackButton>
            )}
          </Box>

          {step === 0 && (
            <Filters id="new-chat-filter-0" container spacing={1}>
              <FilterInput item xs={5}>
                <Autocomplete
                  options={years}
                  fullWidth
                  disableClearable
                  loading={loadingClasses}
                  value={selectedYear}
                  onChange={(e, val) => {
                    this.setState({ selectedYear: val }, this.getClasses);
                  }}
                  getOptionLabel={option => option}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Ano Letivo"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingClasses ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </FilterInput>

              <FilterInput item xs={7}>
                <Autocomplete
                  options={filterTypes}
                  value={selectedFilter}
                  fullWidth
                  disableClearable
                  onChange={(e, val) => {
                    const filters = {};
                    if (val === "Turma" || !val) filters.selectedGrade = null;
                    if (val === "Série") filters.selectedClass = null;

                    this.setState({
                      selectedFilter: val,
                      ...filters
                    });
                  }}
                  renderInput={params => (
                    <TextField {...params} fullWidth label="Filtrar por:" />
                  )}
                />
              </FilterInput>

              {selectedFilter === "Série" && (
                <FilterInput item xs={12}>
                  <Autocomplete
                    options={grades}
                    fullWidth
                    loading={loadingClasses}
                    value={selectedGrade}
                    onChange={(e, val) => {
                      this.setState({ selectedGrade: val }, () =>
                        this.changeFilter(val)
                      );
                    }}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Série"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingClasses ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </FilterInput>
              )}

              {(!selectedFilter || selectedFilter === "Turma") && (
                <FilterInput item xs={12}>
                  <Autocomplete
                    options={classes}
                    fullWidth
                    loading={loadingClasses}
                    onChange={(e, val) => {
                      this.setState({ selectedClass: val }, () =>
                        this.changeFilter(val)
                      );
                    }}
                    getOptionLabel={option => option.name}
                    value={selectedClass}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Turma"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingClasses ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </FilterInput>
              )}

              {(selectedGrade || selectedClass) && (
                <FilterInput item xs={12}>
                  <Autocomplete
                    options={students}
                    loading={loadingStudents}
                    onChange={this.changeStudent}
                    value={selectedStudent}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Aluno"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingStudents ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </FilterInput>
              )}
            </Filters>
          )}

          {step === 0 && (
            <ScrollColumnBody
              bodyId="new-chat-body-0"
              subtractIds={[
                "new-chat-header",
                "new-chat-filter-0",
                "next-step-btn-0"
              ]}
              mb={52}
            >
              {loadingMembers ? (
                Array.from(new Array(3)).map(() => (
                  <Box display="flex" p={1.2}>
                    <Skeleton variant="circle" width={50} height={50} />
                    <Box flexGrow={1} ml={1} display="flex" alignItems="center">
                      <Skeleton variant="text" width="100%" height={30} />
                    </Box>
                  </Box>
                ))
              ) : (
                <span>
                  {familyGroup && (
                    <>
                      <MembersLabel>Selecionar destinatários</MembersLabel>

                      {allowGroup && familyGroup.id && (
                        <MemberItem
                          onSelect={(e, value) =>
                            this.onSelectMember(
                              value,
                              {
                                id: familyGroup.id,
                                name: familyGroup.name,
                                selectedStudent
                              },
                              "group"
                            )
                          }
                          relation="grupo"
                          name={familyGroup.name}
                          checked={selectedMembers.find(
                            member =>
                              member.id === familyGroup.id &&
                              member.cardType === "group" &&
                              member.selectedStudent.id === selectedStudent.id
                          )}
                          type="group"
                        />
                      )}

                      {familyGroup.members.map(member => (
                        <MemberItem
                          key={member.id}
                          id={member.id}
                          onSelect={(e, value) =>
                            this.onSelectMember(value, member, "private")
                          }
                          relation={member.relation}
                          name={member.name}
                          photo={member.photo}
                          disabled={selectedGroup}
                          memberType={member.typeUser}
                          checked={selectedMembers.find(
                            selectedMember =>
                              selectedMember.id === member.id &&
                              selectedMember.cardType === "private" &&
                              selectedMember.selectedStudent.id ===
                                selectedStudent.id
                          )}
                          type="private"
                        />
                      ))}
                    </>
                  )}
                </span>
              )}

              {!!selectedMembers.length && (
                <>
                  <MembersLabel>Destinatários selecionados</MembersLabel>

                  {selectedMembers.map(member => {
                    if (member.cardType === "private") {
                      return (
                        <MemberItem
                          key={`${member.id}${member.selectedStudent.id}`}
                          onDelete={() =>
                            this.onSelectMember(false, member, member.cardType)
                          }
                          relation={member.relation}
                          name={member.name}
                          photo={member.photo}
                          memberType={`${
                            member.type ? `${member.type} - ` : ""
                          } ${member.selectedStudent.name}`}
                          type="private"
                        />
                      );
                    }
                    if (member.cardType === "group") {
                      return (
                        <MemberItem
                          key={`${member.id}${member.selectedStudent.id}`}
                          onDelete={() =>
                            this.onSelectMember(false, member, member.cardType)
                          }
                          relation="grupo"
                          memberType={member.selectedStudent.name}
                          name={member.name}
                          type="group"
                        />
                      );
                    }

                    return null;
                  })}
                </>
              )}
            </ScrollColumnBody>
          )}

          {step === 1 && (
            <ScrollColumnBody
              bodyId="new-chat-body-1"
              subtractIds={["new-chat-header", "message-input-1"]}
              mb={this.getBottomHeight("message-input-1")}
            >
              {selectedMembers.map(member => {
                if (member.cardType === "private")
                  return (
                    <MemberItem
                      key={member.id}
                      onDelete={() =>
                        this.onSelectMember(false, member, member.cardType)
                      }
                      relation={member.relation}
                      name={member.name}
                      photo={member.photo}
                      memberType={
                        member.categories && member.categories.join("\n")
                      }
                      type="private"
                    />
                  );

                if (member.cardType === "group")
                  return (
                    <MemberItem
                      onDelete={() =>
                        this.onSelectMember(false, member, member.cardType)
                      }
                      relation="grupo"
                      name={familyGroup.name}
                      memberType={member.selectedStudent.name}
                      type="group"
                    />
                  );

                return null;
              })}
            </ScrollColumnBody>
          )}

          {step === 1 && (
            <ContainerInput id="message-input-1">
              <MessageField
                value={messageValue}
                onChange={value => this.setState({ messageValue: value })}
                onSend={this.sendBroadcast}
                onSendFile={this.sendFileBroadcast}
                loading={loadingCreation}
              />
            </ContainerInput>
          )}

          {step === 0 && (
            <NextButton
              id="next-step-btn-0"
              color="primary"
              disabled={!selectedMembers.length}
              onClick={() => this.setState({ step: 1 })}
            >
              Próximo
            </NextButton>
          )}
        </Box>
      </SlideContainer>
    );
  }
}

NewChatColumn.defaultProps = {
  allowGroup: true
};

NewChatColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedChannel: PropTypes.number.isRequired,
  allowGroup: PropTypes.bool,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  currentSection: PropTypes.string.isRequired
};

export default withAlert()(NewChatColumn);
