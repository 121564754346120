import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import {
  Avatar as MuiAvatar,
  Chip as MuiChip,
  IconButton
} from "@material-ui/core";
import {
  MoreHoriz as ActionsIcon,
  LockOutlined,
  BorderColor,
  Delete,
  Block,
  CheckCircleOutline
} from "@material-ui/icons";
import styled from "styled-components";
import HoverPopover from "../../../components/HoverPopover";
import ClickPopover from "../../../components/ClickPopover";

const DataGrid = styled(ReactDataGrid)`
  &.InovuaReactDataGrid {
    border: none;
    font-family: Nunito, sans-serif;
    font-size: 14px;
    min-height: 550px;
    background: #f5f5f5;
    cursor: pointer;
  }

  .InovuaReactDataGrid__header,
  .InovuaReactDataGrid__row {
    background: #f5f5f5;
  }

  .InovuaReactDataGrid__column-header {
    border: none;
  }
`;
const Avatar = styled(MuiAvatar)`
  width: 36px;
  height: 36px;
`;
const Chip = styled(MuiChip)`
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: white;
  height: 26px;
  background-color: ${({ status }) => (status === 1 ? "#6ADC35" : "#DC3545")};
`;
const LockContainer = styled.div`
  display: inline-block;
  width: 26px;
  height: 26px;
`;
const LockIcon = styled(LockOutlined)`
  width: 24px;
  height: 24px;
`;

const UserList = ({
  users,
  loading,
  onOpenDialog,
  onDelete,
  onChangeStatus
}) => {
  const [user, setUser] = useState({});

  const actions = [
    {
      title: "Editar cadastro",
      onClick: data => onOpenDialog(data, true),
      icon: <BorderColor />
    },
    {
      title: "Excluir usuário",
      onClick: data => onDelete(data),
      icon: <Delete />
    },
    {
      title: `${user.status === 1 ? "Desativar" : "Ativar"} usuário`,
      onClick: data => onChangeStatus(data, data.status),
      icon: user.status === 1 ? <Block /> : <CheckCircleOutline />
    }
  ];

  const columns = [
    {
      name: "photo",
      header: "Foto",
      width: 80,
      render: ({ data }) => <Avatar src={data.photo} />
    },
    {
      name: "name",
      header: "Nome Completo",
      defaultFlex: 1
    },
    {
      name: "email",
      header: "E-mail",
      defaultFlex: 1
    },
    {
      name: "documentNumber",
      header: "CPF",
      render: ({ data }) => data.documentNumber || "-"
    },
    {
      name: "status",
      header: "Status",
      headerAlign: "center",
      textAlign: "center",
      render: ({ data }) => (
        <Chip status={data.status} label={data.status ? "Ativo" : "Inativo"} />
      )
    },
    {
      name: "actions",
      header: "Ações",
      headerAlign: "center",
      textAlign: "center",
      onClick: (e, row) => {
        setUser(row.data);
        e.stopPropagation();
      },
      render: ({ data }) => {
        return data.isExternalUser ? (
          <LockContainer>
            <HoverPopover id={data.id} value="Usuário externo">
              <LockIcon />
            </HoverPopover>
          </LockContainer>
        ) : (
          <IconButton>
            <ClickPopover value="Usuário externo" items={actions} data={data}>
              <ActionsIcon />
            </ClickPopover>
          </IconButton>
        );
      }
    }
  ];

  const i18n = Object.assign({}, ReactDataGrid.defaultProps.i18n, {
    pageText: "Página",
    ofText: "de",
    perPageText: "Itens por página",
    showingText: "Mostrando",
    recordsText: "registros"
  });

  return (
    <DataGrid
      idProperty="id"
      showCellBorders={false}
      showZebraRows={false}
      columns={columns}
      dataSource={users}
      showColumnMenuTool={false}
      resizable={false}
      reorderColumns={false}
      cellSelectionByIndex={false}
      rowHeight={50}
      sortable={false}
      loading={loading}
      enableSelection
      onSelectionChange={row => onOpenDialog(row.data, false)}
      pagination
      limit={20}
      i18n={i18n}
    />
  );
};

UserList.defaultProps = {
  loading: false
};

UserList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onOpenDialog: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default UserList;
