import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Search as SearchIcon, Edit as EditIcon } from "@material-ui/icons";
import {
  Box,
  TextField,
  Chip,
  InputAdornment,
  IconButton,
  Slide,
  Button
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { DebounceInput } from "react-debounce-input";
import { withAlert } from "react-alert";
import { withTranslation } from "react-i18next";
import { AbilityContext, Can } from "../../../acl/can";
import ChatColumnHeader from "./ChatColumnHeader";
import ChatService from "../../../services/ChatService";
import RoundedAvatar from "../../../waybee-ui/RoundedAvatar";
import ScrollColumnBody from "./ScrollColumnBody";
import DialogTitle from "../../../waybee-ui/Dialog/DialogTitle";
import DialogContent from "../../../waybee-ui/Dialog/DialogContent";
import DialogActions from "../../../waybee-ui/Dialog/DialogActions";
import Dialog from "../../../waybee-ui/Dialog";

const SearchField = styled(DebounceInput)`
  margin-top: 45px;
  margin-bottom: 15px;
`;

const SlideContainer = styled(Slide)`
  @media (min-width: 784px) {
    transition: none !important;
  }
`;

const ChannelItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 12px;
  border-top: 2px solid ${({ theme }) => theme.color.background.default};
  background-color: ${({ selected, theme }) =>
    selected ? theme.color.primary.light : "transparent"};

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);

    .channel-unread {
      display: none;
    }

    .edit-button {
      display: flex;
    }
  }
`;

const ChannelInfos = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  &,
  * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ChannelText = styled.div`
  margin-left: 12px;
  margin-right: 25px;
`;

const ChannelName = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  white-space: nowrap;
`;

const ChannelChatsCount = styled.p`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
`;

const ChannelUnread = styled.div`
  display: flex;
  align-items: center;
`;

const ChannelEdit = styled.div`
  display: none;
  align-items: center;
`;

const isMobile = window.innerWidth <= 784;

class ChannelsColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingChannels: true,
      channels: [],
      filteredChannels: [],
      filter: "",
      openResyncModal: false
    };
  }

  componentDidMount() {
    this.getChannels();
    this.setupListener();
  }

  componentWillUnmount() {
    document.removeEventListener("onResolve", this.onResolveListener, false);
    document.removeEventListener("refresh", this.getChannels, false);
  }

  resolveChat = (roomId, resolved) => {
    const { channels } = this.state;
    const newChannels = channels.map(channel => {
      const newChannel = channel;
      newChannel.rooms = channel.rooms.map(room => {
        const newRoom = room;
        if (room.rocketChatRoomId === roomId) {
          newRoom.resolvedAt = resolved ? new Date() : null;
        }
        return newRoom;
      });

      return newChannel;
    });

    if (newChannels !== channels) {
      this.updateChannels(newChannels);
    }
  };

  onResolveListener = e => {
    this.resolveChat(e.roomId, e.resolved);
  };

  setupListener = () => {
    document.addEventListener("onResolve", this.onResolveListener, false);
    document.addEventListener("refresh", this.getChannels, false);
  };

  updateChannels = rawChannels => {
    let channels = rawChannels;
    channels = channels.map(channel => ({
      ...channel,
      metadata: {
        totalChats: channel.rooms.length,
        totalNotResolved: channel.rooms.filter(room => room.resolvedAt === null)
          .length
      }
    }));

    this.setState({
      channels,
      filteredChannels: channels,
      loadingChannels: false
    });
  };

  getChannels = async () => {
    try {
      const { onSelect } = this.props;
      const { channels } = this.state;

      const newChannels = await ChatService.getChannels();
      if (!channels.length && newChannels.length) {
        newChannels[0].selected = true;
        if (!isMobile) {
          onSelect(newChannels[0]);
        }
      } else if (channels.length && newChannels.length) {
        for (let i = 0; i < channels.length; i += 1) {
          if (channels[i].selected) newChannels[i].selected = true;
        }
      }
      this.updateChannels(newChannels);
    } catch (e) {
      console.error(e);
      const { alert, t } = this.props;
      alert.show(
        t("messages.error_loading_channels", "Erro ao carregar canais"),
        { title: "Erro" }
      );
    }
  };

  onClickChannel = (e, channel, editMode) => {
    const { onSelect } = this.props;
    let { channels } = this.state;

    if (editMode) e.stopPropagation();
    onSelect(channel, editMode);

    channels = channels.map(chann => {
      const newChannel = chann;
      if (newChannel.id === channel.id) newChannel.selected = true;
      else newChannel.selected = false;
      return newChannel;
    });

    this.setState({ channels });
  };

  onSearch = e => {
    const { channels } = this.state;
    const filter = e.target.value || "";

    const filteredChannels = channels.filter(channel =>
      channel.name.toLowerCase().includes(filter.toLowerCase())
    );

    this.setState({ filter, filteredChannels });
  };

  resyncAllChannels = async () => {
    try {
      await ChatService.resyncAllChannels();
      this.getChannels();
      this.setState({ openResyncModal: false });
    } catch (e) {
      console.error(e);
      const { alert } = this.props;
      alert.show(`Erro ao sincronizar canais\n${e.message}`, { title: "Erro" });
    }
  };

  render() {
    const {
      filteredChannels,
      filter,
      loadingChannels,
      openResyncModal
    } = this.state;
    const { onClickAdd, t, currentSection } = this.props;
    const { context } = this;

    const isCurrentSection = isMobile ? currentSection === "channelList" : true;

    return (
      <SlideContainer
        direction="right"
        mountOnEnter
        unmountOnExit
        in={isCurrentSection}
      >
        <div>
          <Box id="channel-header">
            <ChatColumnHeader
              title={t("messages.channels", "Canais")}
              icon={context.can("create", "channel") ? "add" : ""}
              resyncIcon={context.can("create", "channel") ? "sync" : ""}
              onClickResync={() => {
                this.setState({ openResyncModal: true });
              }}
              onClickIcon={onClickAdd}
            >
              <SearchField
                minLength={1}
                debounceTimeout={400}
                element={TextField}
                value={filter}
                onChange={this.onSearch}
                placeholder={t(
                  "messages.search_channels_placeholder",
                  "Procurar"
                )}
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="action" />
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
            </ChatColumnHeader>
          </Box>
          <ScrollColumnBody
            bodyId="channel-body"
            subtractIds={["channel-header"]}
          >
            {loadingChannels
              ? Array.from(new Array(3)).map(() => (
                <Box display="flex" p={1.2}>
                  <Skeleton variant="circle" width={56} height={56} />
                  <Box
                    flexGrow={1}
                    ml={1}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="50%" height={10} />
                  </Box>
                </Box>
                ))
              : filteredChannels.map(channel => (
                <ChannelItem
                  key={channel.id}
                  onClick={e => this.onClickChannel(e, channel, false)}
                  selected={channel.selected}
                >
                  <ChannelInfos>
                    <RoundedAvatar
                      imageSrc={channel.photo}
                      imageAlt="Foto do canal"
                      icon="group"
                      size={56}
                    />
                    <ChannelText>
                      <ChannelName>{channel.name}</ChannelName>
                      <ChannelChatsCount>
                        {`${channel.metadata.totalChats} ${t(
                            "messages.conversations",
                            "Conversas"
                          )}`}
                      </ChannelChatsCount>
                    </ChannelText>
                  </ChannelInfos>
                  <ChannelUnread className="channel-unread">
                    {!!channel.metadata.totalNotResolved && (
                    <Chip
                      size="small"
                      label={channel.metadata.totalNotResolved}
                      color="primary"
                    />
                      )}
                  </ChannelUnread>

                  <Can I="edit" a="channel">
                    <ChannelEdit className="edit-button">
                      <IconButton
                        color="secondary"
                        size="small"
                        onClick={e => this.onClickChannel(e, channel, true)}
                      >
                        <EditIcon />
                      </IconButton>
                    </ChannelEdit>
                  </Can>
                </ChannelItem>
                ))}
          </ScrollColumnBody>
          <Dialog
            onClose={() => this.setState({ openResyncModal: false })}
            maxWidth="sm"
            fullWidth
            open={openResyncModal}
          >
            <DialogTitle>Resincronizar canais</DialogTitle>
            <DialogContent>
              Ao clicar em &quot;Resincronizar&quot;, você estará recarregando
              as informações de TODOS os canais de conversa. Isso significa que
              todos os alunos que não se enquadram mais nos filtros aplicados
              aos canais serão removidos. Simultaneamente, quaisquer novos
              alunos que agora se enquadrem nos filtros serão adicionados aos
              canais. Certifique-se de que é isso que você deseja fazer antes de
              continuar
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.resyncAllChannels()}
              >
                Resincronizar
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => this.setState({ openResyncModal: false })}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </SlideContainer>
    );
  }
}

ChannelsColumn.contextType = AbilityContext;

ChannelsColumn.propTypes = {
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  currentSection: PropTypes.string.isRequired
};

export default withTranslation()(withAlert()(ChannelsColumn));
