import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { Editor } from "@tinymce/tinymce-react";
import _ from "lodash";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Radio,
  RadioGroup
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  AddCircleOutline as AddIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Close as CloseIcon,
  Details as DetailsIcon,
  Edit as EditIcon,
  HowToReg as HowToRegIcon,
  People as PeopleIcon,
  Visibility as VisibilityIcon
} from "@material-ui/icons";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import isBlob from "is-blob";
import UserStore from "../../utils/UserStore";
import CommunicationService from "../../services/CommunicationService";
import SchoolGroupService from "../../services/SchoolGroupService";
import FileInputService from "../../services/FileInputService";
import BugsnagService from "../../services/BugsnagService";
import Button from "../../waybee-ui/Button";
import Group from "../../waybee-ui/Group";
import Divider from "../../waybee-ui/Divider";
import Heading from "../../waybee-ui/Heading/Heading";
import ButtonBlock from "../../waybee-ui/ButtonBlock";
import BackButton from "../../components/BackButton";
import FileInput from "../../components/FileInput/FileInput";
import DialogNotification from "./components/notification/dialog_notification";
import SelectScheduleDialog from "../../components/SelectScheduleDialog";
import FilterField from "./components/FilterField";
import ImageInput from "../../components/ImageInput";
import AttachmentList from "../../components/AttachmentsList";
import ModalConfirmUpdate from "./ModalConfirmUpdate";
import { AbilityContext, Can } from "../../acl/can";
import UploadUtil from "../../utils/UploadUtil";
import LoadingContent from "../../components/LoadingContent";
import DestinationModal from "./components/DestinationModal";

const myAccess = UserStore.getAccess();

const uuidV4 = require("uuid/v4");
require("../../utils/yup.locale.pt-br");

const SurveyLegend = styled(Typography)`
  margin-top: 8px;
  display: block;
`;

const isMobile = window.innerWidth <= 784;

class CommunicationForm extends Component {
  static getAlertComponent = selectedFilter => {
    const alertMessage = {
      responsibles:
        "O comunicado será enviado APENAS para os responsáveis  dos alunos selecionados",
      students: "O comunicado será enviado APENAS para os alunos selecionados",
      employees:
        "O comunicado será enviado APENAS para os funcionários dos filtros selecionados"
    };

    if (
      !selectedFilter ||
      !Object.prototype.hasOwnProperty.call(alertMessage, selectedFilter)
    ) {
      return null;
    }

    return (
      <Alert severity="warning">
        <Typography>{alertMessage[selectedFilter]}</Typography>
      </Alert>
    );
  };

  static removeHidenValues = formValues => {
    const values = formValues;
    if (values.type !== 2) {
      values.eventStart = null;
      values.eventEnd = null;
    }
    if (!values.hasLinkButton) {
      values.linkButtons = [];
    }
    if (!values.sendNotification) {
      values.smallMessage = "";
    }
    return values;
  };

  static getTextAction = submitAction => {
    switch (submitAction) {
      case "send":
        return "Enviar";
      case "update":
        return "Editar";
      case "save":
        return "Salvar";
      case "schedule":
        return "Agendar";
      default:
        return "";
    }
  };

  state = {
    submitAction: "",
    coursesAndClasses: [],
    categories: [],
    types: [],
    studentSelectorIsOpen: false,
    modalConfirmIsOpen: false,
    scheduleDialogIsOpen: false,
    modalConfirmUpdateIsOpen: false,
    uuid: null,
    schedule: null,
    filterTypes: ["Série", "Turma"],
    selectedFilter: "Turma",
    finished: false,
    attachments: [],
    uploadImage: null,
    openPreview: false,
    uploading: false,
    loading: false,
    filters: {
      schoolYear: [],
      shift: [],
      enrollmentType: [],
      enrollmentSituation: []
    },
    id: 0,
    formValues: {
      attachments: [],
      bodyMessage: "",
      category: "",
      schoolYears: [`${new Date().getFullYear()}`],
      studentSeries: [],
      studentClasses: [],
      classesShifts: [],
      studentCourses: [],
      enrollmentTypes: [],
      enrollmentSituation: [],
      destinationFilter: {},
      eventEnd: null,
      eventStart: null,
      hasLinkButton: false,
      isAckRequired: false,
      hasSurvey: false,
      linkButtons: [{ text: "", link: "" }],
      surveyTitle: "",
      surveyEnd: null,
      surveyOptions: [
        { question: "", hasJustification: false },
        { question: "", hasJustification: false }
      ],
      photo: "",
      sendNotification: false,
      sentEmail: false,
      sentSms: false,
      smallMessage: "",
      studentSubscribers: [],
      employeeSubscribers: [],
      title: "",
      type: "",
      filterStudents: true,
      filterEmployees: false,
      responsibleTeachers: false,
      employeeClasses: [],
      employeeCourses: [],
      employeeTypes: [],
      status: "open",
      excludedDestinationIds: "",
      subscribers: [],
      selectedFilterType: "studentsAndResponsibles",
      responsibleTypes: []
    },
    warnings: {}
  };

  validationSchema = Yup.object().shape(
    {
      type: Yup.string().required('O campo "Tipo" é obrigatório'),
      eventStart: Yup.date()
        .nullable()
        .when("eventEnd", {
          is: value => value !== null,
          then: Yup.date()
            .required(
              "A data inicial deve ser preenchida após preencher a data final"
            )
            .min(new Date(2019, 0, 1)),
          otherwise: Yup.date().nullable()
        }),

      eventEnd: Yup.date()
        .nullable()
        .when("eventStart", {
          is: value => value !== null,
          then: Yup.date()
            .required(
              "A data final deve ser preenchida após preencher a data inicial"
            )
            .min(
              Yup.ref("eventStart"),
              "A data final deve ser após a data inicial"
            ),
          otherwise: Yup.date().nullable()
        }),
      photo: Yup.string().nullable(),
      category: Yup.string().required("Campo obrigatório"),
      sentSms: Yup.bool(),
      sentEmail: Yup.bool(),
      sendNotification: Yup.bool(),
      isAckRequired: Yup.bool(),
      hasLinkButton: Yup.bool(),
      filterStudents: Yup.bool(),
      filterEmployees: Yup.bool(),
      responsibleTeachers: Yup.bool(),
      smallMessage: Yup.string().when("sendNotification", {
        is: true,
        then: Yup.string().required(
          "A mensagem de notificação deve ser preenchida"
        )
      }),
      linkButtons: Yup.array().when("hasLinkButton", {
        is: true,
        then: Yup.array()
          .of(
            Yup.object().shape({
              link: Yup.string().required("O link deve ser preenchido"),
              text: Yup.string().required("O texto do link deve ser preenchido")
            })
          )
          .min(1, "Devem ser definidas ao menos 1 opção")
      }),
      surveyTitle: Yup.string().when("hasSurvey", {
        is: true,
        then: Yup.string().required("O título da enquete deve ser preenchido")
      }),
      surveyEnd: Yup.date()
        .nullable()
        .when("hasSurvey", {
          is: true,
          then: Yup.date()
            .required("data de encerramento")
            .min(new Date(), "A data não pode ser menor que hoje")
        }),
      surveyOptions: Yup.array().when("hasSurvey", {
        is: true,
        then: Yup.array()
          .of(
            Yup.object().shape({
              question: Yup.string().required(
                "O link do botão deve ser preenchido"
              ),
              hasJustification: Yup.bool()
            })
          )
          .min(2, "Devem ser definidas ao menos 2 opções")
      }),
      bodyMessage: Yup.string().notRequired(),
      // eslint-disable-next-line react/forbid-prop-types
      attachments: Yup.array(),
      title: Yup.string().required("O título deve ser preenchido"),
      // eslint-disable-next-line react/forbid-prop-types
      studentClasses: Yup.array(),
      // eslint-disable-next-line react/forbid-prop-types
      studentCourses: Yup.array(),
      // eslint-disable-next-line react/forbid-prop-types
      employeeClasses: Yup.array(),
      // eslint-disable-next-line react/forbid-prop-types
      employeeCourses: Yup.array(),
      // eslint-disable-next-line react/forbid-prop-types
      employeeTypes: Yup.array()
    },
    ["eventStart", "eventEnd"]
  );

  componentDidMount() {
    const { setTitle } = this.props;
    const { match } = this.props;
    const { id } = match.params;
    const promises = [
      this.getCoursesAndClasses(new Date().getFullYear()),
      this.getFilters(),
      this.getCategories(),
      this.getTypes(),
      this.getResponsibleType(),
      this.getWarningOptions()
    ];

    this.setState({ loading: true });

    Promise.all(promises).then(() => {
      if (id) {
        setTitle("Editar Comunicação");
        this.getCommunicationDetails(id);
      } else {
        setTitle("Nova Comunicação");
        const { uuidCode } = this.state;
        if (!uuidCode) {
          this.setState({ uuid: uuidV4() });
        }
      }
      this.setState({ loading: false });
    });
  }

  getResponsibleType = () => {
    CommunicationService.getResponsibleType().then(response => {
      const responsibleTypes = response.data;
      this.setState(prevState => ({
        formValues: {
          ...prevState.formValues,
          responsibleTypes
        }
      }));
    });
  };

  getTypes = () => {
    CommunicationService.getTypes().then(response => {
      const types = response.data.map(type => type);
      this.setState({ types });
    });
  };

  getCoursesAndClasses = async year => {
    const { openAlert } = this.props;

    try {
      const coursesAndClasses = await SchoolGroupService.getCoursesAndClasses(
        year
      );
      this.setState({ coursesAndClasses });
    } catch (e) {
      openAlert({
        status: "error",
        message: "Falha ao obter dados da comunicação",
        onClose: () => window.location.replace("/")
      });
      BugsnagService.notifyError(e);
    }
  };

  static getClassesByCourses = (courses, series) => {
    if (!courses) return [];

    let classes = [];
    courses.forEach(course => {
      if (course.VW_TURMA) classes.push(course.VW_TURMA);
    });

    classes = classes.flat();
    if (series && series.length) {
      const seriesCod = series.map(serie => serie.CODIGO);
      classes = classes.filter(classe => seriesCod.indexOf(classe.SERIE) >= 0);
    }
    return classes;
  };

  getFilters = async () => {
    const { context } = this;
    if (context.can("use module", "communicationAdvancedFilter")) {
      const res = await SchoolGroupService.getFilters();
      this.setState({ filters: res });
    }
  };

  setSubmitAction(textAction) {
    this.setState({ submitAction: textAction });
  }

  handleOpenPreview = () => {
    this.setState({ openPreview: true });
  };

  getCategories = async () => {
    return CommunicationService.getCategories().then(response => {
      const categories = response.data.map(
        categoryUser => categoryUser.category
      );
      this.setState({ categories });
    });
  };

  getCommunicationDetails = id => {
    return CommunicationService.getCommunicationDetail(id).then(response => {
      const { communication } = response;
      const formValuesEdit = {
        id: communication.id,
        attachments: [],
        bodyMessage: communication.bodyMessage,
        category: communication.category.id,
        destinationFilter: communication.destinationFilter,
        eventEnd: communication.eventEnd,
        eventStart: communication.eventStart,
        hasLinkButton: communication.hasLinkButton,
        isAckRequired: communication.isAckRequired,
        photo: communication.photo,
        sendNotification: communication.sendNotification,
        sentEmail: communication.sentEmail,
        sentSms: communication.sentSms,
        smallMessage: communication.smallMessage,
        title: communication.title,
        type: communication.type.id,
        hasSurvey: Boolean(communication.surveyId),
        status: communication.status,
        excludedDestinationIds: communication.excludedDestinationIds,
        // studentSubscribers: communication.studentSubscribers,
        linkButtons: communication.buttons.map(btn => ({
          id: btn.id,
          link: btn.link,
          text: btn.text
        })),
        surveyTitle: "",
        surveyEnd: "",
        surveyOptions: [
          { question: "", hasJustification: false },
          { question: "", hasJustification: false }
        ],
        filterStudents: true,
        filterEmployees: false,
        responsibleTeachers: false,
        employeeClasses: [],
        employeeCourses: [],
        employeeTypes: [],
        studentCourses: [],
        schoolYears: [`${new Date().getFullYear()}`],
        studentSeries: [],
        classesShifts: [],
        enrollmentTypes: [],
        enrollmentSituation: [],
        studentSubscribers: [],
        studentClasses: [],
        employeeSubscribers: [],
        selectedFilterType: communication.destinationFilter
          ? communication.destinationFilter.communicationRecipients
          : null,
        responsibleTypes: communication.destinationFilter
          ? communication.destinationFilter.responsibleRecipients
          : null
      };

      if (formValuesEdit.hasSurvey) {
        formValuesEdit.surveyOptions = communication.survey.options;
        formValuesEdit.surveyTitle = communication.survey.title;
        formValuesEdit.surveyEnd = communication.survey.limitDate;
      }
      // Carregando dados dos cursos
      if (_.get(communication, "destinationFilter.students.courses")) {
        communication.destinationFilter.students.courses.forEach(item => {
          const { coursesAndClasses } = this.state;
          const course = coursesAndClasses.find(
            itemCourse => itemCourse.CODIGO === item.CODIGO
          );

          if (course) {
            formValuesEdit.studentCourses.push(course);
          }
        });
      }

      // Carregando dados das classes
      if (_.get(communication, "destinationFilter.students.classes")) {
        communication.destinationFilter.students.classes.forEach(item => {
          const { coursesAndClasses } = this.state;
          let course;
          let classe;

          if (coursesAndClasses.length) {
            course = coursesAndClasses.find(
              itemCourse => itemCourse.CODIGO === item.CODIGO_CURSO
            );
          }

          if (course) {
            classe = course.VW_TURMA.find(
              itemClasse => itemClasse.CODIGO === item.CODIGO
            );
          }

          if (classe) {
            formValuesEdit.studentClasses.push(classe);
          }
        });
      }

      // setups selected student series
      if (
        communication.destinationFilter &&
        communication.destinationFilter.communicationRecipients
      ) {
        if (
          communication.destinationFilter.students.grades &&
          communication.destinationFilter.students.grades.length
        ) {
          let availableGrades = [];

          formValuesEdit.studentCourses.forEach(selectedCourse => {
            if (selectedCourse && selectedCourse.VW_SERIES)
              availableGrades = availableGrades.concat(
                selectedCourse.VW_SERIES
              );
          });

          communication.destinationFilter.students.grades.forEach(
            selectedGrade => {
              const foundGrade = availableGrades.find(courseGrade => {
                return (
                  courseGrade.CODIGO === selectedGrade.CODIGO &&
                  courseGrade.CODIGO_CURSO === selectedGrade.CODIGO_CURSO
                );
              });

              if (foundGrade) formValuesEdit.studentSeries.push(foundGrade);
            }
          );
        }

        // setups selected student shifts
        if (
          communication.destinationFilter.students.shifts &&
          communication.destinationFilter.students.shifts.length
        ) {
          const availableShifts = _.get(this.state, "filters.shift", []);
          communication.destinationFilter.students.shifts.forEach(
            selectedShift => {
              const foundShift = availableShifts.find(
                availableShift => availableShift === selectedShift
              );
              if (foundShift) formValuesEdit.classesShifts.push(foundShift);
            }
          );
        }

        // setups selected student enrollmentSituation
        if (
          communication.destinationFilter.students.enrollmentSituation &&
          communication.destinationFilter.students.enrollmentSituation.length
        ) {
          const availableEnrollmentSituations = _.get(
            this.state,
            "filters.enrollmentSituation",
            []
          );
          communication.destinationFilter.students.enrollmentSituation.forEach(
            selectedEnrollmentSituation => {
              const foundEnrollmentSituation = availableEnrollmentSituations.find(
                availableEnrollmentSituation =>
                  availableEnrollmentSituation.CODIGO_STATUS_MATRICULA ===
                  selectedEnrollmentSituation.CODIGO_STATUS_MATRICULA
              );
              if (foundEnrollmentSituation)
                formValuesEdit.enrollmentSituation.push(
                  foundEnrollmentSituation
                );
            }
          );
        }

        // setups selected student enrollmentType
        if (
          communication.destinationFilter.students.enrollmentTypes &&
          communication.destinationFilter.students.enrollmentTypes.length
        ) {
          const availableEnrollmentTypes = _.get(
            this.state,
            "filters.enrollmentType",
            []
          );
          communication.destinationFilter.students.enrollmentTypes.forEach(
            selectedEnrollmentType => {
              const foundEnrollmentType = availableEnrollmentTypes.find(
                availableEnrollmentType =>
                  availableEnrollmentType.CODIGO_TIPO_MATRICULA ===
                  selectedEnrollmentType.CODIGO_TIPO_MATRICULA
              );
              if (foundEnrollmentType)
                formValuesEdit.enrollmentTypes.push(foundEnrollmentType);
            }
          );
        }
      }

      formValuesEdit.attachments = communication.attachments.map(attach => ({
        id: attach.id,
        file: attach,
        name: attach.name
      }));

      this.setState({
        uuid: communication.uuid,
        id,
        attachments: formValuesEdit.attachments,
        formValues: formValuesEdit
      });
    });
  };

  getWarningOptions = async () => {
    const warnings = await CommunicationService.getWarningOptions();

    this.setState({ warnings });
  };

  save = async communication => {
    const { uploadImage, id } = this.state;
    let communicationData;

    const communicationPayload = communication;
    try {
      if (
        communicationPayload.hasLinkButton &&
        (!communicationPayload.linkButtons ||
          !communicationPayload.linkButtons.length ||
          !communicationPayload.linkButtons[0].text ||
          !communicationPayload.linkButtons[0].link)
      ) {
        communicationPayload.hasLinkButton = false;
      }

      if (id) {
        communicationData = await CommunicationService.updateCommunication(
          id,
          communicationPayload
        );
      } else {
        communicationData = await CommunicationService.saveCommunication(
          communicationPayload
        );
      }
    } catch (e) {
      throw Error("Erro ao salvar comunicação");
    }

    try {
      if (uploadImage) {
        await uploadImage();
      }
    } catch (e) {
      BugsnagService.notifyError(
        e,
        "Houve uma falha ao fazer upload da imagem de capa"
      );
      throw Error("Houve uma falha ao fazer upload da imagem de capa");
    }

    this.setState({ finished: true });
    return communicationData.communication.id;
  };

  schedule = async communication => {
    const communicationId = await this.save(communication);
    try {
      const { schedule } = this.state;
      await CommunicationService.scheduleCommunication(
        communicationId,
        schedule
      );
    } catch (e) {
      throw Error(
        `A comunicação foi salva mas houve um problema ao agendar: ${e.message}`
      );
    }
  };

  send = async communication => {
    const communicationId = await this.save(communication);
    try {
      await CommunicationService.sendCommunication(communicationId);
    } catch (e) {
      throw Error(
        `A comunicação foi salva mas houve um problema ao enviar: ${e.message}`
      );
    }
  };

  submitCommunication = async formValues => {
    const { uuid: uuidState, submitAction } = this.state;
    const { openAlert } = this.props;

    this.setState({ loading: true });

    const textAction = CommunicationForm.getTextAction(submitAction);
    const communication = CommunicationForm.removeHidenValues(formValues);
    communication.uuid = uuidState;
    const allSubscribers = communication.studentSubscribers.concat(
      communication.employeeSubscribers
    );
    communication.subscribers = allSubscribers
      .filter(e => e.checked)
      .map(e => ({ id: e.id }));
    communication.excludedDestinationIds = allSubscribers
      .filter(e => e.checked === false)
      .map(e => e.id);

    let submitPromise;
    switch (submitAction) {
      case "save":
        submitPromise = this.save;
        break;
      case "update":
        submitPromise = this.save;
        break;
      case "schedule":
        submitPromise = this.schedule;
        break;
      case "send":
        submitPromise = this.send;
        break;
      default:
    }

    try {
      await submitPromise(communication);
      const { history } = this.props;
      history.replace(`/reload`);
      setTimeout(() => {
        history.replace(`/communication`);
        openAlert({
          status: "success",
          message: `Sucesso ao ${textAction} comunicação`
        });
      });
    } catch (e) {
      this.closeModalConfirm();
      openAlert({
        status: "warning",
        title: "Atenção",
        message: e.message
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  verifyErrors(errors) {
    const { openAlert } = this.props;
    if (Object.keys(errors).length !== 0) {
      openAlert({
        status: "error",
        message: "Alguns campos não foram preeenchidos corretamente."
      });
      return true;
    }
    return false;
  }

  formErrorAlert(errorData) {
    const { openAlert } = this.props;
    const fields = {
      type: "tipo",
      category: "categoria",
      linkButtons: "link",
      title: "título",
      smallMessage: "mensagem de notificação",
      surveyEnd: "data final da enquete",
      surveyTitle: "título da enquete",
      surveyOptions: "opções da enquete"
    };

    openAlert({
      status: "error",
      message: `Os seguintes campos não foram preenchidos corretamente: ${Object.keys(
        errorData
      )
        .map(field => fields[field])
        .join(", ")}.`
    });
  }

  addAttachment(attachment) {
    this.setState(prevState => {
      prevState.attachments.push(attachment);
      return { attachments: prevState.attachments };
    });
  }

  removeAttachment(attachmentId) {
    FileInputService.deleteAttachment(attachmentId);
    this.setState(prevState => {
      return {
        attachments: prevState.attachments.filter(
          attach => attach.id !== attachmentId
        )
      };
    });
  }

  openStudentSelector() {
    this.setState({ studentSelectorIsOpen: true });
  }

  closeStudentSelector() {
    this.setState({ studentSelectorIsOpen: false });
  }

  openModalConfirm() {
    this.setState({ modalConfirmIsOpen: true });
  }

  closeModalConfirm() {
    this.setState({ modalConfirmIsOpen: false });
  }

  openModalConfirmUpdate() {
    this.setState({ modalConfirmUpdateIsOpen: true });
  }

  closeModalConfirmUpdate() {
    this.setState({ modalConfirmUpdateIsOpen: false });
  }

  openScheduleDialog() {
    this.setState({ scheduleDialogIsOpen: true });
  }

  closeScheduleDialog() {
    this.setState({ scheduleDialogIsOpen: false });
  }

  disableSubmitButtons() {
    this.setState({ uploading: true });
  }

  enableSubmitButtons() {
    this.setState({ uploading: false });
  }

  generateGenericAttachmentsName(name) {
    const { attachments } = this.state;
    const filterAttachments = attachments.filter(attachment =>
      attachment.file.name.includes(name)
    );

    if (filterAttachments.length) {
      return `${name} (${filterAttachments.length})`;
    }
    return name;
  }

  render() {
    const {
      coursesAndClasses,
      categories,
      types,
      studentSelectorIsOpen,
      modalConfirmIsOpen,
      scheduleDialogIsOpen,
      modalConfirmUpdateIsOpen,
      submitAction,
      schedule,
      filterTypes,
      selectedFilter,
      finished,
      uuid,
      attachments,
      openPreview,
      uploading,
      formValues,
      loading,
      filters,
      id,
      warnings
    } = this.state;
    const { openAlert } = this.props;
    const { context } = this;
    if (finished) {
      return <Redirect to="/communication" />;
    }

    const textAction = CommunicationForm.getTextAction(submitAction);

    const {
      schoolYear: schoolYears,
      shift: classesShifts,
      enrollmentType: enrollmentTypes,
      enrollmentSituation
    } = filters;

    const ScheduleDialog = (
      <SelectScheduleDialog
        open={scheduleDialogIsOpen}
        onClose={() => this.closeScheduleDialog()}
        value={schedule}
        onChange={value => this.setState({ schedule: value })}
        title="Agendamento de Comunicação"
        message="Escolha a data e hora para a comunicação ser enviada"
        onConfirm={() => {
          this.closeScheduleDialog();
          this.openModalConfirm();
        }}
      />
    );

    const modalConfirm = (values, setFieldValue) => {
      if (
        (!values.destinationFilter ||
          !values.destinationFilter.communicationRecipients) &&
        modalConfirmIsOpen &&
        id
      ) {
        return <> </>;
      }
      return (
        <DestinationModal
          isOpen={modalConfirmIsOpen}
          onClose={() => this.closeModalConfirm()}
          simpleFilter={{
            employeeClasses: values.employeeClasses,
            employeeCourses: values.employeeCourses,
            employeeTypes: values.employeeTypes,
            studentClasses: values.studentClasses,
            studentCourses: values.studentCourses,
            excludedIds: values.excludedDestinationIds,
            destinationType: values.selectedFilterType
          }}
          advancedFilter={{
            years: values.schoolYears,
            series: values.studentSeries,
            shift: values.classesShifts,
            enrollmentTypes: values.enrollmentTypes,
            enrollmentSituation: values.enrollmentSituation
          }}
          alert={CommunicationForm.getAlertComponent(values.selectedFilterType)}
          setSubscribers={(type, subscribers) =>
            setFieldValue(type, subscribers)
          }
          isConfirm
          onConfirm={() => this.submitCommunication(values)}
          confirmBtnText={textAction}
        />
      );
    };

    const employeeTypes = [
      { CODIGO: "PROFESSOR", NOME: "Professor" },
      { CODIGO: "COORDENADOR", NOME: "Coordenador" }
    ];

    return (
      <Formik
        validateOnBlur
        validateOnChange={false}
        initialValues={formValues}
        enableReinitialize
        validationSchema={this.validationSchema}
        onSubmit={() => {
          if (submitAction === "schedule") {
            this.openScheduleDialog();
          } else if (submitAction === "update") {
            this.openModalConfirmUpdate();
          } else {
            this.openModalConfirm();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          validateForm,
          isValid
        }) => {
          const surveyAddOption = () => {
            values.surveyOptions.push({ title: "", hasJustification: false });
            setFieldValue("surveyOptions", values.surveyOptions);
          };

          const surveyRemoveOption = index => {
            values.surveyOptions.splice(index, 1);
            setFieldValue("surveyOptions", values.surveyOptions);
          };

          const changeSurveyField = (index, field, value, event) => {
            const options = values.surveyOptions;
            options[index][field] = value;
            setFieldValue("surveyOptions", options);
            if (event) handleBlur(event);
          };

          const buttonsAddOption = () => {
            values.linkButtons.push({ text: "", link: "" });
            setFieldValue("linkButtons", values.linkButtons);
          };

          const buttonsRemoveOption = index => {
            values.linkButtons.splice(index, 1);
            setFieldValue("linkButtons", values.linkButtons);
          };

          const changeButtonsField = (index, field, value, event) => {
            const buttons = values.linkButtons;
            buttons[index][field] = value;
            setFieldValue("linkButtons", buttons);
            if (event) handleBlur(event);
          };

          let studentSeries = [];
          values.studentCourses.forEach(course => {
            studentSeries = studentSeries.concat(course.VW_SERIES);
          });

          return (
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              {loading && <LoadingContent />}

              <Grid container spacing={2}>
                <BackButton to="/communication" />

                <Grid item xs={12}>
                  <Group>
                    <Heading level={2} icon={DetailsIcon} gutterBottom>
                      Detalhes da comunicação
                    </Heading>
                    {id === 0 ||
                    (formValues.destinationFilter &&
                      formValues.destinationFilter.communicationRecipients) ? (
                        <Box pt={4} pb={2}>
                          <Grid container spacing={6}>
                            <Grid item xs={isMobile ? 12 : 6}>
                              <FormControl
                                fullWidth
                                margin="dense"
                                error={errors.type && touched.type}
                              >
                                <InputLabel id="type">
                                  Tipos de Comunicação
                                </InputLabel>
                                <Select
                                  id="type"
                                  name="type"
                                  value={values.type}
                                  onChange={(e, node) => {
                                  const selectedName = types.find(
                                    type => type.id === node.props.value
                                  ).name;
                                  const foundCategory = categories.find(
                                    category => category.name === selectedName
                                  );
                                  if (foundCategory) {
                                    setFieldValue("category", foundCategory.id);
                                    if (foundCategory.id === 19) {
                                      setFieldValue("hasLinkButton", true);
                                    }
                                  }
                                  handleChange(e, node);
                                }}
                                  onBlur={handleBlur}
                                >
                                  {/* Foi criado o tipo 99 que se refere a comunicações criadas via API */}
                                  <MenuItem value="" />
                                  {types.map(type => (
                                    <MenuItem value={type.id}>
                                      {type.name}
                                    </MenuItem>
                                ))}
                                </Select>
                                <FormHelperText>
                                  {touched.type && errors.type}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            {+values.type !== 2 ? (
                              <Grid item xs={6} implementation="css" />
                          ) : (
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={ptBrLocale}
                            >
                              <Grid item xs={isMobile ? 12 : 3}>
                                <KeyboardDateTimePicker
                                  id="eventStart"
                                  name="eventStart"
                                  margin="dense"
                                  variant="inline"
                                  ampm={false}
                                  format="dd/MM/yyyy HH:mm"
                                  label="Data e hora inicial"
                                  value={values.eventStart}
                                  onChange={value => {
                                    setFieldValue("eventStart", value);
                                  }}
                                  onBlur={handleBlur}
                                  invalidDateMessage="Data inválida"
                                  autoOk
                                  fullWidth
                                  error={
                                    errors.eventStart && touched.eventStart
                                  }
                                  helperText={
                                    touched.eventStart && errors.eventStart
                                  }
                                />
                              </Grid>
                              <Grid item xs={isMobile ? 12 : 3}>
                                <KeyboardDateTimePicker
                                  id="eventEnd"
                                  name="eventEnd"
                                  margin="dense"
                                  variant="inline"
                                  ampm={false}
                                  format="dd/MM/yyyy HH:mm"
                                  label="Data e hora final"
                                  value={values.eventEnd}
                                  onChange={value => {
                                    setFieldValue("eventEnd", value);
                                  }}
                                  onBlur={handleBlur}
                                  autoOk
                                  invalidDateMessage="Data inválida"
                                  fullWidth
                                  error={errors.eventEnd && touched.eventEnd}
                                  helperText={
                                    touched.eventEnd && errors.eventEnd
                                  }
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                          )}

                            <Grid item xs={isMobile ? 12 : 6}>
                              <FormControl
                                fullWidth
                                margin="dense"
                                error={errors.category && touched.category}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Categoria
                                </InputLabel>
                                <Select
                                  id="category"
                                  name="category"
                                  value={values.category}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <MenuItem value="" />
                                  {categories.map(category => (
                                    <MenuItem value={category.id}>
                                      {category.name}
                                    </MenuItem>
                                ))}
                                </Select>
                                <FormHelperText>
                                  {touched.category && errors.category}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item md={12}>
                              <Box py={3}>
                                <FormControl
                                  component="fieldset"
                                  fullWidth
                                  margin="dense"
                                >
                                  <FormLabel component="legend">
                                    Especificações de envio
                                  </FormLabel>
                                  <FormGroup aria-label="position">
                                    <Grid container spacing={3}>
                                      {[
                                      context.can(
                                        "use module",
                                        "sendEmailCommunication"
                                      ) && {
                                        name: "sentEmail",
                                        label: "Enviar E-mail",
                                        disabled: values.status !== "open"
                                      },
                                      {
                                        name: "sendNotification",
                                        label:
                                          "Enviar notificação da comunicação",
                                        disabled: values.status !== "open"
                                      },
                                      {
                                        name: "isAckRequired",
                                        label: "Ciência obrigatória",
                                        disabled: values.status !== "open"
                                      },
                                      {
                                        name: "hasLinkButton",
                                        label: "Ativar botão de link no aviso"
                                      },
                                      {
                                        name: "hasSurvey",
                                        label: "Adicionar Enquete"
                                      }
                                    ].map(
                                      i =>
                                        i && (
                                          <Grid item md={6}>
                                            <FormControlLabel
                                              control={(
                                                <Field
                                                  component={Checkbox}
                                                  checked={values[i.name]}
                                                  color="primary"
                                                  name={i.name}
                                                  id={i.name}
                                                  disabled={i.disabled}
                                                  onChange={e => {
                                                    if (
                                                      i.name === "hasSurvey"
                                                    ) {
                                                      setFieldValue(
                                                        "isAckRequired",
                                                        false
                                                      );
                                                    } else if (
                                                      i.name === "isAckRequired"
                                                    ) {
                                                      setFieldValue(
                                                        "hasSurvey",
                                                        false
                                                      );
                                                    }
                                                    handleChange(e);
                                                  }}
                                                  onBlur={handleBlur}
                                                />
                                              )}
                                              label={i.label}
                                            />
                                          </Grid>
                                        )
                                    )}
                                    </Grid>
                                  </FormGroup>
                                </FormControl>
                              </Box>
                            </Grid>
                            {values.sendNotification && (
                            <Fragment>
                              <Grid item spacing={2} md={12}>
                                <Heading level={2}>Notificação</Heading>
                                <Grid item md={6}>
                                  <Field
                                    component={TextField}
                                    id="smallMessage"
                                    name="smallMessage"
                                    value={values.smallMessage}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label="Mensagem da Notificação"
                                    helperText={
                                      touched.smallMessage
                                        ? errors.smallMessage
                                        : "Será mostrada no push notification e no card de aviso do aplicativo"
                                    }
                                    fullWidth
                                    margin="normal"
                                    error={
                                      errors.smallMessage &&
                                      touched.smallMessage
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={6}
                                  implementation="css"
                                  smDown
                                  component={Hidden}
                                />
                              </Grid>
                            </Fragment>
                          )}
                            {values.hasLinkButton && (
                            <Fragment>
                              <Grid item spacing={2} md={12}>
                                <Heading level={2}>Link</Heading>
                                {values.linkButtons.map((linkButton, i) => (
                                  <Grid container item={12} spacing={2}>
                                    <Grid item md={5}>
                                      <Field
                                        component={TextField}
                                        value={linkButton.text}
                                        onChange={(e, value) =>
                                          changeButtonsField(i, "text", value)
                                        }
                                        onBlur={e =>
                                          changeButtonsField(
                                            i,
                                            "text",
                                            e.target.value,
                                            e
                                          )
                                        }
                                        label="Texto para exibir no botão"
                                        helperText="ex: ‘Acesse agora’, ‘Ver mais’, ‘Inscreva-se', etc"
                                        error={
                                          errors.linkButtons &&
                                          errors.linkButtons[i] &&
                                          errors.linkButtons[i].text
                                        }
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item md={6}>
                                      <Field
                                        component={TextField}
                                        value={linkButton.link}
                                        onChange={(e, value) =>
                                          changeButtonsField(i, "link", value)
                                        }
                                        onBlur={e =>
                                          changeButtonsField(
                                            i,
                                            "link",
                                            e.target.value,
                                            e
                                          )
                                        }
                                        label="Endereço do link do botão"
                                        error={
                                          errors.linkButtons &&
                                          errors.linkButtons[i] &&
                                          errors.linkButtons[i].link
                                        }
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item md={1}>
                                      {values.linkButtons.length > 1 && (
                                        <IconButton
                                          onClick={() => buttonsRemoveOption(i)}
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                      )}
                                    </Grid>
                                  </Grid>
                                ))}

                                <Grid item xs={12}>
                                  <Button
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={buttonsAddOption}
                                  >
                                    <Typography
                                      style={{ marginBottom: 0 }}
                                      variant="caption"
                                    >
                                      Adicionar botão de link
                                    </Typography>
                                  </Button>
                                  <SurveyLegend variant="caption">
                                    Caso queira excluír uma das opções, clique
                                    no &quot;X&quot;
                                  </SurveyLegend>
                                </Grid>
                              </Grid>
                            </Fragment>
                          )}

                            {values.hasSurvey && (
                            <Fragment>
                              <Grid item spacing={2} md={12}>
                                <Heading level={2}>Enquete</Heading>
                                <Grid container item={12} spacing={2}>
                                  <Grid item md={6}>
                                    <Field
                                      id="surveyTitle"
                                      component={TextField}
                                      name="surveyTitle"
                                      value={values.surveyTitle}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      label="Pergunta da Enquete"
                                      fullWidth
                                      error={
                                        errors.surveyTitle &&
                                        touched.surveyTitle
                                      }
                                      helperText={
                                        touched.surveyTitle &&
                                        errors.surveyTitle
                                      }
                                    />
                                  </Grid>

                                  <Grid item md={4}>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                      locale={ptBrLocale}
                                    >
                                      <KeyboardDateTimePicker
                                        id="surveyEnd"
                                        name="surveyEnd"
                                        variant="inline"
                                        ampm={false}
                                        format="dd/MM/yyyy HH:mm"
                                        label="Data e hora do encerramento"
                                        value={values.surveyEnd}
                                        onChange={value => {
                                          setFieldValue("surveyEnd", value);
                                        }}
                                        minDate={new Date()}
                                        onBlur={handleBlur}
                                        autoOk
                                        invalidDateMessage="Data inválida"
                                        fullWidth
                                        error={
                                          errors.surveyEnd && touched.surveyEnd
                                        }
                                        helperText={
                                          touched.surveyEnd && errors.surveyEnd
                                        }
                                      />
                                    </MuiPickersUtilsProvider>
                                  </Grid>

                                  {values.surveyOptions.map((option, i) => (
                                    <>
                                      <Grid item md={6}>
                                        <Field
                                          component={TextField}
                                          value={option.question}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                {values.surveyOptions.length >
                                                  2 && (
                                                  <IconButton
                                                    onClick={() =>
                                                      surveyRemoveOption(i)
                                                    }
                                                  >
                                                    <CloseIcon />
                                                  </IconButton>
                                                )}
                                              </InputAdornment>
                                            )
                                          }}
                                          onChange={(e, value) =>
                                            changeSurveyField(
                                              i,
                                              "question",
                                              value
                                            )
                                          }
                                          onBlur={e =>
                                            changeSurveyField(
                                              i,
                                              "question",
                                              e.target.value,
                                              e
                                            )
                                          }
                                          label={`Opção de Resposta ${i + 1}`}
                                          fullWidth
                                          start
                                          error={
                                            errors.surveyOptions &&
                                            errors.surveyOptions[i] &&
                                            errors.surveyOptions[i].question
                                          }
                                          helperText={
                                            errors.surveyOptions &&
                                            errors.surveyOptions[i] &&
                                            errors.surveyOptions[i].question
                                          }
                                        />
                                      </Grid>
                                      <Grid container md={6}>
                                        <FormControlLabel
                                          control={(
                                            <Field
                                              component={Checkbox}
                                              checked={option.hasJustification}
                                              onChange={(e, value) =>
                                                changeSurveyField(
                                                  i,
                                                  "hasJustification",
                                                  value
                                                )
                                              }
                                              color="primary"
                                            />
                                          )}
                                          label="Opção necessita de justificativa"
                                        />
                                      </Grid>
                                    </>
                                  ))}
                                </Grid>

                                <Grid item xs={12}>
                                  {values.surveyOptions.length < 10 && (
                                    <Button
                                      color="primary"
                                      startIcon={<AddIcon />}
                                      onClick={surveyAddOption}
                                    >
                                      <Typography
                                        style={{ marginBottom: 0 }}
                                        variant="caption"
                                      >
                                        Adicionar Opção à Enquete
                                      </Typography>
                                    </Button>
                                  )}
                                  <SurveyLegend variant="caption">
                                    Caso queira excluír uma das opções, clique
                                    no &quot;X&quot;
                                  </SurveyLegend>
                                </Grid>
                              </Grid>
                            </Fragment>
                          )}
                          </Grid>
                        </Box>
                    ) : (
                      <Typography>
                        Não é possível editar o tipo e a categoria de uma
                        comunicação enviada externamente
                        {" "}
                      </Typography>
                    )}
                  </Group>
                </Grid>

                <Grid item xs={12}>
                  <Group>
                    <Heading level={2} icon={PeopleIcon} gutterBottom>
                      Público da comunicação
                    </Heading>
                    {id === 0 ||
                    (values.destinationFilter &&
                      values.destinationFilter.communicationRecipients) ? (
                        <Box py={4}>
                          <Grid container spacing={5}>
                            {(!myAccess.classDiary ||
                            values.status === "sent") && (
                            <Grid item container spacing={2} xs={12}>
                              <Grid item xs={12}>
                                <FormLabel>
                                  Assinale para quem deseja enviar a
                                  comunicação:
                                </FormLabel>
                              </Grid>
                              <Grid item xs={12}>
                                <RadioGroup
                                  style={{ flexDirection: "row" }}
                                  value={values.selectedFilterType}
                                  onChange={(event, value) => {
                                    handleChange(event, value);
                                    setFieldValue("selectedFilterType", value);
                                    if (!value) {
                                      setFieldValue("studentCourses", []);
                                      setFieldValue("studentClasses", []);
                                    }
                                  }}
                                >
                                  <FormControlLabel
                                    value="studentsAndResponsibles"
                                    control={(
                                      <Field
                                        id="filterStudentsAndResponsibles"
                                        name="filterStudentsAndResponsibles"
                                        component={Radio}
                                        disabled={values.status === "sent"}
                                        color="primary"
                                      />
                                    )}
                                    label="Alunos e Responsáveis"
                                  />
                                  {context.can(
                                    "use module",
                                    "communicationResponsible"
                                  ) && (
                                    <FormControlLabel
                                      value="responsibles"
                                      control={(
                                        <Field
                                          id="filterResponsibles"
                                          name="filterResponsibles"
                                          component={Radio}
                                          disabled={values.status === "sent"}
                                          color="primary"
                                        />
                                      )}
                                      label="Apenas Responsáveis"
                                    />
                                  )}
                                  {context.can(
                                    "use module",
                                    "communicationStudent"
                                  ) && (
                                    <FormControlLabel
                                      value="students"
                                      control={(
                                        <Field
                                          id="filterStudents"
                                          name="filterStudents"
                                          component={Radio}
                                          disabled={values.status === "sent"}
                                          color="primary"
                                        />
                                      )}
                                      label="Apenas Alunos"
                                    />
                                  )}
                                  {context.can(
                                    "use module",
                                    "communicationEmployee"
                                  ) && (
                                    <FormControlLabel
                                      value="employees"
                                      control={(
                                        <Field
                                          id="filterEmployees"
                                          name="filterEmployees"
                                          component={Radio}
                                          disasble={values.status === "sent"}
                                          color="primary"
                                          onChange={(event, value) => {
                                            handleChange(event, value);
                                            setFieldValue(
                                              "selectedFilterType",
                                              value
                                            );
                                            if (!value) {
                                              setFieldValue(
                                                "employeeCourses",
                                                []
                                              );
                                              setFieldValue(
                                                "employeeClasses",
                                                []
                                              );
                                              setFieldValue(
                                                "employeeTypes",
                                                []
                                              );
                                            } else if (!myAccess.classDiary) {
                                              setFieldValue(
                                                "responsibleTeachers",
                                                true
                                              );
                                              setFieldValue(
                                                "employeeCourses",
                                                values.studentCourses
                                              );
                                              setFieldValue(
                                                "employeeClasses",
                                                values.studentClasses
                                              );
                                              setFieldValue("employeeTypes", [
                                                employeeTypes.find(
                                                  type =>
                                                    type.CODIGO === "PROFESSOR"
                                                )
                                              ]);
                                            }
                                          }}
                                        />
                                      )}
                                      label="Apenas Professores/Coordenadores"
                                    />
                                  )}
                                </RadioGroup>
                              </Grid>
                            </Grid>
                          )}

                            {values.filterStudents && (
                            <>
                              <Grid item xs={12}>
                                <Box>
                                  <FormLabel component="legend">
                                    Selecione os filtros:
                                  </FormLabel>
                                </Box>
                              </Grid>
                              <Can
                                I="use module"
                                a="communicationAdvancedFilter"
                              >
                                <Grid item xs={12} sm={6}>
                                  <FilterField
                                    label="Ano Letivo"
                                    disabled={values.status === "sent"}
                                    onChange={(event, yearsValues) => {
                                      setFieldValue("schoolYears", yearsValues);
                                      this.getCoursesAndClasses(yearsValues);
                                    }}
                                    error={
                                      errors.schoolYears && touched.schoolYears
                                    }
                                    helperText={
                                      touched.schoolYears && errors.schoolYears
                                    }
                                    name="schoolYears"
                                    options={schoolYears}
                                    value={values.schoolYears}
                                  />
                                </Grid>
                              </Can>
                              <Grid item xs={12} sm={6}>
                                <Autocomplete
                                  multiple
                                  id="studentCourses"
                                  noOptionsText="Não foram encontrados cursos para exibir, verifique com um administrador do sistema se você possui as permissões necessárias"
                                  disabled={values.status === "sent"}
                                  name="studentCourses"
                                  value={values.studentCourses}
                                  onChange={(event, coursesValues) => {
                                    if (
                                      coursesValues.length === 0 &&
                                      !myAccess.classDiary
                                    ) {
                                      setFieldValue("filterEmployees", false);
                                      setFieldValue(
                                        "responsibleTeachers",
                                        false
                                      );
                                      setFieldValue("employeeCourses", []);
                                      setFieldValue("employeeClasses", []);
                                      setFieldValue("employeeTypes", []);
                                    }
                                    setFieldValue(
                                      "studentCourses",
                                      coursesValues
                                    );
                                    const filteredClasses = CommunicationForm.getClassesByCourses(
                                      coursesValues
                                    ).filter(
                                      classItem =>
                                        values.studentClasses.filter(
                                          i => i.CODIGO === classItem.CODIGO
                                        ).length
                                    );
                                    setFieldValue(
                                      "studentClasses",
                                      filteredClasses
                                    );
                                    if (values.responsibleTeachers) {
                                      setFieldValue(
                                        "employeeCourses",
                                        coursesValues
                                      );
                                      setFieldValue(
                                        "employeeClasses",
                                        filteredClasses
                                      );
                                    }
                                  }}
                                  options={coursesAndClasses}
                                  getOptionLabel={option => option.NOME || ""}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      label="Cursos"
                                      margin="dense"
                                      fullWidth
                                      error={
                                        errors.studentCourses &&
                                        touched.studentCourses
                                      }
                                      helperText={
                                        touched.studentCourses &&
                                        errors.studentCourses
                                      }
                                    />
                                  )}
                                  disableCloseOnSelect
                                  renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                      <Checkbox
                                        color="primary"
                                        icon={<CheckBoxOutlineBlankIcon />}
                                        checkedIcon={<CheckBoxIcon />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.NOME || ""}
                                    </React.Fragment>
                                  )}
                                />
                              </Grid>

                              {!!values.studentCourses.length && (
                                <>
                                  {values.selectedFilterType !==
                                    "employees" && (
                                    <Can
                                      I="use module"
                                      a="communicationAdvancedFilter"
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        style={{ padding: "0 25px 0 25px" }}
                                      >
                                        <Autocomplete
                                          options={filterTypes}
                                          value={selectedFilter}
                                          fullWidth
                                          disableClearable
                                          onChange={(e, val) => {
                                            if (val === "Turma" || !val) {
                                              setFieldValue(
                                                "studentSeries",
                                                []
                                              );
                                            }
                                            if (val === "Série") {
                                              setFieldValue(
                                                "studentClasses",
                                                []
                                              );
                                            }

                                            this.setState({
                                              selectedFilter: val
                                            });
                                          }}
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              label="Filtrar por:"
                                            />
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        {selectedFilter === "Série" && (
                                          <FilterField
                                            disabled={values.status === "sent"}
                                            label="Série"
                                            onChange={(event, seriesValues) => {
                                              setFieldValue(
                                                "studentSeries",
                                                seriesValues
                                              );
                                              const newValues = values.studentClasses.filter(
                                                classe =>
                                                  seriesValues
                                                    .map(serie => serie.CODIGO)
                                                    .indexOf(classe.SERIE) >= 0
                                              );
                                              setFieldValue(
                                                "studentClasses",
                                                newValues
                                              );
                                            }}
                                            error={
                                              errors.studentSeries &&
                                              touched.studentSeries
                                            }
                                            helperText={
                                              touched.studentSeries &&
                                              errors.studentSeries
                                            }
                                            name="studentSeries"
                                            options={studentSeries}
                                            value={values.studentSeries}
                                            labelField="NOME"
                                          />
                                        )}
                                        {(!selectedFilter ||
                                          selectedFilter === "Turma") && (
                                          <Autocomplete
                                            multiple
                                            id="studentClasses"
                                            disabled={values.status === "sent"}
                                            noOptionsText="Não foram encontradas turmas para exibir, verifique com um administrador do sistema se você possui as permissões necessárias"
                                            name="studentClasses"
                                            options={CommunicationForm.getClassesByCourses(
                                              values.studentCourses
                                            )}
                                            getOptionLabel={option =>
                                              option.NOME
                                            }
                                            onChange={(
                                              event,
                                              classesValues
                                            ) => {
                                              setFieldValue(
                                                "studentClasses",
                                                classesValues
                                              );
                                              if (values.responsibleTeachers)
                                                setFieldValue(
                                                  "employeeClasses",
                                                  classesValues
                                                );
                                            }}
                                            value={values.studentClasses}
                                            renderInput={params => (
                                              <TextField
                                                {...params}
                                                label="Turmas"
                                                margin="dense"
                                                fullWidth
                                                error={
                                                  errors.studentClasses &&
                                                  touched.studentClasses
                                                }
                                                helperText={
                                                  touched.studentClasses &&
                                                  errors.studentClasses
                                                }
                                              />
                                            )}
                                            disableCloseOnSelect
                                            renderOption={(
                                              option,
                                              { selected }
                                            ) => (
                                              <React.Fragment>
                                                <Checkbox
                                                  color="primary"
                                                  icon={
                                                    <CheckBoxOutlineBlankIcon />
                                                  }
                                                  checkedIcon={<CheckBoxIcon />}
                                                  style={{ marginRight: 8 }}
                                                  checked={selected}
                                                />
                                                {option.NOME}
                                              </React.Fragment>
                                            )}
                                          />
                                        )}
                                      </Grid>
                                    </Can>
                                  )}
                                  {values.selectedFilterType !==
                                    "employees" && (
                                    <Can
                                      I="use module"
                                      a="communicationAdvancedFilter"
                                    >
                                      <Grid item xs={12} sm={6}>
                                        <FilterField
                                          disabled={values.status === "sent"}
                                          label="Turno"
                                          onChange={(event, shiftsValues) => {
                                            setFieldValue(
                                              "classesShifts",
                                              shiftsValues
                                            );
                                          }}
                                          error={
                                            errors.classesShifts &&
                                            touched.classesShifts
                                          }
                                          helperText={
                                            touched.classesShifts &&
                                            errors.classesShifts
                                          }
                                          name="classesShifts"
                                          options={classesShifts}
                                          value={values.classesShifts}
                                        />
                                      </Grid>

                                      <Grid item xs={12} sm={6}>
                                        <FilterField
                                          disabled={values.status === "sent"}
                                          label="Tipo de Matrícula"
                                          onChange={(
                                            event,
                                            enrollmentTypesValues
                                          ) => {
                                            setFieldValue(
                                              "enrollmentTypes",
                                              enrollmentTypesValues
                                            );
                                          }}
                                          error={
                                            errors.enrollmentTypes &&
                                            touched.enrollmentTypes
                                          }
                                          helperText={
                                            touched.enrollmentTypes &&
                                            errors.enrollmentTypes
                                          }
                                          name="enrollmentTypes"
                                          options={enrollmentTypes}
                                          value={values.enrollmentTypes}
                                          labelField="NOME_TIPO_MATRICULA"
                                        />
                                      </Grid>

                                      <Grid item xs={12} sm={6}>
                                        <FilterField
                                          disabled={values.status === "sent"}
                                          label="Situação de Matrícula"
                                          onChange={(
                                            event,
                                            enrollmentSitValues
                                          ) => {
                                            setFieldValue(
                                              "enrollmentSituation",
                                              enrollmentSitValues
                                            );
                                          }}
                                          error={
                                            errors.enrollmentSituation &&
                                            touched.enrollmentSituation
                                          }
                                          helperText={
                                            touched.enrollmentSituation &&
                                            errors.enrollmentSituation
                                          }
                                          name="enrollmentSituation"
                                          options={enrollmentSituation}
                                          value={values.enrollmentSituation}
                                          labelField="NOME_STATUS_MATRICULA"
                                        />
                                      </Grid>
                                    </Can>
                                  )}
                                </>
                              )}

                              {values.selectedFilterType === "responsibles" && (
                                <Grid item xs={12} sm={6}>
                                  <Autocomplete
                                    multiple
                                    id="responsibleTypes"
                                    noOptionsText="Não foram encontrados tipos de responsáveis para exibir, verifique com um administrador do sistema se você possui as permissões necessárias"
                                    disabled={values.status === "sent"}
                                    name="responsibleTypes"
                                    value={values.responsibleTypes}
                                    defaultValue={[...values.responsibleTypes]}
                                    onChange={(event, value) => {
                                      handleChange(event, value);
                                      setFieldValue("responsibleTypes", value);
                                    }}
                                    options={formValues.responsibleTypes}
                                    getOptionLabel={option => option.name}
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        label="Tipo de Responsável"
                                        margin="dense"
                                        fullWidth
                                        error={
                                          errors.responsibleTypes &&
                                          touched.responsibleTypes
                                        }
                                        helperText={
                                          touched.responsibleTypes &&
                                          errors.responsibleTypes
                                        }
                                      />
                                    )}
                                    disableCloseOnSelect
                                    renderOption={(option, { selected }) => (
                                      <React.Fragment>
                                        <Checkbox
                                          color="primary"
                                          icon={<CheckBoxOutlineBlankIcon />}
                                          checkedIcon={<CheckBoxIcon />}
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                        />
                                        {option.name}
                                      </React.Fragment>
                                    )}
                                  />
                                </Grid>
                              )}
                            </>
                          )}

                            {values.selectedFilterType === "employees" && (
                            <Grid item xs={12} sm={6}>
                              <Autocomplete
                                multiple
                                noOptionsText="Não foram encontrados tipos de funcionários para exibir, verifique com um administrador do sistema se você possui as permissões necessárias"
                                id="employeeTypes"
                                name="employeeTypes"
                                options={employeeTypes}
                                disabled={values.status === "sent"}
                                getOptionLabel={option => option.NOME}
                                value={values.employeeTypes}
                                defaultValue={[...values.employeeTypes]}
                                onChange={(event, typesValues) => {
                                  setFieldValue("employeeTypes", typesValues);
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Cargo"
                                    margin="dense"
                                    fullWidth
                                    error={
                                      errors.employeeTypes &&
                                      touched.employeeTypes
                                    }
                                    helperText={
                                      touched.employeeTypes &&
                                      errors.employeeTypes
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          )}
                          </Grid>
                        </Box>
                    ) : (
                      <Box py={4}>
                        <Typography>
                          Essa comunicação foi enviada para pessoas específicas
                        </Typography>
                      </Box>
                    )}
                    <Divider />
                    <Group.GroupFooter>
                      <ButtonBlock
                        startIcon={<HowToRegIcon />}
                        disabled={
                          (!values.studentCourses.length &&
                            !values.employeeCourses.length) ||
                          values.status === "sent" ||
                          !values.destinationFilter
                        }
                        onClick={() => this.openStudentSelector()}
                      >
                        Gerenciar destinatários
                        {" "}
                        {values.status === "sent" &&
                          formValues.destinationFilter !== null &&
                          "- Não é possível alterar os destinatários de uma comunicação já enviada"}
                        {id !== 0 &&
                          (formValues.destinationFilter === null ||
                            formValues.destinationFilter
                              .communicationRecipients === null) &&
                          "- Não é possível alterar os destinatários de uma comunicação enviada externamente"}
                      </ButtonBlock>
                    </Group.GroupFooter>
                  </Group>
                </Grid>

                <Grid item xs={12}>
                  <Group>
                    <Heading level={2} icon={EditIcon} gutterBottom>
                      Conteúdo da comunicação
                    </Heading>
                    <Box py={4}>
                      <Grid container spacing={6} alignItems="flex-end">
                        <Grid item md={6}>
                          <Field
                            component={TextField}
                            id="title"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="Título"
                            fullWidth
                            margin="dense"
                            error={errors.title && touched.title}
                            helperText={touched.title && errors.title}
                          />
                        </Grid>

                        <Grid item md={6}>
                          <ImageInput
                            imageUrl={values.photo}
                            label="Imagem de capa da comunicação"
                            onChange={file => {
                              setFieldValue("photo", URL.createObjectURL(file));
                              this.setState({
                                uploadImage: async () =>
                                  FileInputService.uploadCoverImage(file, {
                                    uuid
                                  })
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} alignItems="center">
                        {warnings.photoWarning && (
                          <Grid item xs={12}>
                            <Alert severity="info">
                              {warnings.photoWarning}
                            </Alert>
                          </Grid>
                        )}
                      </Grid>
                      <Grid container spacing={6} alignItems="center">
                        <Grid item md={12}>
                          <InputLabel>Texto da Comunicação</InputLabel>
                          <Editor
                            initialValue={values.bodyMessage}
                            id="bodyMessage"
                            name="bodyMessage"
                            apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                            init={{
                              branding: false,
                              height: 300,
                              setup: editor => {
                                editor.on("KeyDown", e => {
                                  if (
                                    (e.keyCode === 8 || e.keyCode === 46) &&
                                    editor.selection
                                  ) {
                                    const selectedNode = editor.selection.getNode();
                                    if (
                                      selectedNode &&
                                      selectedNode.nodeName === "IMG"
                                    ) {
                                      const selectedAttachment = attachments.find(
                                        attachment =>
                                          attachment.file.url ===
                                          selectedNode.src
                                      );
                                      this.removeAttachment(
                                        selectedAttachment.id
                                      );
                                    }
                                  }
                                });
                              },
                              images_upload_handler: blobInfo =>
                                new Promise(async (resolve, reject) => {
                                  const { uuid: uuidCopy } = this.state;
                                  let file = blobInfo.blob();
                                  if (!isBlob(file)) {
                                    const base64Response = await fetch(
                                      blobInfo.blobUri()
                                    );
                                    file = await base64Response.blob();
                                  }

                                  const filename = this.generateGenericAttachmentsName(
                                    "Anexo"
                                  );

                                  const attachment = await UploadUtil.communicationUploadAttachment(
                                    file,
                                    filename,
                                    uuidCopy
                                  );

                                  if (attachment) {
                                    this.addAttachment(attachment);
                                    resolve(attachment.file.url);
                                  } else {
                                    reject(
                                      new Error(
                                        "Erro ao fazer o upload da imagem"
                                      )
                                    );
                                  }
                                }),
                              paste_data_images: true,
                              paste_retain_style_properties: "color font-size",
                              plugins: [
                                "accordion",
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "print",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "fullscreen",
                                "insertdatetime",
                                "table",
                                "help",
                                "wordcount",
                                "image",
                                "link",
                                "powerpaste",
                                "code",
                                "pageembed",
                                "emoticons",
                                "code",
                                "pagebreak",
                                "tinymcespellchecker"
                              ],
                              powerpaste_allow_local_images: true,
                              powerpaste_word_import: "prompt",
                              powerpaste_html_import: "prompt",
                              font_family_formats:
                                "Arial=arial,helvetica,sans-serif; Helvetica=helvetica; Times New Roman=times new roman,times;",
                              pagebreak_separator:
                                "────────────────────────────────",
                              spellchecker_language: "pt_BR",
                              toolbar: `styles fontfamily forecolor emoticons | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify pagebreak| bullist numlist | table | link image accordion | code spellchecker searchreplace `,
                              color_map: [
                                "#2DC26B",
                                "Green",
                                "#E03E2D",
                                "Red",
                                "#B96AD9",
                                "Purple",
                                "#3598DB",
                                "Blue",
                                "#169179",
                                "Dark Turquoise",
                                "#E67E23",
                                "Orange",
                                "#BA372A",
                                "Dark Red",
                                "#843FA1",
                                "Dark Purple",
                                "#236FA1",
                                "Dark Blue",
                                "#CED4D9",
                                "Medium Gray",
                                "#95A5A6",
                                "Gray",
                                "#7E8C8D",
                                "Dark Gray",
                                "#34495E",
                                "Navy Blue",
                                "#000000",
                                "Black"
                              ],
                              custom_colors: false,
                              style_formats: [
                                {
                                  title: "Headings",
                                  items: [
                                    { title: "Heading 1", format: "h2" },
                                    { title: "Heading 2", format: "h3" },
                                    { title: "Heading 3", format: "h4" },
                                    { title: "Paragraph", format: "p" }
                                  ]
                                }
                              ],
                              formats: {
                                h2: {
                                  block: "h2",
                                  styles: { fontWeight: "normal" }
                                },
                                h3: {
                                  block: "h3",
                                  styles: { fontWeight: "normal" }
                                },
                                h4: {
                                  block: "h4",
                                  styles: { fontWeight: "normal" }
                                }
                              },
                              menubar: false,
                              language_url: `${window.location.protocol}//${window.location.host}/tinymce/pt_BR.js`,
                              language: "pt_BR",
                              link_assume_external_targets: true,
                              default_link_target: "_system",
                              link_default_protocol: "https",
                              target_list: [
                                { title: "Nova Página", value: "_system" }
                              ]
                            }}
                            onBlur={e =>
                              setFieldValue(
                                "bodyMessage",
                                e.target.getContent()
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider />
                    <Divider />
                    <Box py={4}>
                      {/* <Button
                        color="secondary"
                        startIcon={<AddCircleIcon />}
                        size="small"
                      >
                        Adicionar anexos
                      </Button> */}
                      {warnings.attachmentWarning && (
                        <Grid item xs={12}>
                          <Alert severity="info">
                            {warnings.attachmentWarning}
                          </Alert>
                        </Grid>
                      )}
                      <FileInput
                        accept="image/png,image/jpeg,application/pdf"
                        name="attachments"
                        id="attachments"
                        label="Anexo"
                        fieldOptions={{ uuid }}
                        upload={FileInputService.uploadAttachment}
                        status="NOT_SENT"
                        onError={error => {
                          if (error.response) {
                            const errorData = error.response.data.error;
                            const { message } = errorData;
                            openAlert({ status: "error", message });
                          } else {
                            openAlert({
                              status: "error",
                              message: "Falha durante upload do arquivo."
                            });
                          }
                          this.enableSubmitButtons();
                        }}
                        onSuccess={(response, file) => {
                          const attachment = {
                            id: response.attachmentId,
                            file
                          };
                          this.addAttachment(attachment);
                          this.enableSubmitButtons();
                        }}
                        onChange={() => this.disableSubmitButtons()}
                      />
                      {!!attachments.length && (
                        <Box my={4}>
                          <AttachmentList
                            attachments={attachments}
                            onDelete={attachment =>
                              this.removeAttachment(attachment.id)
                            }
                          />
                        </Box>
                      )}
                    </Box>
                    <Group.GroupFooter>
                      <ButtonBlock
                        startIcon={<VisibilityIcon />}
                        onClick={() => {
                          validateForm().then(() => {
                            this.handleOpenPreview();
                          });
                        }}
                      >
                        Visualizar Comunicação
                      </ButtonBlock>
                    </Group.GroupFooter>
                  </Group>
                </Grid>
              </Grid>
              <Box my={isMobile ? 5 : 9} mx={isMobile ? 2 : 5}>
                <Grid container spacing={isMobile ? 3 : 6}>
                  <Grid item xs={isMobile ? 12 : 6}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      disabled={uploading}
                      onClick={() => {
                        validateForm().then(async () => {
                          if (isValid) {
                            this.setSubmitAction("save");
                            if (
                              id !== 0 &&
                              (!values.destinationFilter ||
                                !values.destinationFilter
                                  .communicationRecipients)
                            ) {
                              await this.submitCommunication(values);
                            }
                          } else {
                            this.formErrorAlert();
                          }
                        });
                      }}
                    >
                      Salvar
                    </Button>
                  </Grid>
                  {formValues.status === "open" && (
                    <Grid item xs={isMobile ? 12 : 6}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        fullWidth
                        disabled={uploading}
                        onClick={() => {
                          validateForm().then(data => {
                            if (isValid) {
                              this.setSubmitAction("schedule");
                            } else {
                              this.formErrorAlert(data);
                            }
                          });
                        }}
                      >
                        Agendar Envio
                      </Button>
                    </Grid>
                  )}
                  {formValues.status === "open" && (
                    <Grid item xs={isMobile ? 12 : 6}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={uploading}
                        onClick={() => {
                          validateForm().then(data => {
                            if (isValid) {
                              this.setSubmitAction("send");
                            } else {
                              this.formErrorAlert(data);
                            }
                          });
                        }}
                      >
                        Enviar agora
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <ModalConfirmUpdate
                isOpen={modalConfirmUpdateIsOpen}
                onClose={() => this.closeModalConfirmUpdate()}
                onConfirm={() => this.submitCommunication(values)}
                textAction="Alterar informações da comunicação"
                alert={CommunicationForm.getAlertComponent(
                  values.selectedFilterType
                )}
              />
              <DestinationModal
                isOpen={studentSelectorIsOpen}
                onClose={() => this.closeStudentSelector()}
                simpleFilter={{
                  employeeClasses: values.employeeClasses,
                  employeeCourses: values.employeeCourses,
                  employeeTypes: values.employeeTypes,
                  studentClasses: values.studentClasses,
                  studentCourses: values.studentCourses,
                  excludedIds: values.excludedDestinationIds,
                  destinationType: values.selectedFilterType
                }}
                advancedFilter={{
                  years: values.schoolYears,
                  series: values.studentSeries,
                  shift: values.classesShifts,
                  enrollmentTypes: values.enrollmentTypes,
                  enrollmentSituation: values.enrollmentSituation
                }}
                alert={CommunicationForm.getAlertComponent(
                  values.selectedFilterType
                )}
                setSubscribers={(type, subscribers) =>
                  setFieldValue(type, subscribers)
                }
                isConfirm={false}
                confirmBtnText="Salvar"
              />
              {ScheduleDialog}
              {modalConfirm(values, setFieldValue)}
              <DialogNotification
                open={openPreview}
                notificationClicked={{
                  bodyMessage: values.bodyMessage,
                  title: values.title,
                  icon: UserStore.getUserPhoto(),
                  createdAt: new Date(),
                  type: values.type,
                  photo: values.photo,
                  eventStart: values.eventStart
                }}
                schoolName={UserStore.getUserSchoolName()}
                onClose={() => this.setState({ openPreview: false })}
                communicationAttachments={attachments.map(
                  attach => attach.file
                )}
                survey={
                  values.hasSurvey
                    ? {
                        title: values.surveyTitle,
                        options: values.surveyOptions
                      }
                    : undefined
                }
              />
            </form>
          );
        }}
      </Formik>
    );
  }
}

CommunicationForm.contextType = AbilityContext;

CommunicationForm.defaultProps = {
  openAlert: false
};

CommunicationForm.propTypes = {
  setTitle: PropTypes.func.isRequired,
  openAlert: PropTypes.bool,
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  }).isRequired
};

export default CommunicationForm;
