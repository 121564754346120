import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import Button from "../waybee-ui/Button";
import Dialog from "../waybee-ui/Dialog";
import DialogTitle from "../waybee-ui/Dialog/DialogTitle";
import DialogContent from "../waybee-ui/Dialog/DialogContent";
import DialogActions from "../waybee-ui/Dialog/DialogActions";

const isMobile = window.innerWidth <= 784;

const DialogConfirm = ({ title, message, open, sendText, onClose, onSend }) => {
  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth>
      {title && <DialogTitle onClose={onClose}>{title}</DialogTitle>}

      <DialogContent>
        <Box mt={1}>
          <Typography variant="subtitle1">{message}</Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={3} justify="space-around">
          <Grid item xs={4}>
            <Button
              variant="contained"
              autoFocus
              fullWidth
              onClick={onClose}
              color="secondary"
            >
              Voltar
            </Button>
          </Grid>
          <Grid item xs={isMobile ? 6 : 4}>
            <Button
              variant="contained"
              autoFocus
              fullWidth
              onClick={onSend}
              color="primary"
            >
              {sendText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

DialogConfirm.defaultProps = {
  title: "",
  sendText: "Ok"
};

DialogConfirm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  sendText: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired
};

export default DialogConfirm;
