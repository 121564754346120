import React, { useState } from "react";
import PropTypes from "prop-types";
import { Avatar as MuiAvatar, Grid, Typography } from "@material-ui/core";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../../waybee-ui/Dialog/ConfirmDialog";
import { Can } from "../../../acl/can";
import TicketChannelActions from "./TicketChannelActions";

const ChannelItemContainer = styled(Grid)`
  background-color: #ffffff;
  border-radius: 16px;
  margin: 4px 0;
  min-height: 84px;

  :hover {
    cursor: pointer;
    background-color: #e3e3e3;
  }
`;

const Avatar = styled(MuiAvatar)`
  width: 60px;
  height: 60px;

  @media (max-width: 1030px) {
    max-height: 40px;
    max-width: 40px;
  }

  @media (max-width: 784px) {
    max-height: 35px;
    max-width: 35px;
  }
`;
const ChannelTitle = styled.p`
  color: #0d062d;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  text-align: center;

  @media (max-width: 784px) {
    text-align: left;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;
const ChannelItemDescription = styled(Typography)`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #787486;
  text-align: justify;
`;

const MobileChannelDescription = styled(ChannelItemDescription)`
  width: 100%;
  padding: 8px 16px 8px 21px;
`;

const ChannelItemCategory = styled(ChannelItemDescription)`
  text-align: center;

  @media (max-width: 784px) {
    text-align: justify;
  }
`;

const ChannelInfoContainer = styled(Grid)`
  padding-left: 18px;
`;

const MobileChannelInfo = styled(Grid)`
  padding-left: 18px;
`;

const MobileChannelHeader = styled(Grid)`
  padding-top: 8px;
`;
const isMobile = window.innerWidth <= 784;

const TicketChannelItem = ({
  channel,
  onClickDelete,
  onClickEnableVisibility
}) => {
  const { title, description, photoAvatar, category, id, isVisible } = channel;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [action, setAction] = useState(null);
  const [dialog, setDialog] = useState({ title: "", message: "" });
  const history = useHistory();

  const confirmDialogQuestion = selectedAction => {
    switch (selectedAction) {
      case "visibility":
        setDialog({
          title: `Deseja realmente ${
            isVisible ? "desativar" : "ativar"
          } o canal?`,
          message: `Ao ${
            isVisible ? "desativar" : "ativar"
          } o canal, os usuários ${
            isVisible ? "não terão mais" : "terão"
          } acesso a este canal de atendimento.`
        });
        break;
      case "exclude":
        setDialog({
          title: "Deseja realmente excluir o canal?",
          message: "Ao excluir o canal, os atendimentos serão excluídos"
        });
        break;
      default:
        break;
    }

    setAction(selectedAction);
    setShowDeleteDialog(true);
  };

  const onConfirmDialog = () => {
    switch (action) {
      case "visibility":
        onClickEnableVisibility(id, !isVisible);
        break;
      case "exclude":
        onClickDelete(id);
        break;
      default:
        break;
    }
  };

  if (isMobile) {
    return (
      <ChannelItemContainer container spacing={2} xs={12} alignItems="center">
        <MobileChannelHeader
          container
          justifyContent="space-between"
          alignItems="center"
          xs={12}
          onClick={() => history.push(`/ticket-channels/${id}`)}
        >
          <MobileChannelInfo container xs={isMobile ? 10 : 6} spacing={2}>
            <Grid item xs={isMobile ? 2 : 4}>
              <Avatar src={photoAvatar} />
            </Grid>
            <Grid item xs={8}>
              <ChannelTitle>{title}</ChannelTitle>
              <ChannelItemCategory>{category.name}</ChannelItemCategory>
            </Grid>
          </MobileChannelInfo>
          <Can I="create" a="ticketsCommunication">
            <Grid item xs={2}>
              <TicketChannelActions
                id={id}
                isVisible={isVisible}
                confirmDialogQuestion={confirmDialogQuestion}
              />
            </Grid>
          </Can>
        </MobileChannelHeader>
        <MobileChannelDescription
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {showDeleteDialog && (
          <ConfirmDialog
            open={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            onCancel={() => setShowDeleteDialog(false)}
            onConfirm={onConfirmDialog}
            cancelBtnText="Voltar"
            title={dialog.title}
            message={dialog.message}
            confirmBtnText="Confirmar"
          />
        )}
      </ChannelItemContainer>
    );
  }

  return (
    <ChannelItemContainer container spacing={2} xs={12} alignItems="center">
      <ChannelInfoContainer
        container
        xs={10}
        alignItems="center"
        onClick={() => history.push(`/ticket-channels/${id}`)}
      >
        <Grid item xs={1}>
          <Avatar src={photoAvatar} />
        </Grid>
        <Grid item xs={3}>
          <ChannelTitle>{title}</ChannelTitle>
        </Grid>
        <Grid item xs={5}>
          <ChannelItemDescription
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Grid>
        <Grid item xs={3}>
          <ChannelItemCategory>{category.name}</ChannelItemCategory>
        </Grid>
      </ChannelInfoContainer>

      <Can I="create" a="ticketsCommunication">
        <TicketChannelActions
          id={id}
          isVisible={isVisible}
          confirmDialogQuestion={confirmDialogQuestion}
        />
      </Can>

      {showDeleteDialog && (
        <ConfirmDialog
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          onCancel={() => setShowDeleteDialog(false)}
          onConfirm={onConfirmDialog}
          cancelBtnText="Voltar"
          title={dialog.title}
          message={dialog.message}
          confirmBtnText="Confirmar"
        />
      )}
    </ChannelItemContainer>
  );
};

TicketChannelItem.propTypes = {
  channel: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    photoAvatar: PropTypes.string.isRequired,
    category: PropTypes.objectOf().isRequired,
    id: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired
  }).isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickEnableVisibility: PropTypes.func.isRequired
};

export default TicketChannelItem;
