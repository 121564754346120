import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, TextField, Typography } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import _ from "lodash";
import Dialog from "../../../waybee-ui/Dialog";
import ImageInput from "../../../components/ImageInput";
import WuiConfirmButton from "../../../components/Button/ConfirmButton";
import WuiCancelButton from "../../../components/Button/CancelButton";
import validateCpf from "../../../utils/validateCpf";

const ContentGrid = styled(Grid)`
  padding: 45px 65px 60px 65px;
`;
const Label = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
  margin-top: 15px;
`;
const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;
const ButtonStyle = `
  width: 158px;
  height: 56px;
  margin-left: 32px;
  margin-right: 32px;
`;

const ConfirmButton = styled(WuiConfirmButton)`
  ${ButtonStyle}
`;
const CancelButton = styled(WuiCancelButton)`
  ${ButtonStyle}
`;

const UserDialog = ({
  open,
  onClose,
  onSend,
  onEdit,
  onChangeStatus,
  onDelete,
  onEditing,
  data,
  isEditing,
  isCreatingUser
}) => {
  const [photoUrl, setPhotoUrl] = useState();

  const {
    register,
    getValues,
    setValue,
    control,
    watch,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      phone: "",
      name: "",
      email: "",
      documentNumber: ""
    }
  });

  useEffect(() => {
    if (open && !_.isEmpty(data)) {
      reset(data);
    }
  }, [open]);

  const onChangeImage = file => {
    const blobUrl = URL.createObjectURL(file);
    setValue("photo", file);
    setPhotoUrl(blobUrl);
  };

  const onSendForm = async () => {
    const values = getValues();
    await onSend(values);
    reset({
      phone: "",
      name: "",
      email: "",
      documentNumber: ""
    });
  };

  const onEditForm = async () => {
    const values = getValues();
    await onEdit(values);
    reset({
      phone: "",
      name: "",
      email: "",
      documentNumber: ""
    });
  };

  const onCloseForm = () => {
    reset({
      phone: "",
      name: "",
      email: "",
      documentNumber: ""
    });
    return onClose();
  };

  const disabledForm =
    (!isCreatingUser && !isEditing) || (data && data.isExternalUser);

  return (
    <Dialog
      open={open}
      onClose={onCloseForm}
      closeButton
      maxWidth="md"
      fullWidth
    >
      <ContentGrid container spacing={1}>
        <Grid alignContent="center" item container xs={4}>
          <ImageInput
            size={180}
            imageUrl={photoUrl}
            onChange={(isEditing || !data.isExternalUser) && onChangeImage}
          />
        </Grid>
        <Grid item container xs={8} spacing={1}>
          <Grid item xs={6}>
            <Label>Nome Completo*</Label>
            {disabledForm ? (
              <>
                <Typography variant="h5">{data.name}</Typography>
              </>
            ) : (
              <>
                <TextField
                  {...register("name", { required: "Campo obrigatório" })}
                  variant="outlined"
                  error={errors.name}
                  fullWidth
                />
                {errors.name && errors.name.message}
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            <Label>CPF</Label>
            {disabledForm ? (
              <Typography variant="h5">{data.documentNumber}</Typography>
            ) : (
              <>
                <Controller
                  name="documentNumber"
                  control={control}
                  rules={{
                    validate: value => validateCpf(value) || "CPF inválido"
                  }}
                  render={({ field }) => (
                    <InputMask {...field} required mask="999.999.999-99">
                      {inputProps => (
                        <TextField
                          {...inputProps}
                          variant="outlined"
                          fullWidth
                          error={errors.documentNumber}
                        />
                      )}
                    </InputMask>
                  )}
                />
                {errors.documentNumber && errors.documentNumber.message}
              </>
            )}
          </Grid>

          <Grid item xs={6}>
            <Label>E-mail*</Label>
            {disabledForm ? (
              <Typography variant="h5">{data.email}</Typography>
            ) : (
              <>
                <TextField
                  {...register("email", { required: "Campo obrigatório" })}
                  type="email"
                  variant="outlined"
                  error={errors.email}
                  fullWidth
                />
                {errors.email && errors.email.message}
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            <Label>Telefone</Label>
            {disabledForm ? (
              <Typography variant="h5">{data.phone}</Typography>
            ) : (
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <InputMask
                    {...field}
                    mask={
                      watch("phone").charAt(5) === "9"
                        ? "(99) 99999-9999"
                        : "(99) 9999-9999"
                    }
                  >
                    {inputProps => (
                      <TextField {...inputProps} variant="outlined" fullWidth />
                    )}
                  </InputMask>
                )}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Actions>
            {!isEditing ? (
              <>
                {isCreatingUser ? (
                  <>
                    <CancelButton onClick={onClose} text="Cancelar" />
                    <ConfirmButton
                      onClick={handleSubmit(onSendForm)}
                      text="Salvar"
                    />
                  </>
                ) : (
                  <>
                    {!data.isExternalUser ? (
                      <>
                        <ConfirmButton
                          onClick={() => onChangeStatus(data, data.status)}
                          text={`${data.status === 1 ? "Desativar" : "Ativar"}`}
                        />
                        <ConfirmButton
                          onClick={() => onEditing(true)}
                          text="Editar"
                        />
                        <ConfirmButton
                          color="#DC3545"
                          onClick={() => onDelete(data)}
                          text="Exlcuir"
                        />
                      </>
                    ) : (
                      <CancelButton onClick={onClose} text="Voltar" />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <CancelButton onClick={onClose} text="Cancelar" />
                <ConfirmButton
                  onClick={handleSubmit(onEditForm)}
                  text="Salvar"
                />
              </>
            )}
          </Actions>
        </Grid>
      </ContentGrid>
    </Dialog>
  );
};

UserDialog.defaultProps = {
  onSend: () => {},
  onEdit: () => {},
  onChangeStatus: () => {},
  onDelete: () => {},
  onEditing: () => {},
  data: {}
};

UserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func,
  onEdit: PropTypes.func,
  onChangeStatus: PropTypes.func,
  onDelete: PropTypes.func,
  onEditing: PropTypes.func,
  data: PropTypes.objectOf(),
  isEditing: PropTypes.bool.isRequired,
  isCreatingUser: PropTypes.bool.isRequired
};

export default UserDialog;
