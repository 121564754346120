import React, { Component } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "../../../waybee-ui/Button";

const MessagesItem = styled(Grid)`
  margin-top: 4px;
  border-bottom: 1px solid;
`;

const MessagesContainer = styled(Grid)`
  margin-top: 12px;
`;

const SectionTitle = styled(Typography)`
  margin: 12px 0;
`;

const AddButton = styled(Button)`
  height: 56px;
`;

const AutoMessageText = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const isMobile = window.innerWidth <= 784;

class TicketChannelFormAutoMessages extends Component {
  constructor(props) {
    super(props);
    const { values, status } = this.props;

    const foundedStatus = values.autoMessages.map(
      message => message.ticketStatus
    );

    this.state = {
      statusAutoMessage: "",
      selectedStatus: null,
      autoMessages: values.autoMessages.filter(message => message.ticketStatus),
      status: status.filter(
        statusItem => !foundedStatus.includes(statusItem.id)
      ),
      shortcutMessages: values.autoMessages.filter(message => message.shortcut),
      shortcutMessage: "",
      shortcut: ""
    };
  }

  addAutoMessage = () => {
    const { setValue } = this.props;
    const {
      autoMessages,
      selectedStatus,
      shortcutMessages,
      statusAutoMessage,
      status
    } = this.state;
    if (!selectedStatus || !statusAutoMessage) return null;

    const result = autoMessages || [];
    result.push({
      message: statusAutoMessage,
      ticketStatus: selectedStatus.id
    });
    setValue(result.concat(shortcutMessages));

    status.splice(
      status.findIndex(statusItem => {
        return statusItem.id === selectedStatus.id;
      }),
      1
    );

    this.setState({
      autoMessages: result.filter(message => message.ticketStatus),
      status,
      statusAutoMessage: "",
      selectedStatus: null
    });

    return null;
  };

  addShortcutMessage = async () => {
    const { setValue } = this.props;
    const {
      shortcutMessage,
      shortcutMessages,
      shortcut,
      autoMessages
    } = this.state;

    if (!shortcutMessage || !shortcut) return;

    const result = shortcutMessages;
    result.push({
      message: shortcutMessage,
      shortcut: `/${shortcut}`
    });
    setValue(result.concat(autoMessages));

    this.setState({
      shortcutMessages: result.filter(message => message.shortcut),
      shortcut: "",
      shortcutMessage: ""
    });
  };

  deleteMessage = async message => {
    const { autoMessages, status, shortcutMessages } = this.state;
    const { status: originalStatus, setValue } = this.props;

    const newMessages = autoMessages.filter(autoMessage => {
      if (message.ticketStatus) {
        return autoMessage.ticketStatus !== message.ticketStatus;
      }
      return true;
    });

    const newShortcuts = shortcutMessages.filter(autoMessage => {
      if (message.shortcut) {
        return autoMessage.shortcut !== message.shortcut;
      }
      return true;
    });

    if (message.ticketStatus) {
      status.push(
        originalStatus.find(ogStatus => ogStatus.id === message.ticketStatus)
      );
    }

    setValue(newMessages.concat(newShortcuts));

    this.setState({
      autoMessages: newMessages,
      status,
      shortcutMessages: newShortcuts
    });
  };

  autoMessageStatusList = () => {
    const { autoMessages } = this.state;
    const { status } = this.props;

    if (autoMessages.length > 0) {
      return (
        <MessagesContainer item xs={12}>
          {autoMessages.map(autoMessage => {
            const foundStatus = status.find(
              statusItem => statusItem.id === autoMessage.ticketStatus
            );
            if (autoMessage.ticketStatus) {
              return (
                <MessagesItem
                  container
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={isMobile ? 5 : 8}>
                    <AutoMessageText>{autoMessage.message}</AutoMessageText>
                  </Grid>
                  <Grid item xs={isMobile ? 4 : 3}>
                    <Typography>{foundStatus.name}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    onClick={() => this.deleteMessage(autoMessage)}
                  >
                    <CloseIcon />
                  </Grid>
                </MessagesItem>
              );
            }
            return null;
          })}
        </MessagesContainer>
      );
    }

    return null;
  };

  shortcutMessageList = () => {
    const { shortcutMessages } = this.state;

    if (shortcutMessages.length > 0) {
      return (
        <MessagesContainer item xs={12}>
          {shortcutMessages.map(message => (
            <MessagesItem
              container
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={6}>
                <AutoMessageText>{message.shortcut}</AutoMessageText>
              </Grid>
              <Grid item xs={5}>
                <Typography>{message.message}</Typography>
              </Grid>
              <Grid item xs={1} onClick={() => this.deleteMessage(message)}>
                <CloseIcon />
              </Grid>
            </MessagesItem>
          ))}
        </MessagesContainer>
      );
    }

    return null;
  };

  render() {
    const {
      status,
      statusAutoMessage,
      selectedStatus,
      shortcut,
      shortcutMessage
    } = this.state;

    return (
      <>
        <Grid item xs={isMobile ? 10 : 8}>
          <SectionTitle variant="h5">
            Configurar atalhos de respostas rápidas
          </SectionTitle>

          <Grid container justifyContent="space-between" xs={12}>
            <Grid item xs={isMobile ? 12 : 4}>
              <TextField
                onChange={e => this.setState({ shortcut: e.target.value })}
                value={shortcut}
                variant="outlined"
                fullWidth
                label="Nome do Atalho"
              />
            </Grid>
            <Grid item xs={isMobile ? 8 : 6}>
              <TextField
                onChange={e =>
                  this.setState({ shortcutMessage: e.target.value })
                }
                value={shortcutMessage}
                variant="outlined"
                fullWidth
                label="Resposta"
              />
            </Grid>
            <AddButton
              color="primary"
              variant="contained"
              onClick={this.addShortcutMessage}
            >
              +
            </AddButton>
          </Grid>

          {this.shortcutMessageList()}

          <SectionTitle variant="h5">
            Configurar respostas por alteração de status
          </SectionTitle>
          <Grid container justifyContent="space-between" xs={12}>
            <Grid item xs={isMobile ? 12 : 7}>
              <TextField
                id="statusAutoMessage"
                name="statusAutoMessage"
                onChange={e =>
                  this.setState({ statusAutoMessage: e.target.value })
                }
                value={statusAutoMessage}
                variant="outlined"
                fullWidth
                label="Digite uma resposta"
              />
            </Grid>
            <Grid item xs={isMobile ? 8 : 3}>
              <Autocomplete
                id="selectedStatus"
                name="selectedStatus"
                value={selectedStatus}
                getOptionLabel={option => option.name || ""}
                options={status}
                renderInput={params => (
                  <TextField
                    {...params}
                    margin="none"
                    label="Status"
                    fullWidth
                    variant="outlined"
                  />
                )}
                onChange={(event, statusValue) => {
                  this.setState({ selectedStatus: statusValue });
                }}
                renderOption={option => (
                  <React.Fragment>{option.name || ""}</React.Fragment>
                )}
              />
            </Grid>
            <AddButton
              color="primary"
              variant="contained"
              onClick={this.addAutoMessage}
            >
              +
            </AddButton>
          </Grid>
          {this.autoMessageStatusList()}
        </Grid>
      </>
    );
  }
}

TicketChannelFormAutoMessages.defaultProps = {
  channelId: undefined
};

TicketChannelFormAutoMessages.propTypes = {
  values: PropTypes.objectOf().isRequired,
  errors: PropTypes.objectOf().isRequired,
  status: PropTypes.arrayOf().isRequired,
  channelId: PropTypes.number,
  setValue: PropTypes.func.isRequired
};

export default TicketChannelFormAutoMessages;
